<div class="f-c home-container">
  <!-- <div class="w-100 top-message-mobile pt-3 pb-3">
    <div class="message-header">
      <span class="rem-0-95 wt">{{ 'youCanScheduleViaSite' | translate }}</span>
    </div>

    <div class="message-options f-r pt-3 align-items-center">
      <div class="schedule-container f1">
        <button class="btn-schedule">
          <img class="btn-schedule-img pr-1" src="../../assets/images/blood_donation_red.png" alt="{{ 'scheduleMeDonation' | translate }}">
          {{ 'scheduleMeDonation' | translate }}
        </button>
      </div> 

      <div class="question-container f1 f-c">
        <span class="underline wt rem-1 text-center"> {{ 'amIEligible' | translate }}</span>
      </div>
    </div>
  </div> -->

  <div class="supply-button-sticky f-r" (click)="onSupplyButtonClicked()">
    <div class="supply-icon">
      <img src="../../assets/images/blood-bag.svg" alt="{{ 'supplyState' | translate }}">
    </div>
    <div class="supply-text">
      <span>{{ 'supplyState' | translate }}</span>
    </div>
  </div>


  <div class="slider-cont w-100">
    <app-slider></app-slider>
  </div>

  <div class="supply-button-mobile f-r" (click)="onSupplyButtonClicked()">
    <div class="supply-icon">
      <img src="../../assets/images/blood-bag.svg" alt="{{ 'supplyState' | translate }}">
    </div>
    <div class="supply-text">
      <span>{{ 'supplyState' | translate }}</span>
    </div>
  </div>

  
  <div class="f-c pt-3 section-home w-100 justify-content-center">
    <app-ztk-map-section></app-ztk-map-section>
    <app-news-section></app-news-section>
    <app-darujkrv-me></app-darujkrv-me>
  </div>


  
</div>
