<div class="main-header f-r container">
  <div class="row col-12" style="align-items: flex-end;">
    <div class="col-10">
        <img class="vsite-logo" src="../assets/images/dhm_primary_logo.png" alt="Logo">
    </div>
    <div class="col-2 right">
        <a class="cursor-pointer right" [ngClass]="{'hover': IfMe()}" (click) ="changeLanguage('me')">ME </a>|
        <a class="cursor-pointer right"  [ngClass]="{'hover': !IfMe()}" (click) ="changeLanguage('en')">DE</a>
    </div>
  </div>
  <!-- <div>
    <a class="cursor-pointer" [ngClass]="{'hover': IfMe()}" (click) ="changeLanguage('me')">ME </a>|
    <a class="cursor-pointer"  [ngClass]="{'hover': !IfMe()}" (click) ="changeLanguage('en')">DE</a>
  </div> -->
</div>
