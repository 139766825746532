import { OnInit, EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { Subscription, Observable, of } from 'rxjs';
import { Config } from '../../../Config';
import { AuthService } from './auth.service';
import { DataService } from './data.service';
import { CartItem } from '../models/cart-item.model';


@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {
  private cartItems: CartItem[] = [];
  private config: Config;
  public itemDeleted = new EventEmitter<number>();
  public itemChanged = new EventEmitter<{item: CartItem, changedFromProductPage: boolean}>();
  public itemAdded = new EventEmitter<CartItem>();
  public cartCleared = new EventEmitter<boolean>();
  private isLoggedIn: boolean;
  private loggedInUser: any;
  // public cartChanged = new EventEmitter<number>();

  constructor(private auth: AuthService,
              private dataService: DataService,
              private storageService: StorageService) {

                this.auth.isLoggedIn.subscribe((val) => {
                  this.isLoggedIn = val;
                  this.loggedInUser = this.auth.loggedInUser;
                });

              }

  public get localCartItems() {
    let myShoppingCart = this.storageService.localGet('shoppingCart');
    if (myShoppingCart == null || myShoppingCart === undefined) {
      myShoppingCart = [];
    }

    return myShoppingCart;
  }

  public addItem(item: CartItem, fromProductPage: boolean): Observable<any> {
    if(this.isLoggedIn) {
      return this.addItemToCartDb(item, fromProductPage);
    } else {
      return this.addItemToCartLocalStorage(item, fromProductPage);
    }
  }

  public addItemToCartLocalStorage(item: CartItem, fromProductPage: boolean) {
    const it = this.storageService.localGet('shoppingCart');
    if (this.cartItems == null || this.cartItems === undefined || it === 0) {
        this.cartItems = [];
    } else {
      this.cartItems = it;
    }

    let elementChanged = false;

    for (const itemIterator of this.cartItems) {
      if (itemIterator.product.id === item.product.id) {
        if(itemIterator.size === item.size) {
          itemIterator.quantity += 1;
          item.quantity = itemIterator.quantity;
          elementChanged = true;
          break;
        }
      }
    }

    if (!elementChanged) {
        this.cartItems.push(item);
    }

    this.storageService.localSet('shoppingCart', this.cartItems);

    if(elementChanged) {
      this.itemChanged.emit({item, changedFromProductPage: fromProductPage});
    } else {
      this.itemAdded.emit(item);
    }

    const obj = {
      code: 200,
      status: 'OK',
      message: 'Uspješno dodat element u korpu',
      data: item
    };
    return of(obj);
  }

  public addItemToCartDb(item: CartItem, fromProductPage: boolean) {
    const obj = {
      item
    };
    return this.dataService.postAnyData('cart-item', obj);
  }

  public removeItemFromCart(item: CartItem, positionOrIds: any) {
    if (this.isLoggedIn) {
      return this.removeItemFromCartDb(item, positionOrIds);
    } else {
      return this.removeItemFromCartLocalStorage(item, positionOrIds);
    }
  }

  public removeItemFromCartLocalStorage(item: CartItem, position: number) {
    let cart: Array<CartItem>;
    cart = this.storageService.localGet('shoppingCart');
    cart.splice(position, 1);

    this.storageService.localSet('shoppingCart', cart);

    const obj = {
      code: 201,
      status: 'OK',
      message: 'Element maknut iz korpe',
      data: item
    };
    return of(obj);
  }

  public removeItemFromCartDb(item: CartItem, ids: any) {
    if (item) {
      return this.dataService.deleteData('cart-item', item.id);
    } else {
      return this.dataService.deleteData('cart-item', ids);
    }
  }

  public submitCartFromSession(userId: number) {
    this.cartItems = this.storageService.localGet('shoppingCart');
    const obj = {
      cart: {
        items: this.cartItems
      }      
    };

    return this.dataService.postAnyData('cart', obj);
  }


  





}
