import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-image-loader',
  templateUrl: './image-loader.component.html',
  styleUrls: ['./image-loader.component.css']
})
export class ImageLoaderComponent implements OnInit {
  @Input('classnames') classnames: string;
  @Input('imageSrc') imageSrc: string;
  @Input('copyright') copyright: string;
  @Input('alt') alt: string;

  imageLoaded = false;
  

  constructor() { }

  ngOnInit() {
  }

  loadingFinished(ev: any) {
    this.imageLoaded = true;

  }

  errorWhileLoading() {
    this.imageSrc = this.imageSrc.replace(/-\d{2,}x\d{2,}./gi, '.');
  }

}
