import { Component, OnInit } from '@angular/core';
import { ArticleCategory } from '../shared/models/article-category.model';
import { Router, ActivatedRoute } from '@angular/router';
import { ArticleService } from '../shared/services/article.service';
import { SiteArticle } from '../shared/models/site-article.model';
import { DataService } from '../shared/services/data.service';
import { CategorySectionResolverService } from '../shared/services/resolvers/category-section-resolver.service';
import { StorageService } from '../shared/services/storage.service';
import { AuthService } from '../shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalsService } from '../shared/services/modals.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  homeArticles: SiteArticle[];
  isLoading = false;
  langParam: string;


  constructor(private router: Router,
              private route: ActivatedRoute,
              private translate: TranslateService,
              private articleService: ArticleService,
              private categoryListener: CategorySectionResolverService,
              private modal: ModalsService,
              private storageService: StorageService,
              private auth: AuthService,) { }

  ngOnInit() {
    // this.loadArticles();
    const userToken = this.storageService.sessionGet("vsite_user_token");

    // tslint:disable-next-line: max-line-length
    this.langParam = this.route.snapshot.data.language != null && this.route.snapshot.data.language !== undefined ? this.route.snapshot.data.language.language : 'me';

    if(userToken == null || userToken === undefined) {
      this.auth.logout();

      this.router.navigate(['/' + this.langParam + '/home']);
    }
  }

  onSupplyButtonClicked() {
    this.modal.modalBloodSupplies.emit(true);
  }

}
