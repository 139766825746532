import { Component, OnInit, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { AgmMap } from '@agm/core';
import { isPlatformBrowser } from '@angular/common';



@Component({
  selector: 'app-locations-map',
  templateUrl: './locations-map.component.html',
  styleUrls: ['./locations-map.component.css']
})
export class LocationsMapComponent implements OnInit {
  lat = 42.442577;
  lng = 19.244931;
  // @ViewChild(AgmMap) agmMap: AgmMap
  markers: Array<{title: string, address: string, city: string, country: string, lat: number, lng: number}> = [];
  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId) {
     this.isBrowser = isPlatformBrowser(this.platformId);
   }

  ngOnInit() {
    this.markers = [
      {title: 'Pinko', address: 'Bulevar Džordža Vašingtona 10', city: 'Podgorica', country: 'Montenegro', lat: 42.442411, lng: 19.244851},
      // tslint:disable-next-line: max-line-length
      {title: 'Hugo Boss', address: 'Bulevar Džordža Vašingtona 12', city: 'Podgorica', country: 'Montenegro', lat: 42.443104, lng: 19.245202},
      // tslint:disable-next-line: max-line-length
      {title: 'Garry Weber, Bar', address: 'Jovana Stojanovića F43', city: 'Podgorica', country: 'Montenegro', lat: 42.09809, lng: 19.09752},
      // tslint:disable-next-line: max-line-length
      {title: 'Garry Weber, Podgorica', address: 'Ulica Slobode 1', city: 'Podgorica', country: 'Montenegro', lat: 42.439409, lng: 19.262349},
      // tslint:disable-next-line: max-line-length
      {title: 'Petit Bateau', address: 'Bulevar Svetog Petra Cetinjskog 147', city: 'Podgorica', country: 'Montenegro', lat: 42.443355, lng: 19.246199},
      {title: 'Liu Jo, Tivat', address: 'Ulica Palih Boraca', city: 'Podgorica', country: 'Montenegro', lat: 42.430402, lng: 18.698071},
      {title: 'Liu Jo, Bar', address: 'Jovana Stojanovića F43', city: 'Podgorica', country: 'Montenegro', lat: 42.097759, lng: 19.096778},
      // tslint:disable-next-line: max-line-length
      {title: 'Liu Jo, Podgorica', address: 'Bulevar Džordža Vašingtona 68', city: 'Podgorica', country: 'Montenegro', lat: 42.442722, lng: 19.245012},
      {title: 'Max&Co', address: 'Bulevar Džordža Vašingtona 18', city: 'Podgorica', country: 'Montenegro', lat: 42.443059, lng: 19.245176},
      {title: 'MaxMara Tivat', address: 'Arsenalska', city: 'Podgorica', country: 'Montenegro', lat: 42.432763, lng: 18.694131},
      // tslint:disable-next-line: max-line-length
      {title: 'MaxMara Podgorica', address: 'Bulevar Džordža Vašingtona 84', city: 'Podgorica', country: 'Montenegro', lat: 42.442292, lng: 19.24477},
      {title: 'Mura', address: 'Bulevar Džordža Vašingtona 81', city: 'Podgorica', country: 'Montenegro', lat: 42.442515, lng: 19.245391},
      // tslint:disable-next-line: max-line-length
      {title: 'PennyBlack', address: 'Bulevar Džordža Vašingtona 70', city: 'Podgorica', country: 'Montenegro', lat: 42.443282, lng: 19.245162},
      // tslint:disable-next-line: max-line-length
      {title: 'Paul & Shark', address: 'Bulevar Džordža Vašingtona 14', city: 'Podgorica', country: 'Montenegro', lat: 42.443153,lng: 19.24505},
      // tslint:disable-next-line: max-line-length
      {title: 'Twin-Set', address: 'Bulevar Džordža Vašingtona 18', city: 'Podgorica', country: 'Montenegro', lat: 42.443034, lng: 19.245156},
      // tslint:disable-next-line: max-line-length
      {title: 'Weekend MaxMara', address: 'Bulevar Džordža Vašingtona 82', city: 'Podgorica', country: 'Montenegro', lat: 42.442424, lng: 19.244827},
      // tslint:disable-next-line: max-line-length
      {title: 'Ermenegildo Zegna', address: 'Bulevar Džordža Vašingtona 74', city: 'Podgorica', country: 'Montenegro', lat: 42.442577, lng: 19.244931},
    ]
  }


}
