<div class="f-c outter-slider-cont">
  <div id="sammsCarousel" class="carousel slide" data-interval="3000" data-ride="carousel">
    <div class="carousel-inner">
      <div *ngFor="let slide of slides; let s = index" class="carousel-item {{ s === 0 ? 'active' : '' }}">
        <app-slider-item [slide]="slide"></app-slider-item>
      </div>
    </div>
    <a class="carousel-control-prev f-c" href="#sammsCarousel" role="button" data-slide="prev">
      <span class="fa fa-chevron-left" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next f-c" href="#sammsCarousel" role="button" data-slide="next">
      <span class="fa fa-chevron-right" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>

    <div class="w-100 crsl-indicators">
      <ol class="carousel-indicators">
        <li *ngFor="let slide of slides; let s = index" data-target="#sammsCarousel" data-slide-to="0" 
        class="{{ s === 0 ? 'active' : '' }}"></li>
      </ol>
    </div>
  </div>
  
  
</div>

