import { Injectable, Inject } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { Config } from '../../../Config';
import { DOCUMENT } from '@angular/common';


@Injectable({
providedIn: 'root'
})
export class MapboxService {
private config = new Config();
map: mapboxgl.Map;
style = 'mapbox://styles/vukdju/ckfi81qo40k2x19nv5yl71vyn';
lat = 42.795858;
lng = 19.257480;
zoom: number;
screenWidth: number;
markers: Array<mapboxgl.Marker> = [];

constructor(@Inject(DOCUMENT) private document: Document) {
  mapboxgl.accessToken = this.config.mapbox.accessToken;
}



buildMap() {
  this.screenWidth = this.document.documentElement.clientWidth;
  this.zoom = this.screenWidth > 800 ? 7.5 : 7;
  this.map = new mapboxgl.Map({
    container: 'map',
    style: this.style,
    zoom: this.zoom,
    center: [this.lng, this.lat],
  });



  const marker = new mapboxgl.Marker().setLngLat([42.441257, 19.251516]).addTo(this.map);

  if(this.screenWidth > 800) {
    this.map.addControl(new mapboxgl.NavigationControl());
  }
  

}
}