<!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->

<div>
  <div *ngIf="!onHomePage" class="bag-container">
    <div class="grey-bckg"> 
    </div>
  
    <div class="blood" [ngStyle]="{ 'min-height': bloodHeightInPixels }">
    </div>   
    
    <svg version="1.1" id="layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="-247.5 310 113 173" style="enable-background:new -247.5 310 113 173;" xml:space="preserve">
        <g>
        <path class="st0" d="M-196.2,480.4v-20c0-1.1-0.9-2.1-2.1-2.1h-11.4c-1.1,0-2.1,0.9-2.1,2.1v20c0,1.1-0.9,2.1-2.1,2.1h19.6
          C-195.2,482.5-196.2,481.6-196.2,480.4z"/>
        <path class="st0" d="M-170.3,480.4v-20c0-1.1-0.9-2.1-2.1-2.1h-11.4c-1.1,0-2.1,0.9-2.1,2.1v20c0,1.1-0.9,2.1-2.1,2.1h19.6
          C-169.4,482.5-170.3,481.6-170.3,480.4z"/>
        <path class="st0" d="M-248,310.5v172h28.1c-1.1,0-2.1-0.9-2.1-2.1v-20c0-1.1-0.9-2.1-2.1-2.1h-2.8c0,0,0,0,0,0
          c-11.4,0-20.7-9.3-20.7-20.8v-90.3v0c0-11.4,9.2-20.7,20.7-20.8h16.6c1,0,1.9-0.7,2-1.7c1.3-7.1,6.9-12.7,14-14
          c9.5-1.8,18.6,4.5,20.4,14c0.2,1,1,1.7,2,1.7h16.6c11.4,0,20.7,9.3,20.7,20.8v90.3c0,0,0,0,0,0c0,11.4-9.2,20.7-20.7,20.8h-2.8
          c-1.1,0-2.1,0.9-2.1,2.1v20c0,1.1-0.9,2.1-2.1,2.1h28.1v-172H-248z"/>
        </g>
        <circle id="Ellipse_6" class="st0" cx="-191" cy="328.1" r="6.4"/>
        <path id="Path_11" class="st0" d="M-152.1,422.5h-77.8c-1.1,0-2.1-0.9-2.1-2.1c0,0,0,0,0,0v-56c0-1.1,0.9-2.1,2.1-2.1c0,0,0,0,0,0
        h77.8c1.1,0,2.1,0.9,2.1,2.1c0,0,0,0,0,0v56C-150,421.6-151,422.5-152.1,422.5z"/>
    </svg>
  
    <div class="auto-center">
      <span class="rem-1-25">{{ groupName }}</span>
    </div>
    
  </div>

  <div *ngIf="onHomePage" class="bag-container home">
    <div class="grey-bckg home"> 
    </div>
  
    <div class="blood home" [ngStyle]="{ 'min-height': bloodHeightInPixels }">
    </div>   
    
    <svg version="1.1" id="layer_1" class="home" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="-247.5 310 113 173" style="enable-background:new -247.5 310 113 173;" xml:space="preserve">
        <g>
        <path class="st0" d="M-196.2,480.4v-20c0-1.1-0.9-2.1-2.1-2.1h-11.4c-1.1,0-2.1,0.9-2.1,2.1v20c0,1.1-0.9,2.1-2.1,2.1h19.6
          C-195.2,482.5-196.2,481.6-196.2,480.4z"/>
        <path class="st0" d="M-170.3,480.4v-20c0-1.1-0.9-2.1-2.1-2.1h-11.4c-1.1,0-2.1,0.9-2.1,2.1v20c0,1.1-0.9,2.1-2.1,2.1h19.6
          C-169.4,482.5-170.3,481.6-170.3,480.4z"/>
        <path class="st0" d="M-248,310.5v172h28.1c-1.1,0-2.1-0.9-2.1-2.1v-20c0-1.1-0.9-2.1-2.1-2.1h-2.8c0,0,0,0,0,0
          c-11.4,0-20.7-9.3-20.7-20.8v-90.3v0c0-11.4,9.2-20.7,20.7-20.8h16.6c1,0,1.9-0.7,2-1.7c1.3-7.1,6.9-12.7,14-14
          c9.5-1.8,18.6,4.5,20.4,14c0.2,1,1,1.7,2,1.7h16.6c11.4,0,20.7,9.3,20.7,20.8v90.3c0,0,0,0,0,0c0,11.4-9.2,20.7-20.7,20.8h-2.8
          c-1.1,0-2.1,0.9-2.1,2.1v20c0,1.1-0.9,2.1-2.1,2.1h28.1v-172H-248z"/>
        </g>
        <circle id="Ellipse_6" class="st0" cx="-191" cy="328.1" r="6.4"/>
        <path id="Path_11" class="st0" d="M-152.1,422.5h-77.8c-1.1,0-2.1-0.9-2.1-2.1c0,0,0,0,0,0v-56c0-1.1,0.9-2.1,2.1-2.1c0,0,0,0,0,0
        h77.8c1.1,0,2.1,0.9,2.1,2.1c0,0,0,0,0,0v56C-150,421.6-151,422.5-152.1,422.5z"/>
    </svg>
  
    <div class="auto-center home">
      <span class="rem-1-25">{{ groupName }}</span>
    </div>
    
  </div>
</div>


