import { Component, OnInit } from '@angular/core';
import { MapboxService } from '../../../shared/services/mapbox.service';

@Component({
  selector: 'app-ztk-map-section',
  templateUrl: './ztk-map-section.component.html',
  styleUrls: ['./ztk-map-section.component.css']
})
export class ZtkMapSectionComponent implements OnInit {

  constructor(private mapboxService: MapboxService) { }

  ngOnInit() {
    this.mapboxService.buildMap();
    this.mapboxService.map.scrollZoom.disable();
    this.mapboxService.map.dragPan.disable();
  }

}
