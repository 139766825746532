<div class="bt">
  <div class="  row " style=" margin-left: 0px;  margin-right: 0px;">
    <div class="col-12 info" style="padding-left: 0px;padding-right: 0px;">

        <app-image-loader [classnames]="'img-fluid f-c fill-background ab'"
                          [imageSrc]="'./../../../assets/images/kurs_1.jpg'">

        </app-image-loader>
      </div>
    <div class="card col-lg-11 col-xl-9 sol-sm-11" style="padding-left: 0px;padding-right: 0px;">
        <h3 class="card-title greenBK ourTeam">
          {{ 'aboutUs' | translate }}
        </h3>
        <div class="card-text">
            <ul class="">
                <li>         <div  [innerHtml]="'aboutUsP1' | translate">
                  </div> </li>
                <li>  <div [innerHtml]="'aboutUsP2' | translate">
                  </div> </li>
              </ul>
        </div>
        <h3 class="card-title greenBK ourTeam">
            {{ 'ourGoalsSubtitle' | translate }}
          </h3>
          <div class="">

            <div class="card-text">
              <ul class="" style="list-style-type: initial;">
                <li> {{ 'goal1' | translate }} </li>
                <li> {{ 'goal2' | translate }} </li>
                <li> {{ 'goal3' | translate }} </li>
                <li> {{ 'goal4' | translate }} </li>
              </ul>
            </div>
        </div>
        <h3 class="card-title greenBK ourTeam">
            {{ 'partners' | translate }}
          </h3>
          <div class="">
            <!-- <div class="mv-24 section-title-border animated fadeInUp"></div> -->

            <div class="card-text">
              <ul>

                <li> {{ 'par_1' | translate }} </li>
                <li> {{ 'par_2' | translate }} </li>
                <li> {{ 'par_3' | translate }} </li>
                <li> {{ 'par_4' | translate }} </li>
              </ul>
            </div>
        </div>
      </div>

    <div class=" col-12" style="padding-left: 0px;padding-right: 0px;">

        <div id="accordion">
            <div class="card">
                <h3 class="card-title greenBK ourTeam accordion_toggle">
                    {{ 'ourTeam' | translate }}
                  </h3>
              <div class="card-header" id="headingOne">
                <h5 class="mb-0 left">
                  <button class="btn btn-inverse collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    <span class="fa fa-chevron-down"></span> {{ 'teachers' | translate }}
                  </button>
                </h5>
              </div>

              <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body">
                    <div class="blue-team-cont f-r">
                        <img class="team-member-img" src="../../../assets/images/bb.jpg" alt="Bojana Božović">
                        <table class="member-data">
                          <tr><td class="member-data-text">Bojana Božović</td></tr>
                          <tr><td class="member-data-text"><a class="white-anchor-text-medium" href="mailto:bojana.bozovic@dhm.org.me">bojana.bozovic@dhm.org.me</a>
                          </td></tr>
                        </table>
                    </div>

                    <div class="blue-team-cont f-r">
                        <img class="team-member-img" src="../../../assets/images/ir.jpg" alt="Ilda Ramusović">
                        <table class="member-data">
                          <tr><td class="member-data-text">Ilda Ramusović</td></tr>
                          <tr><td class="member-data-text"><a class="white-anchor-text-medium" href="mailto:ilda.ramusovic@dhm.org.me">ilda.ramusovic@dhm.org.me</a>
                          </td></tr>
                        </table>
                    </div>

                    <div class="blue-team-cont f-r">
                        <img class="team-member-img" src="../../../assets/images/mp.jpg" alt="Mladenka Maja Perić">
                        <table class="member-data">
                          <tr><td class="member-data-text">Mladenka Maja Perić</td></tr>
                          <tr><td class="member-data-text"><a class="white-anchor-text-medium" href="mailto:maja.peric@dhm.org.me">maja.peric@dhm.org.me</a>
                          </td></tr>
                        </table>
                    </div>
                </div>
              </div>

                <div class="card-header" id="headingTwo">
                  <h5 class="mb-0 left">
                    <button class="btn btn-inverse collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <span class="fa fa-chevron-down"></span> {{ 'info' | translate }}
                    </button>
                  </h5>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div class="card-body">
                      <div class="blue-team-cont f-r">
                          <img class="team-member-img" src="../../../assets/images/mb.jpg" alt="Milena Bulatović">
                          <table class="member-data">
                            <tr><td class="member-data-text">Milena Bulatović</td></tr>
                            <tr><td class="member-data-text"><a class="white-anchor-text-medium" href="mailto:milena.bulatovic@dhm.org.me">milena.bulatovic@dhm.org.me</a>
                            </td></tr>
                          </table>
                      </div>
        </div>
                </div>
                  <div class="card-header" id="headingThree">
                    <h5 class="mb-0 left bt">
                      <button class="btn btn-inverse collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <span class="fa fa-chevron-down"></span> {{ 'director' | translate }}
                      </button>
                    </h5>
                  </div>
                  <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                    <div class="card-body">
                        <div class="blue-team-cont f-r">
                            <img class="team-member-img" src="../../../assets/images/jb.jpg" alt="Jelena Božović">
                            <table class="member-data">
                              <tr><td class="member-data-text">Jelena Božović</td></tr>
                              <tr><td class="member-data-text"><a class="white-anchor-text-medium" href="mailto:jelena.bozovic@dhm.org.me">jelena.bozovic@dhm.org.me</a>
                              </td></tr>
                            </table>
                        </div>
                     </div>
                  </div>
                </div>
        </div>
      </div>

</div>
  </div>

