export class SearchTerm {
  public term: {
    me: string,
    en: string
  };
  public name: string;
  public type: string; // Possible values : ['product', 'category', 'brand'];
  public id: number;
  public link: string;

  constructor(obj: {termMe: string, termEn: string, type: string, termId: number, link: string}) {
      this.term = {
        me: obj.termMe,
        en: obj.termEn
      };
      this.name = obj.termMe;
      this.type = obj.type;
      this.id = obj.termId;
      this.link = obj.link;
  }
}
