<footer>
  <div class="footer-out-most f-c justify-content-center align-items-center pt-3 pb-3">
    <div class="footer-container col-sm-12 col-md-12 col-lg-11 col-xl-11">
      <div class="footer-left-column text-center f-c f1 p-4 ">
        <div class="footer-inner-column f-c p-2">
          <div class="footer-column-title mv-12">
            <span class="fs-14">{{ 'contact' | translate }}</span>
          </div>
  
          <div class="column-content f-c mv-12">
            <div class="footer-row">
              <span>{{ 'realAddress' | translate }}</span>
            </div>
            <div class="footer-row">
              <span>{{ 'realPhone' | translate }}</span>
            </div>
          </div>
  
          <div class="column-content f-c mv-12">
            <div class="footer-row">
              <span>{{ 'bankAccount1' | translate }}</span>
            </div>
            <div class="footer-row">
              <span>{{ 'bankAccount2' | translate }}</span>
            </div>
            <div class="footer-row">
              <span>{{ 'realEmail' | translate }}</span>
            </div>
          </div>
  
          <div class="column-content f-c mv-12">
            <div class="footer-row">
              <span>{{ 'siteCopyright1' | translate }}</span>
            </div>
            <div class="footer-row">
              <span>{{ 'siteCopyright2' | translate }}</span>
            </div>
            <div class="footer-row">
              <span>{{ 'siteCopyright3' | translate }}</span>
            </div>
          </div>
    
          <!-- <div class="social-icons-container f-r w-100 mv-12">
            <div class="social-icon fs-18">
              <a href="https://www.instagram.com/samms_cg/" target="_blank"><span class="fa fa-instagram"></span></a>
            </div>
    
            <div class="social-icon fs-18">
              <a href="https://www.facebook.com/sammscg" target="_blank"><span class="fa fa-facebook"></span></a>
            </div>
    
            <div class="social-icon fs-18">
              <span class="fa fa-youtube"></span>
            </div>
          </div> -->
        </div>
        
      </div>
      
      
      <div class="f-c f1 p-4">
        <div class="footer-inner-column p-2">
          <div class="footer-column-title mv-12">
            <span class="fs-14">{{ 'usefulLinks' | translate }}</span>
          </div>
          <ul class="footer-list mv-12 fs-14">
            <li><a class="wt" target="_blank" href="http://www.mzdravlja.gov.me/">{{ 'ministryOfHealth' | translate }}</a></li>
            <li><a class="wt" target="_blank" href="http://ckcg.me/">{{ 'redCross' | translate }}</a></li>
            <li><a class="wt" target="_blank" href="https://www.ijzcg.me/">{{ 'instituteForPublicHealth' | translate }}</a></li>
            <li><a class="wt" target="_blank" href="https://fzocg.me/">{{ 'fundForHealthInsurance' | translate }}</a></li>
          </ul>
        </div>      
      </div>
  
      <div class="footer-right-column text-center f-c f1 p-4">
        <div class="footer-inner-column right-column p-2">
          <div class="footer-column-title mv-12">
            <span class="fs-14">{{ 'followUs' | translate }}</span>
          </div>
          

          <div class="social-icons-container f-r w-100 mv-12">

            <div class="social-icon fs-18">
              <a href="https://www.facebook.com/ztkcg/" target="_blank"><img src="../../assets/images/fb.png" alt="" class="social-img"></a>
            </div>

            <div class="social-icon fs-18">
              <a href="https://www.instagram.com/zavodzatransfuzijukrvi/?hl=en" target="_blank"><img src="../../assets/images/instagram.png" alt="" class="social-img"></a>
            </div>
    
            <div class="social-icon fs-18">
              <a href="https://twitter.com/ztkcg" target="_blank"><img src="../../assets/images/twitter.png" alt="" class="social-img"></a>
            </div>

            <div class="social-icon fs-18">
              <a href="https://www.youtube.com/channel/UCrjzxZyW4i71OAchMMtaszA" target="_blank"><img src="../../assets/images/youtube.png" alt="" class="social-img"></a>
            </div>
          </div>


          <div class="footer-column-title mt-5">
            <span class="fs-14">{{ 'internationalCertificatesAndStandards' | translate }}</span>
          </div>

          <div class="standards f-r mv-12 justify-content-center">
            <div class="standard">
              <img src="../../assets/images/sertifikat_ztkcg.jpg" alt="ISO standard" class="iso-img p-2">
            </div>
            <!-- <div class="standard">
              <img src="../../assets/images/ISO9001.png" alt="ISO standard" class="iso-img p-2">
            </div> -->
          </div>

        </div> 
      </div>
    </div>
    
  </div>
</footer>