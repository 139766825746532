<div *ngIf="errorExists" class="page-error">
    <div class="alert alert-danger">
        {{ 'pageNotAvailable' | translate }}
    </div>
</div>
<div *ngIf="!errorExists" class="article-component-container pager">
    <div class="article-header">
        <h4 class="article-subtitle">
                {{ article.subtitle }}
        </h4>
        <h1 class="article-title">
           {{  article.title }}
        </h1>
    </div>

    <div class="article-content">
        <div *ngIf="article.visualType === 'I' && article.imageOrientation != null">
            <app-image-loader [classnames]="'article-image f-c ' +  (article.imageSpecial === 1 ? 'spec-image' : '')"
                            [imageSrc]="article.imageUrl"
                            [copyright]="article.imageCopyright">

            </app-image-loader>
        </div>

        <div *ngIf="article.visualType === 'V'" class="article-video">
            <video [src]="article.videoUrl" controls style="width: 100%"></video>
            <!-- <iframe class="article-video" [src]="article.videoUrl" frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"  allowfullscreen></iframe> -->
        </div>
            
        <!-- <div class="article-image f-c" [ngStyle]="{'background-image': 'url(' + article.imageUrl + ')'}">
            <div class="image-copyright">
                <span>{{ article.imageCopyright }}</span>
            </div>
        </div> -->

        <!-- <div class="article-text" [innerHTML]="article.text">
        </div> -->

        <div *ngIf="article.show_date" class="date-cont pt-4">
            <span class="fs-14"> {{ 'published' | translate }} {{ ' ' + article.post_date }}</span>
        </div>

        <div class="article-text">
           <markdown [data]="article.text"></markdown>
        </div>
    </div>

    <div class="p-2 mv-24"></div>

    
</div>

