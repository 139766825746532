import { Component, OnInit, Inject } from '@angular/core';
import { SiteProduct } from '../../shared/models/site-product.model';
import { formatDate } from '@angular/common';
import { DataService } from '../../shared/services/data.service';
import { LangService } from '../../shared/services/lang.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-rotation-products',
  templateUrl: './rotation-products.component.html',
  styleUrls: ['./rotation-products.component.css']
})
export class RotationProductsComponent implements OnInit {
  rotationProducts: Array<SiteProduct> = [];
  numberOfGroups: number;
  productGroups: Array<any> = [];
  currentLang: string;
  screenWidth: number;
  screenHeight: number;

  constructor(private dataService: DataService,
              private langService: LangService,
              private translate: TranslateService,
              @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.screenHeight = this.document.documentElement.clientHeight;
    this.screenWidth = this.document.documentElement.clientWidth;
    // //  console.log('screenWidth =' + this.screenWidth);
    this.currentLang = this.translate.currentLang;
    this.loadProductsOnRotation();
  }

  loadProductsOnRotation() {
    /* ovo me dolje ne utiče na prevod */
    this.dataService.getDataFromUrl('products?promo=3&page=1&rows=12&status=A&sort=last_change_time&sortType=desc').subscribe(
      (rez: any) => {
        // tslint:disable-next-line:prefer-for-of
          if (rez.code === 200) {
            const products = [];
            for (const element of rez.data) {
              const pro = new SiteProduct(element);
              pro.showAppropriateLangData(this.currentLang);
              products.push(pro);
            }
            this.rotationProducts = products;
            // //  console.log(this.rotationProducts);
            this.splitProductsIntoGroups();

            // this.fillInTheGaps();
          } else {
            // //  console.log('Dogodila se greska');
          }
        });
  }

  splitProductsIntoGroups() {
    // tslint:disable-next-line: max-line-length
    this.numberOfGroups = this.rotationProducts.length / 3 > Math.round(this.rotationProducts.length / 3) ? Math.round(this.rotationProducts.length / 3) + 1 : Math.round(this.rotationProducts.length / 3);
    // //  console.log('Broj grupa: ' +  this.numberOfGroups);
    let groupIndex = 0;
    let singleIndex = 0;
    while(groupIndex < this.numberOfGroups) {
      let arr = [];
      for(let n = 0; n < 3; n++) {
        arr.push(this.rotationProducts[singleIndex]);
        singleIndex++;
      }

      this.productGroups.push(arr);
      groupIndex++;
    }
  }

  // fillInTheGaps() {
  //   let products = [];

  //   for(let i = 0; i < 12 - this.rotationProducts.length; i++) {
  //     products.push(new SiteProduct({
  //       id: i,
  //       sifra: '0000' + i,
  //       name_me: 'Proizvod #' + i,
  //       name_en: 'Product #' + i,
  //       description_me: '<p>Ovo je opis proizvoda. On takođe može biti <b>stilizovan</b>.</p>',
  //       description_en: '<p>This is a description of the product. Content here can be <b>styled</b> as well.</p>',
  //       product_slug_me: 'product-' + i,
  //       product_slug_en: 'product-' + i,
  //       brand_id: 1,
  //       brandName: 'MaxMara',
  //       price: i % 3 === 0 ? 290 : (i % 3 === 1 ? 100 : 220),
  //       sale_price: 0,
  //       image_url: 'https://cdn-images.farfetch-contents.com/14/58/42/64/14584264_27100480_480.jpg',
  //       small_image_url: 'https://cdn-images.farfetch-contents.com/14/58/42/64/14584264_27100480_480.jpg',
  //       image_orientation: 'V',
  //       prod_cat_id: 1,
  //       promo_position: 3,
  //       gallery_id: 1,
  //       gender_oriented: i % 3 === 0 ? 'Z' : ( i % 3 === 1 ? 'M' : 'D'),
  //       status: 'A',
  //       date_created: formatDate(new Date(), 'dd.MM.yyyy HH:mm', 'en'),
  //       user_created: 1,
  //       date_changed: formatDate(new Date(), 'dd.MM.yyyy HH:mm', 'en'),
  //       user_changed: 1,
  //       author: 'Admin',
  //       editor: 'Admin',
  //       fake: true
  //     }));
  //   }

  //   this.rotationProducts = this.rotationProducts.concat(products);
  //   // //  console.log(this.rotationProducts);
  //   this.splitProductsIntoGroups();

  // }

}
