import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { VisitorMailService } from '../shared/services/visitor-mail.service';
import { AgmMap } from '@agm/core';



@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  formWasSubmitted = false;
  actionSuccessfull = false;
  actionFailed = false;
  inTheProcess = false;
  lastResult: {
    code: number,
    status: string,
    message: string,
    data: any
  };

  constructor(private fb: FormBuilder,
              private mailService: VisitorMailService) { }

  ngOnInit() {
    this.createContactForm();
    // this.agmMap.triggerResize();

  }

  createContactForm() {
    this.contactForm = this.fb.group({
      // tslint:disable-next-line:max-line-length
      fullName: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      phone: [null, null],
      message: [null, Validators.required]
     });
  }

  sendMail(conactInfo: {
    fullName: string, email: string, phone: string, message: string
  }) {
    if (this.contactForm.invalid) {
      const err = {
        code: 1001,
        status: 'ERROR',
        message: 'Podaci nijesu pravilno unešeni. Polja označena sa zvjezdicom (*) su obavezna',
        data: null
      };
      this.lastResult = err;
      this.displayError(err);
      // Javi neku gresku
    } else {
      this.inTheProcess = true;
      this.formWasSubmitted = false; /* Da bi se makle poruke sa stranice prilikom ponovnog slanja */
      this.mailService.sendMailFromVisitor(conactInfo).subscribe(
        (result: any) => {
          this.lastResult = result;
          this.inTheProcess = false;

          if (result.code === 200) {
            this.showSuccess(result);
          } else {
            this.displayError(result);
          }
        });
    }
  }

  displayError(err: any) {
    this.formWasSubmitted = true;
    this.actionSuccessfull = false;
    this.actionFailed = true;
  }

  showSuccess(resp: any) {
    this.formWasSubmitted = true;
    this.actionFailed = false;
    this.actionSuccessfull = true;
    this.contactForm.reset();
  }

}
