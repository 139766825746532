
<div class="article-component-container pager">
  <div class="article-header mv-24">
      <h1 class="article-title text-center">
         {{  'faq' | translate }}
      </h1>
  </div>

  <div class="article-content">
    
  
    <div *ngIf="currentLang === 'me'" id="accordionMe">
      <div class="card">
        <div class="card-header" id="headingOne">
          <span class="mb-0 question-cont">
            <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              {{ 'isBloodGivingBad' | translate }}
            </button>
          </span>
        </div>
    
        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionMe">
          <div class="card-body">
            <div class="inner-pad">
              <p>
                Svaka zdrava osoba između 18 i 65 godina starosti može bez opasnosti za svoje zdravlje davati krv, 3 do 4 puta tokom jedne godine.
              </p> 

              <p>
                Zdrav organizam davaoca krvi vrlo brzo u potpunosti nadoknađuje količinu i sve sastave djelove date krvi: već unutr 24 časa organizam nadoknadi tekući dio krvi – plazmu i njene sastojke, broj trombocita i leukocita. Eritrociti se nadoknade u periodu od 4 do 6 sedmica.
              </p>

              <p>
                Prije svakog davanja krvi obavezno provjeravamo zdravstveno stanje davaoca krvi i skrining vrijednosti hematokrita u krvi. Davanje krvi nikako ne ugrožava zdravlje davaoca, već je i jedan oblik kontrole zdravstvenog stanja.
              </p>

              <div class="img-cont">
                <img src="../../../assets/images/faq/barometar.jpg" alt="Davanje krvi" class="w-100">
              </div>
              
            </div>            
          </div>
        </div>
      </div>



      <div class="card">
        <div class="card-header" id="headingTwo">
          <span class="mb-0 question-cont">
            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              {{ 'canIContractDisease' | translate }}
            </button>
          </span>
        </div>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionMe">
          <div class="card-body">
            <div class="inner-pad">
              <p>
                Tokom davanja krvi nije moguće dobiti zaraznu bolest. Pribor za uzimanje krvi je sterilan i za jednokratnu upotrebu – davalac je 100% siguran.
              </p>
            </div>     
          </div>
        </div>
      </div>


      <div class="card">
        <div class="card-header" id="headingThree">
          <span class="mb-0 question-cont">
            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              {{ 'whyNoCigaretesAfter' | translate }}
            </button>
          </span>
        </div>
        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionMe">
          <div class="card-body">
            <div class="inner-pad">
              <p>
                Nakon davanja krvi, davcaocima koji su pušači savjetuje se da ne koriste cigarete u narednih dva sata. Jedna od rjeđih, ali neugodnih reakcija organizma na pušenje je kratkotrajno stiskanje (spazam) krvnih žila u mozgu. Stoga, ako se puši odmah nakon završenog davanja krvi, kod nekih, posebno mlađih osoba, može doći do blage mučnine.
              </p>
            </div> 
        </div>
      </div>
    </div>


    <div class="card">
      <div class="card-header" id="heading4">
        <span class="mb-0 question-cont">
          <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
            {{ 'canIBecomeAddictedToGivingBlood' | translate }}
          </button>
        </span>
      </div>
      <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordionMe">
        <div class="card-body">
          <div class="inner-pad">
            <p>
              Davanje krvi može se započeti i prekinuti u svako doba između 18 i 65 godina života. Davanjem krvi ne nastaju nikakve štetne tjelene promjene ili posljedice po organizam.
            </p> 
          </div> 
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header" id="heading5">
        <span class="mb-0 question-cont">
          <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
            {{ 'howOftenCanIBeADonor' | translate }}
          </button>
        </span>
      </div>
      <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordionMe">
        <div class="card-body">
          <div class="inner-pad">
            <p>
              Muškarci mogu dati krv na svaka tri, a žene na svaka četiri mjeseca.
            </p> 
          </div> 
        </div>
      </div> 
    </div>

    <div class="card">
      <div class="card-header" id="heading6">
        <span class="mb-0 question-cont">
          <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
            {{ 'whatAreCommonSideEffectsToGivingBLood' | translate }}
          </button>
        </span>
      </div>

      <div id="collapse6" class="collapse" aria-labelledby="heading6" data-parent="#accordionMe">
        <div class="card-body">
          <div class="inner-pad">
            <p>
              Veoma rijetko se mogu javiti neželjene reakcije od kojih je vrijedno pomenuti pojavu lokalnog hematoma (modrice) ili osjećaj slabosti. Reakcije se mogu javiti ako se ne pridržavamo uputstava prije i/ili nakon davanja krvi.
            </p>
          </div>
        </div>
      </div>
    </div>

  
  
  
  </div>

  <div *ngIf="currentLang === 'en'" id="accordionEn">	
    <div class="card">		
      <div class="card-header" id="enHeadingOne">
        <span class="mb-0 question-cont">
          <button aria-controls="collapseOne" aria-expanded="false" class="btn btn-link collapsed" data-target="#enCollapseOne" data-toggle="collapse">
            {{ 'isBloodGivingBad' | translate }}
          </button>
        </span>
      </div>	
      <div aria-labelledby="enHeadingOne" class="collapse" data-parent="#accordionEn" id="enCollapseOne">
        <div class="card-body">
          <div class="inner-pad">
            <p>
              Any healthy person between the ages of 18 and 65 can donate blood without danger to their health, 3 to 4 times in one year.
            </p> 

            <p>
              A healthy blood donor's organism very quickly completely compensates for the amount and all the components of a given blood: within 24 hours the organism compensates for the liquid part of the blood - plasma and its components, the number of platelets and leukocytes. Erythrocytes are replenished in a period of 4 to 6 weeks.
            </p>

            <p>
              Before each blood donation, we must check the health condition of the blood donor and the screening of hematocrit values ​​in the blood. Blood donation does not endanger the health of the donor in any way, but it is also a form of health control.
            </p>

            <div class="img-cont">
              <img src="../../../assets/images/faq/barometar.jpg" alt="Davanje krvi" class="w-100">
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="enHeadingTwo">
        <span class="mb-0 question-cont">
          <button aria-controls="collapseTwo" aria-expanded="false" class="btn btn-link collapsed" data-target="#enCollapseTwo" data-toggle="collapse"> 
            {{ 'canIContractDisease' | translate }}
          </button>
        </span>
      </div>
      <div aria-labelledby="enHeadingTwo" class="collapse" data-parent="#accordionEn" id="enCollapseTwo">
        <div class="card-body">
          <div class="inner-pad">
            <p>
              It is not possible to get an infectious disease during blood donation. Blood collection equipment is sterile and disposable - the donor is 100% safe.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="enHeadingThree">
        <span class="mb-0 question-cont">
          <button aria-controls="collapseThree" aria-expanded="false" class="btn btn-link collapsed" data-target="#enCollapseThree" data-toggle="collapse">
            {{ 'whyNoCigaretesAfter' | translate }}
          </button>
        </span>
      </div>
      <div aria-labelledby="enHeadingThree" class="collapse" data-parent="#accordionEn" id="enCollapseThree">
        <div class="card-body">
          <div class="inner-pad">
            <p>
              After donating blood, donors who are smokers are advised not to use cigarettes for the next two hours. One of the rarer but unpleasant reactions of the body to smoking is short-term compression (spasm) of blood vessels in the brain. Therefore, if you smoke immediately after the end of blood donation, some, especially younger people, may experience mild nausea.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="enHeading4">
        <span class="mb-0 question-cont">
          <button aria-controls="collapse4" aria-expanded="false" class="btn btn-link collapsed" data-target="#enCollapse4" data-toggle="collapse">
            {{ 'canIBecomeAddictedToGivingBlood' | translate }}
          </button>
        </span>
      </div>
      <div aria-labelledby="enHeading4" class="collapse" data-parent="#accordionEn" id="enCollapse4">
        <div class="card-body">
          <div class="inner-pad">
            <p>
              Blood donation can be started and stopped at any time between 18 and 65 years of age. Giving blood does not cause any harmful bodily changes or consequences for the body.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="enHeading5">
        <span class="mb-0 question-cont">
          <button aria-controls="collapse5" aria-expanded="false" class="btn btn-link collapsed" data-target="#enCollapse5" data-toggle="collapse">
            {{ 'howOftenCanIBeADonor' | translate }}
          </button>
        </span>
      </div>
      <div aria-labelledby="enHeading5" class="collapse" data-parent="#accordionEn" id="enCollapse5">
        <div class="card-body">
          <div class="inner-pad">
            <p> Men can donate blood every three months and women every four months. </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="enHeading6">
        <span class="mb-0 question-cont">
          <button aria-controls="collapse6" aria-expanded="false" class="btn btn-link collapsed" data-target="#enCollapse6" data-toggle="collapse">
            {{ 'whatAreCommonSideEffectsToGivingBLood' | translate }}
          </button>
        </span>
      </div>
      <div aria-labelledby="enHeading6" class="collapse" data-parent="#accordionEn" id="enCollapse6">
        <div class="card-body">
          <div class="inner-pad">
            <p>Very rarely, side effects can occur, of which it is worth mentioning the appearance of a local hematoma (bruise) or a feeling of weakness. Reactions may occur if we do not follow the instructions before and / or after donating blood.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="p-2 mv-24"></div>


 

<!--  -->