<table *ngIf="type === 1">
  <thead>
    <th>Mjere brenda</th>
    <th>Standard</th>
  </thead>

  <tbody>
    <tr>
      <td>46</td>
      <td>XS</td>
    </tr>
    <tr>
      <td>48</td>
      <td>S</td>
    </tr>
    <tr>
      <td>50</td>
      <td>M</td>
    </tr>
    <tr>
      <td>52</td>
      <td>L</td>
    </tr>
    <tr>
      <td>54</td>
      <td>XL</td>
    </tr>
    <tr>
      <td>56</td>
      <td>XXL</td>
    </tr>
    <tr>
      <td>58</td>
      <td>XXXL</td>
    </tr>
    <tr>
      <td>60</td>
      <td>4XL</td>
    </tr>                
    <tr>
      <td>62</td>
      <td>5XL</td>
    </tr>
  </tbody>
</table>

<table *ngIf="type === 2">
  <thead>
    <th>EU</th>
    <th>USA</th>
  </thead>

  <tbody>
    <tr *ngFor="let s of shoesSizes">
      <td>{{ 36 + (s * 0.5) }}</td>
      <td>{{ 3 + (s * 0.5) }}</td>
    </tr>
    
  </tbody>
</table>

<p *ngIf=" type === 0"> Za ovaj artikal postoji samo jedna veličina</p>