import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ScrollingService } from '../../shared/services/scrolling.service';
import { MenuOption } from '../../shared/models/menu-option.model';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from '../../shared/services/lang.service';
import { StorageService } from '../../shared/services/storage.service';
import { Config } from '../../../Config';


@Component({
  selector: 'app-sub-navigation-menu',
  templateUrl: './sub-navigation-menu.component.html',
  styleUrls: ['./sub-navigation-menu.component.css']
})
export class SubNavigationMenuComponent implements OnInit {
  @Input('subMenuStatus') subMenuStatus: number; // 0 - never opened, 1 - opened, 2 - closed
  @Input('menuOption') menuOption: MenuOption;
  @Output('menuLostFocus')  menuLostFocus = new EventEmitter<boolean>();
  config = new Config();

  topVisible = false;
  activeLink = 1;
  currentLang: string;

  constructor(private scrollingService: ScrollingService,
              private translateService: TranslateService,
              private storage: StorageService,
              private langService: LangService) { }

  ngOnInit() {
    this.storage.selectedOption.subscribe((option) => {
      this.menuOption = option;
    })
    this.currentLang = this.translateService.currentLang;
    this.langService.languageChanged.subscribe((lang) => {
      this.currentLang = lang;
    });
  }

  hideSubMenuIfApplicable(ev: MouseEvent) {
      this.subMenuStatus = 0;
      this.menuLostFocus.emit(true);
  }

  selectActiveLink(ev: number) {
    this.activeLink = ev;
  }


}
