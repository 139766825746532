import { Component, OnInit } from '@angular/core';
import { ArticleService } from '../shared/services/article.service';
import { Article } from '../shared/models/article.model';
import { TranslateService } from '@ngx-translate/core';
import { SiteArticle } from '../shared/models/site-article.model';
import { LangService } from '../shared/services/lang.service';
import { DataService } from '../shared/services/data.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  public blogArticles: SiteArticle[] = [];
  tempArticles: SiteArticle[] = [];
  requestsFinished = 0;
  public currentPage = 1;

  constructor(private articleService: ArticleService,
              private translate: TranslateService,
              private langService: LangService,
              private dataService: DataService) {
  }

  ngOnInit() {
    this.loadArticles(this.translate.currentLang);
    this.langService.languageChanged.subscribe((lang: string) => {
      this.blogArticles.forEach(element => {
        element.showAppropriateLangData(lang);
      });
    });
  }

  onPageSelected(pageEvent: number) {
    this.currentPage = pageEvent;
    this.loadArticles(this.translate.currentLang);
  }

  loadArticles(lang: string) {
    this.articleService.getArticlesByCategory(4, this.currentPage, 10, true).subscribe(
      (rez: any) => {
        // tslint:disable-next-line:prefer-for-of
          if (rez.code === 200) {
            for (const element of rez.data) {
              const art = new SiteArticle(element);
              art.showAppropriateLangData(lang);
              this.tempArticles.push(art);
            }
            this.requestsFinished++;
            if(this.requestsFinished === 3) {
              this.resolveArticles();
            }
          } else {
            // //  console.log('Dogodila se greska');
          }
        });

    this.articleService.getArticlesByCategory(5, this.currentPage, 10, true).subscribe(
      (rez: any) => {
        // tslint:disable-next-line:prefer-for-of
          if (rez.code === 200) {
            for (const element of rez.data) {
              const art = new SiteArticle(element);
              art.showAppropriateLangData(lang);
              this.tempArticles.push(art);
            }
            this.requestsFinished++;
            if(this.requestsFinished === 3) {
              this.resolveArticles();
            }
          } else {
            // //  console.log('Dogodila se greska');
          }
        });

    this.articleService.getArticlesByCategory(6, this.currentPage, 10, true).subscribe(
      (rez: any) => {
        // tslint:disable-next-line:prefer-for-of
          if (rez.code === 200) {
            for (const element of rez.data) {
              const art = new SiteArticle(element);
              art.showAppropriateLangData(lang);
              this.tempArticles.push(art);
            }
            this.requestsFinished++;
            if(this.requestsFinished === 3) {
              this.resolveArticles();
            }
          } else {
            // //  console.log('Dogodila se greska');
          }
        });
   }

   resolveArticles() {
    this.blogArticles = this.tempArticles.sort((a,b) => {
      return a.id > b.id ? -1 : 1;
    }).slice(0, 20);
  
   }

}
