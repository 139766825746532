<div class="site-options f-r">
  <div class="search-box right-pad-only">
    <div class="ng-autocomplete f-r">
      <span class="fa fa-search"></span>
      <ng-autocomplete #autocomplete
        placeHolder="Search"
        [minQueryLength]="2"
        [data]="data"
        [searchKeyword]="'name'"
        (selected)='selectEvent($event)'
        (inputChanged)='onChangeSearch($event)'
        (inputFocused)='onFocused($event)'
        [isLoading]="isLoading"
        [itemTemplate]="itemTemplate">                                 
      </ng-autocomplete>
       
      <ng-template #itemTemplate let-item>
        <div class="autocomplete-item" 
        routerLink="{{ item.type === 'brand' ? '/products/brand/' + item.link +'/' + item.id : 
        (item.type === 'category' ? '/products/category/' + item.id + '/' + item.link : '/product/' + item.id + '/' + item.link) }}">
          <span class=" rem-1" [innerHTML]="item.type === 'brand' ? ('brand' | translate) + ' ' + item.name : 
          (item.type === 'category' ? ('category' | translate) + ' ' + item.name : item.name)"></span>
        </div>        
      </ng-template>
       
      <ng-template #notFoundTemplate let-notFound>
      <div [innerHTML]="notFound"></div>
      </ng-template>
      </div>
  </div>

</div>