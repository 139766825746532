import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SiteArticle } from '../../../../shared/models/site-article.model';

@Component({
  selector: 'app-news-preview-item',
  templateUrl: './news-preview-item.component.html',
  styleUrls: ['./news-preview-item.component.css']
})
export class NewsPreviewItemComponent implements OnInit {
  @Input('article') article: SiteArticle;

  constructor(public translate: TranslateService) { }

  ngOnInit() {
  }

}
