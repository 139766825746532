<div class="promo-two-container w-100 mv-24">
  <div *ngFor="let news of promoNews" class="f-c vw40">
    <a class="no-decor" [routerLink]="['/article/' + news.id + '/' + news.postNameMe]">
      <div class="promo-two-box f-c">
        <div class="promo-img-cont mb-12">
          <img src="{{ news.imageUrl }}" alt="{{ news.title }}" class="d-block w-100 promo-two-img">
        </div>

        <div class="promo-two-subtitle mt-3">
          <h5 class="rem-1-05 mb-0">{{ news.subtitle }}</h5>
        </div>
    
        <div class="promo-two-title mt-1">
          <h4 class="rem-1-85">{{ news.title }}</h4>
        </div>
    
        
      </div>
    </a>
    
  </div>

  <!-- <div class="promo-two-box f-c col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 f-c">
    <div class="promo-img-cont">
      <img src="assets/images/promo-two-1.jpeg" alt="promo-2" class="d-block w-100 promo-two-img">
    </div>

    <div class="promo-two-title">
      <h4>Lorem Ipsum</h4>
    </div>

    <div class="promo-two-subtitle">
      <h5>Opis samog proizvoda</h5>
    </div>
  </div> -->
</div>