<div class="slide-wrapper w-100">
  <a class="no-decor" [routerLink]="['/'+ translate.currentLang + '/clanak/' + slide.id + '/' + slide.postNameMe ]">
    <div class="w-100 slider-image" [ngStyle]="{'background-image': 'url(' + slide.imageUrl + ')'}">
      <div class="slide-overlay f-c">
        <div class="slide-item-meta">
          <div class="slide-title">
            <h2 class="wt bold rem-1-55">{{ slide.title }}</h2>
          </div>
  
          <div class="slide-item-buttons f-r">
            <button class="btn-slider filled" [routerLink]="['/'+ translate.currentLang + '/clanak/' + slide.id + '/' + slide.postNameMe ]">
              {{ 'findOutMore' | translate }} 
            </button>
            <!-- <button class="btn-slider filled ml-3"> {{ 'scheduleMeDonation' | translate }}</button> -->
  
          </div>
        </div>
      </div>
      
    </div>

    <div class="w-100 slider-image-mobile" [ngStyle]="{'background-image': 'url(' + slide.imageUrl + ')'}">
      <div class="slide-overlay f-c">
        <div class="slide-title">
          <h2 class="wt bold rem-1-25">{{ slide.title }}</h2>
        </div>
      </div>
    </div>


  </a>
  

</div>