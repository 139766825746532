<nav *ngIf="screenWidth > 991" id="mainNavbar" class="navbar navbar-expand-lg navbar-light navbar-custom-bg f-c animated
  {{ !topVisible && scrolledUp ? 'scrolled-up-menu faster slideInDown' : (!topVisible && !scrolledUp ? 'fadeOutUp' : '') }} ">

  <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 f-r inside-navigation">

    <div class="navigation first-col f1">
        <div class="logo-header f-r">
          <a [routerLink]="'/' + lang + '/home'">
            <div class="vsite-logo">
                <img class="vsite-logo-img" src="assets/images/zavod_logo.jpg" alt="Zavod za transfuziju krvi Crne Gore">
            </div>
          </a>
            <div class="vsite-text">
              <h1 class="rem-1-25 bt">{{ 'site-name' | translate }}</h1>
            </div>
        </div>
    </div>

    <div class="navigation second-col f-r f2">

      <div *ngFor="let option of menuOptions" class="menu-option f-c f1" [routerLink] = "'/' + option.link" 
      (mouseover)="setOptionHovered(option)" (mouseleave)="unhoverOption(option)">
        <div class="option-name pl-2 pr-2 f1 d-flex align-items-center justify-content-center" 
        [ngStyle]="{'background-color': option.isHovered || (selectedOption != null && selectedOption.nameMe === option.nameMe) ? option.colorCode : '#fff', 'color': option.isHovered && option.colorCode != '#fff' ? '#fff' : '#303030'}">
          <span *ngIf="option.subOptions != null" class="fa fa-chevron-down p-1 fs-12"></span>
          <span >{{ translate.currentLang === 'me' ? option.nameMe : option.nameEn }}</span>

        </div>

        <div class="option-border" [ngStyle]="{'background-color': option.colorCode}">

        </div>
      </div>
      
    </div>
    
    

    
    
        
  </div>   
  

</nav>

<nav class="navbar mobile navbar-expand-lg navbar-light f-r">
  <div class="f-r justify-content-between align-items-center nav-first-row">

    <div>
      <button #menuToggleButton class="navbar-toggler" type="button" 
      data-toggle="collapse" data-target="#navbarMobile" aria-controls="navbarMobile" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon fa fa-bars"></span>
      </button>
    </div>

    <div class="vsite-logo f1" routerLink="/home">
      <a [routerLink]="'/' + lang + '/home'">
        <img class="vsite-logo-img" src="assets/images/zavod_logo.jpg" alt="SAMMS">
      </a>
    </div>

    <div class="vsite-text">
      <h1 class="rem-1-25 bt">{{ 'site-name' | translate }}</h1>
    </div>

    <!-- <div class="mobile-controls f-r">
      <div class="site-option">
        <span class="fa fa-search"></span>
      </div>
    </div> -->
  
    
  </div>
  
  

  <div class="collapse navbar-collapse mobile f-c animated" id="navbarMobile">  

    <div class="options-mobile pt-2 w-100">
      <div *ngFor="let option of menuOptions" class="menu-option f-c f1" (click)="navigateMobile(option)">
        <div class="option-name pl-2 pr-2 f1 d-flex align-items-center pl-3" 
        [ngStyle]="{'border-left': '8px solid ' + option.colorCode }">
          <div *ngIf="option.subOptions == null">
            <span >{{ translate.currentLang === 'me' ? option.nameMe : option.nameEn }}</span>
          </div>

          <div *ngIf="option.subOptions != null">
            <a class="no-decor" data-toggle="collapse" href="{{ '#' + option.nameMe.replace(' ', '_').toLowerCase() }}" role="button" aria-expanded="false" aria-controls="collapseExample">
              <span class="fa fa-chevron-down p-1 pr-2 fs-12"></span>
              <span >{{ translate.currentLang === 'me' ? option.nameMe : option.nameEn }}</span>
            </a>

            <div class="collapse" id="{{ option.nameMe.replace(' ', '_').toLowerCase() }}">
              <div class="p-2 w-100">
                <div *ngFor="let sub of option.subOptions" class="sub-option">
                  <span class="bt"><a *ngIf="sub.target == null || sub.target === undefined" class="no-decor sub-link bt" [routerLink]="sub.link"> {{ translate.currentLang === 'me' ? sub.nameMe : sub.nameEn }}</a></span>
                  <span class="bt"><a *ngIf="sub.target != null && sub.target !== undefined" class="no-decor sub-link bt" 
                    href="{{ config.baseUrl + 'uploads/Statut_14110a5ca3.pdf' }}" target="_blank"> {{ translate.currentLang === 'me' ? sub.nameMe : sub.nameEn }}</a></span>
                </div>
              </div>
            </div>
          </div>

          
          

        </div>

        <div class="option-border" [ngStyle]="{'background-color': option.colorCode}">

        </div>
      </div>

      <div class="lang-picker mt-5 w-100 text-center">
        <a class="cursor-pointer right rem-0-95" [ngClass]="{'hover': IfMe()}" (click) ="changeLanguage('me')">ME </a>|
        <a class="cursor-pointer right rem-0-95"  [ngClass]="{'hover': !IfMe()}" (click) ="changeLanguage('en')">EN</a>
      </div>
    </div>

    


  </div>
</nav>

<div *ngIf="subMenuStatus == 1 || (optionSelected != null && optionSelected.subOptions != null)" class="w-100">
  <div  class="sub-menu-container col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 f-r">
    <div class="sub-segment first-col f1">
    </div>
  
    <div class="sub-segment second-col f-r f2">
      <app-sub-navigation-menu [subMenuStatus]="subMenuStatus" (menuLostFocus)="hideSubMenuIfApplicable()" 
      (mouseleave)="hideSubMenuIfApplicable()"></app-sub-navigation-menu>
    </div>
  </div>
</div>

