import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '../../../Config';
import { PromoMaterial } from '../../shared/models/promo-material.model';
import { DataService } from '../../shared/services/data.service';
import { LangService } from '../../shared/services/lang.service';

@Component({
  selector: 'app-promo-material',
  templateUrl: './promo-material.component.html',
  styleUrls: ['./promo-material.component.css']
})
export class PromoMaterialComponent implements OnInit {
  materials: Array<PromoMaterial> = [];
  currentLang: string;
  config = new Config();
  errorExists = false;

  constructor(private dataService: DataService,
              private translate: TranslateService,
              private langService: LangService) { }

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
    this.langService.languageChanged.subscribe((lang) => {
      this.translate.currentLang = lang;
      this.currentLang = lang;
    })
    this.loadMaterials();
  }

  loadMaterials() {
    this.materials = [];
    /* ovo me dolje ne utiče na prevod */
    this.dataService.getDataFromUrl('promo-materijals?&_start=0&_limit=50&_sort=created_at:ASC').subscribe(
      (rez: any) => {
        // tslint:disable-next-line:prefer-for-of
        //  console.log(rez);
        if (rez instanceof Array && rez.length > 0) {
          const docs = [];
          for (const element of rez) {
            const dok = element;
            docs.push(dok);
          }
          this.materials = docs;

        } else {
          this.errorExists = true;
          // //  console.log('Dogodila se greska');
        }
      }, (err) => {
        this.errorExists = true;
      });
  }
}
