export class Config {

    baseUrl = 'https://admin.ztkcg.me/';
    imgUrl = 'https://admin.ztkcg.me';
    imgUrlTest = 'https://admin.ztkcg.me/';
    fileUrl = 'https://admin.ztkcg.me';


    mapbox = {
      accessToken: 'pk.eyJ1IjoidnVrZGp1IiwiYSI6ImNrZjhjZWo0ZjBhc2wyeG1xN2drejc0N3EifQ.SozbgcNqueO7rbuLrRBCmg'
    };


  }
