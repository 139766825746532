import { Injectable } from '@angular/core';
import { Article } from '../models/article.model';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, forkJoin, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { SiteArticle } from '../../shared/models/site-article.model';
import { Config } from '../../../Config';
import { map, catchError } from 'rxjs/operators';



@Injectable()
export class ArticleService {
  baseUrl: string;
  private config: Config;
  header: HttpHeaders;

  constructor(private translate: TranslateService,
              private http: HttpClient) {
    this.config = new Config();
    this.baseUrl = this.config.baseUrl;
    this.header = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }


  getArticleById(id: number, isAdmin: boolean): Observable<any> {
    let url = this.baseUrl + 'posts/' + id;
    return this.http.get<any[]>(url).pipe(map(
      (results) => results));
  }



  getArticlesByCategory(cat: number, page: number, rows: number, isHome?: boolean, sort?: string): Observable<any> {
    const sortUrlParam = sort != null && sort !== undefined ? '&sort=' + sort : '';
    let url = this.baseUrl + 'posts?';
    if (cat == null || cat === undefined) {
      url += 'status=D' + '&page=' + page + '&rows=' + rows + sortUrlParam;
    } else {
      url += 'category=' + cat + '&status=D' + '&page=' + page + '&rows=' + rows + sortUrlParam;
    }

    if(isHome) {
      url += '&slider=false';
    }
    return this.http.get<any[]>(url).pipe(map(
      (results) => results));
  }

  getSliderArticles(page: number, rows: number, sort?: string): Observable<any> {
    const sortUrlParam = sort != null && sort !== undefined ? '&sort=' + sort : '';
    const url = this.baseUrl + 'posts?slider=true' + '&page=' + page + '&rows=' + rows  + '&status=D' + sortUrlParam;
    // //  console.log(url);
    return this.http.get<any[]>(url).pipe(map(
      (results) => results));
  }

  searchArticles(term: string, status: string, page: number, rows: number, sort?: string) {
    const sortUrlParam = sort != null && sort !== undefined ? '&sort=' + sort : '';
    const url = this.baseUrl + 'posts/search?term=' + term + '&page=' + page + '&rows=' + rows  + '&status=' + status + sortUrlParam;
    // //  console.log(url);
    return this.http.get<any[]>(url).pipe(map(
      (results) => results));
  }
}
