import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { NotificationWindowService } from '../notification-window.service';

@Injectable()

export class AuthGuard implements CanActivate {
    constructor(private auth: AuthService,
                private notificationService: NotificationWindowService,
                private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
          const currentUser = this.auth.loggedInUser;
          // //  console.log('Provjerava se autorizacija...');
          // //  console.log('Korisnik:');
          // //  console.log(currentUser);
          // //  console.log('role dozvoljene na ruti: ' + route.url);
          // //  console.log(route.data);

          if (currentUser) {
            if(this.auth.isTokenExpired()){
              this.notificationService.showNotification({message: 'Istekla sesija!', status: false});
              this.router.navigate(['/admin-login']);
              return false;
            }
            return true;
          } else {
              // User not logged in
              // //  console.log('User not logged in');
              this.router.navigate(['/admin-login']);
              // this.navService.goToPathInCurrentOutlet(this.routerExtensions, 'default');

              return false;
          }

        }
          
}
