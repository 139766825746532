import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SiteArticle } from '../../shared/models/site-article.model';


@Component({
  selector: 'app-article-preview',
  templateUrl: './article-preview.component.html',
  styleUrls: ['./article-preview.component.css']
})
export class ArticlePreviewComponent implements OnInit {
  @Input() article: SiteArticle;

  constructor(private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.article.videoUrl = this.getYouTubeVideoUrl(this.article.videoUrl);
  }

  getYouTubeVideoUrl(videoUrl: string) {
    let url: string;
    if (videoUrl != null) {
      if (videoUrl.indexOf('&') === -1) {
        url = 'https://www.youtube.com/embed/' + videoUrl.slice(videoUrl.indexOf('?v=') + 3);
      } else {
        url = 'https://www.youtube.com/embed/' + videoUrl.slice(videoUrl.indexOf('?v=') + 3, videoUrl.indexOf('&'));
      }
      return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      return null;
    }
  }

}
