import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ProductCategory } from '../../../shared/models/product-category.model';
import { DataService } from '../../services/data.service';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from '../../services/lang.service';
import { Brand } from '../../../shared/models/brand.model';
import { Router } from '@angular/router';


@Component({
  selector: 'app-filter-categories',
  templateUrl: './filter-categories.component.html',
  styleUrls: ['./filter-categories.component.css']
})
export class FilterCategoriesComponent implements OnInit {
  @Input('brand') brand: Brand;
  @Input('categorySelectedId') categorySelectedId;
  @Input('isOnItsPage') isOnItsPage: boolean;
  categories: Array<any> = [];
  errorExists = false;
  currentLang: string;
  @Output('categorySelection') categorySelection = new EventEmitter<number>();



  constructor(private dataService: DataService,
              private translate: TranslateService,
              private router: Router,
              private langService: LangService) { }

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
    this.langService.languageChanged.subscribe((lang) => {
      this.currentLang = lang;
    });

    this.loadCategories();
  }

  loadCategories() {
    this.dataService.getDataFromUrl('product-categories/levels').subscribe((resp: any) => {
      const cts = [];
      if(resp.code === 200) {
        if (resp.data.length > 0) {
            resp.data.forEach((cat: any) => {
              cat.opened = false;
              cts.push(cat);
            });

            this.categories = cts.filter((el: any) => {
              return el.gender_oriented === 'U' || el.gender_oriented === this.brand.gender;
            });
        } else {
          this.errorExists = true;
          // //  console.log('Nema kategorija');
          // this.dataService.maxPageReached.emit(true);
        }
      } else {
        this.errorExists = true;
      }
    },
    (err) => {
      this.errorExists = true;
      // //  console.log(err);
    });
  }

  categorySelected(ev: any) {
    // this.categorySelectedId = ev;
    // // //  console.log('Promijenjena je kategorija! N(id): ' + ev);
    // this.categorySelection.emit(ev);

    // //  console.log(ev);
    this.categorySelectedId = ev.id;
    if(this.isOnItsPage) {
      // tslint:disable-next-line: max-line-length
      const link = '/products/category/' + ev.id + '/' + (this.currentLang === 'me' ? ev.name_me.toLowerCase().replace(/\s/g, '-') : ev.name_en.toLowerCase().replace(/\s/g, '-'));
      this.router.navigate([link]);
    } else {
      this.categorySelection.emit(ev.id);
    }
  }

  toggleSubFilters(ind: number) {
    const isOpeningAction = !this.categories[ind].opened;
    if (isOpeningAction) {
      for (let i = 0; i < this.categories.length; i++) {
        if ( i !== ind) {
          this.categories[i].opened = false;
        }
      }
    }
    this.categories[ind].opened = !this.categories[ind].opened;
    if(this.categories[ind].subcategories == null && this.categories[ind].subcategories === undefined) {
      this.categorySelected(this.categories[ind]);
    }
  }

}
