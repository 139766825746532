import { Observable, isObservable } from "rxjs";

export class Article {
    public id: number;
    public subtitle: string | Observable<string>;
    public title: string | Observable<string>;
    public lead: string | Observable<string>;
    public htmlText: string | Observable<string>;
    public imageUrl: string;
    public imageCopyright: string;
    public images: string[];
    public imageIndex: number;
    public createdAt: Date;
    public link: string;
    public category: number;
    /*
        0 - slider,
        1 - kultura,
        2 - projekti,
        3 - manifestacije, događaji,
    */

    public markupStrings: Array<string>;
    /*
        markupStrings[0] => subtitle,
        markupStrings[1] => title,
        markupStrings[2] => lead,
        markupStrings[3] => htmlText,
    */


    constructor(
        obj: {
            id: number,
            markupStrings: Array<string>,
            subtitle?: string | Observable<string>,
            title?: string | Observable<string>,
            lead?: string | Observable<string>,
            htmlText?: string | Observable<string>,
            images?: string[],
            createdAt?: any,
            imageIndex?: number,
            imageUrl: string,
            imageCopyright: string,
            link: string,
            category: number
        }
    ) {
        this.id = obj.id;
        this.markupStrings = obj.markupStrings;
        this.imageUrl = obj.imageUrl;
        this.imageCopyright = obj.imageCopyright;
        this.link = obj.link;
        this.category = obj.category;
        this.createdAt = new Date(obj.createdAt)

        if (obj.images !== undefined) {
            this.images = obj.images;
        }

        if (obj.imageIndex !== undefined) {
            this.imageIndex = obj.imageIndex;
        }

        if (isObservable(obj.subtitle)) {
            obj.subtitle.subscribe((res) => {
                this.subtitle = res;
            });
        } else {
            this.subtitle = obj.subtitle;
        }

        if (isObservable(obj.title)) {
            obj.title.subscribe((res) => {
                this.title = res;
            });
        } else {
            this.title = obj.title;
        }

        if (isObservable(obj.lead)) {
            obj.lead.subscribe((res) => {
                this.lead = res;
            });
        } else {
            this.lead = obj.lead;
        }

        if (isObservable(obj.htmlText)) {
            obj.htmlText.subscribe((res) => {
                this.htmlText = res;
            });
        } else {
            this.htmlText = obj.htmlText;
        }
    }
}
