<div class="f-c home-container">
  <!-- <div class="w-100 top-message-mobile pt-3 pb-3">
    <div class="message-header">
      <span class="rem-0-95 wt">{{ 'youCanScheduleViaSite' | translate }}</span>
    </div>

    <div class="message-options f-r pt-3 align-items-center">
      <div class="schedule-container f1">
        <button class="btn-schedule">
          <img class="btn-schedule-img pr-1" src="../../assets/images/blood_donation_red.png" alt="{{ 'scheduleMeDonation' | translate }}">
          {{ 'scheduleMeDonation' | translate }}
        </button>
      </div> 

      <div class="question-container f1 f-c">
        <span class="underline wt rem-1 text-center"> {{ 'amIEligible' | translate }}</span>
      </div>
    </div>
  </div> -->

  <div class="mt-3 mb-3 pt-3 pb-3 row col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 f-r responsive home-items">
    <div class="slider-cont f3 mt-3 mb-3 px-3-desktop">
      <app-slider2></app-slider2>
    </div>    

    <div class="supply-button-mobile f-c" (click)="onSupplyButtonClicked()">
      <div class="supply-text">
        <span>{{ 'supplyState' | translate }}</span>
      </div>
    </div>

    <div class="f2 mt-3 mb-3 px-3-desktop">
      <app-contact-region></app-contact-region>
    </div>

  </div>

  <div class="supply-button-sticky f-c" (click)="onSupplyButtonClicked()">
    <div class="supply-icon d-flex">
      <img src="../../assets/images/color_blood_bag.png" alt="{{ 'supplyState' | translate }}">
    </div>
    <div class="supply-text">
      <span>{{ 'supplyState' | translate }}</span>
    </div>
  </div>

  
  <div class="f-c pt-3 section-home w-100 justify-content-center desktop">
    <app-news-section></app-news-section>
    <app-darujkrv-me></app-darujkrv-me>
  </div>

  <div class="f-c pt-3 section-home w-100 mobile">
    <app-darujkrv-me></app-darujkrv-me>
    <app-news-section></app-news-section>
  </div>

  
</div>
