<div class="contact-region p-3">
  <div class="region-title text-center">
    <span class="bold">{{ 'whenAndWhereToDonate' | translate }}</span>
  </div>
  <p class="max-300 fs-14 mt-1">{{ 'workingTimeInAllUnits' | translate }}</p>
  <table class="mv-12">
    <tr>
      <th>Transfuzijska Ustanova</th>
      <th>Kontakt telefon</th>
    </tr>
    <tr>
      <td>Podgorica</td>
      <td>020 240 715</td>
    </tr>
    <tr>
      <td>Bar</td>
      <td>030/342-212</td>
    </tr>
    <tr>
      <td>Berane</td>
      <td>068/043-111</td>
    </tr>
    <tr>
      <td>Bijelo Polje</td>
      <td>050/486-102</td>
    </tr>
    <tr>
      <td>Cetinje</td>
      <td>041/232-740</td>
    </tr>
    <tr>
      <td>Kotor</td>
      <td>068/047-111</td>
    </tr>
    <tr>
      <td>Nikšić</td>
      <td>068/042-111</td>
    </tr>
    <tr>
      <td>Pljevlja</td>
      <td>068/048-111</td>
    </tr>
    <tr>
      <td>Risan</td>
      <td>032/326-163</td>
    </tr>
  </table>
</div>
