<div class="container">
  <div class="container mv-12 mb-128">
    <div>
      <!-- <p class="rem-1"><img src="/images/samms.jpg" alt="samms"></p> -->
      <app-image-loader [classnames]="'custom-ok-image f-c mt-48'"
                      [imageSrc]="'../../assets/images/samms_baner.jpg'"
                      [copyright]="''">


      </app-image-loader>
      <p class="rem-1">&nbsp;</p>
      <p class="rem-1">Kompanija Samms je osnovana u maju 1994. godine u vlasništvu Sretena Radonjića.</p>
      <p class="rem-1">Početak rada kompanije je obilježen slovenačkim brendom Mura. Prepoznata još u bivšoj Jugoslaviji kao simbol tradicije i kvaliteta, Mura je bila i prva franšizna trgovina u Crnoj Gori.</p>
      <p class="rem-1">Danas, nakon skoro dvadeset pet godina uspješnog poslovanja na teritoriji Crne Gore, SAMMS je vlasnik franšiznih trgovina za 12 svjetskih modnih brendova:&nbsp;<strong>MaxMara, Weekend, Max&amp;co, PennyBlack, LiuJo, Twin-Set, HugoBoss, Paul&amp;Shark, Ermenegildo Zegna, Gerry Weber, Pinko i Petit Bateau.</strong></p>
      <p class="rem-1">Pored franšiznih trgovina u Podgorici SAMMS ima i monobrend radnje na primorju:Max Mara u Portu, Liu Jo u Tivtu i Baru, Gerry Weber u Baru, Paul&amp;Shark u Kotoru i u okviru hotela Splendid.</p>
      <p class="rem-1">Takodje, SAMMS ima i multibrend shopove u shopping centru Plaza u Budvi i u TC Lekic u Baru.</p>
      <p class="rem-1">Svih ovih godina firma SAMMS funkcioniše kao društveno odgovorna, organizujući, ali i učestvujući u brojnim humanitarnim akcijama. Urađeno je mnogo na ljepšem izgledu dijela grada u kojem su i smještene sve trgovine. Zahvaljujući tome, Bulevar Džordža Vašingtona od prvobitno bezlične ulice danas izgleda kao ulica u nekoj od svjetskih metropola, pa je poznata kao crnogorski Montenapoleone.</p>
      <p class="rem-1">SAMMS je otvarajući franšizne trgovine u glavnom gradu, koje predstavljaju specijalizovane radnje identične onima koje se mogu vidjeti u Rimu, Milanu, Parizu ili Londonu, dao doprinos kulturi i stilu kako grada tako i cijele države.</p>
      <p class="rem-1">Praćenje modnih trendova i otvaranje novih franšiznih trgovina kao i definisanje kvalitetnog koncepta tržišno i modno orjentisane kompanije osnova je poslovne politike&nbsp; SAMMS-a.</p>
     

      <p>
      </p>

      <p class="rem-1-55 bold mt-5">Poslovni podaci:</p>
      <table class="business-data mv-12 mb-5">
        <tr>
          <td class="first-cell">Naziv firme: </td>
          <td>“SAMMS” doo</td>
        </tr>
        <tr>
          <td class="first-cell">Registracioni broj: </td>
          <td>5-0011532/017</td>
        </tr>
        <tr>
          <td class="first-cell">PDV/PIB broj: </td>
          <td>02196727 30/31-00086-5</td>
        </tr>
        <tr>
          <td class="first-cell">Adresa:</td>
          <td>Bulevar Džordža Vašingtona 56, Podgorica, Crna Gora</td>
        </tr>

      </table>
    </div>

  </div>  
</div>

