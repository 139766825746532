import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MarkdownModule, MarkedOptions, MarkedRenderer  } from 'ngx-markdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import {NgcCookieConsentModule, NgcCookieConsentConfig, NgcCookieConsentService} from 'ngx-cookieconsent';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NavigationComponent } from './navigation/navigation.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { HomeTestComponent } from './home-test/home-test.component';
import { CultureComponent } from './culture/culture.component';
import { ManifestationsComponent } from './manifestations/manifestations.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { FooterComponent } from './footer/footer.component';
import { SliderComponent } from './shared/slider/slider.component';
import { Slider2Component } from './shared/slider2/slider2.component';
import { SliderItemComponent } from './shared/slider/slider-item/slider-item.component'; 
import { Slider2ItemComponent } from './shared/slider2/slider2-item/slider2-item.component'; 

import { ContactComponent } from './contact/contact.component';
import { FilterCategoriesComponent } from './shared/components/filter-categories/filter-categories.component';
import { FilterBrandsComponent } from './shared/components/filter-brands/filter-brands.component';
import { ArticleService } from './shared/services/article.service';
import { ModalsService } from './shared/services/modals.service';
import { MapboxService } from './shared/services/mapbox.service';
import { ShoppingCartService } from './shared/services/shopping-cart.service';
import { ArticleComponent } from './article/article.component';
import { ArticleResolverService } from './shared/services/resolvers/article-resolver.service';
import { ArticleCategoryResolverService } from './shared/services/resolvers/article-category-resolver.service';
import { ArticlePreviewComponent } from './article/article-preview/article-preview.component';
import { NewsSectionComponent } from './home/sections/news-section/news-section.component';
import { ZtkMapSectionComponent } from './home/sections/ztk-map-section/ztk-map-section.component';
import { AboutComponent } from './about-us/about/about.component';
import { OrderService } from './shared/services/order.service';
import { VisitorMailService } from './shared/services/visitor-mail.service';
import { LangService } from './shared/services/lang.service';
import { AuthService } from './shared/services/auth.service';
import { StorageService } from './shared/services/storage.service';
import { SignInComponent } from './modals/sign-in/sign-in.component';
import {  BloodSuppliesModalComponent } from './modals/blood-supplies-modal/blood-supplies-modal.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { DataService } from './shared/services/data.service';
import { SharedModule } from './shared/shared.module';
import { NotificationWindowService } from './shared/services/notification-window.service';
import { BlogComponent } from './blog/blog.component';
import { SubNavigationMenuComponent } from './navigation/sub-navigation-menu/sub-navigation-menu.component';
import { SiteOptionsComponent } from './navigation/site-options/site-options.component';
import { MockupService } from './shared/services/mockup.service';
import { LanguageResolverService } from './shared/services/resolvers/language-resolver.service';
import { LoremIpsumComponent } from './lorem-ipsum/lorem-ipsum.component';
import { PromoTwoComponent } from './home/promo-two/promo-two.component';
import { RotationProductsComponent } from './home/rotation-products/rotation-products.component';
import { RotationProductItemComponent } from './home/rotation-products/rotation-product-item/rotation-product-item.component';
import { AuthGuard } from './shared/services/guards/auth-guard.guard';
import { LocationsMapComponent } from './contact/locations-map/locations-map.component';
import { SiteUserInterceptor } from './shared/services/site-user-interceptor';
import { NewsPreviewItemComponent } from './home/sections/news-section/news-preview-item/news-preview-item.component';
import { HistoryPageComponent } from './pages/history-page/history-page.component';
import { AboutInstitutePageComponent } from './pages/about-institute-page/about-institute-page.component';
import { DocumentsPageComponent } from './pages/documents-page/documents-page.component';
import { NewsPageComponent } from './pages/news-page/news-page.component';
import { ManagementPageComponent } from './pages/management-page/management-page.component';
import { DirectorsBoardPageComponent } from './pages/directors-board-page/directors-board-page.component';
import { EthicsComiteePageComponent } from './pages/ethics-comitee-page/ethics-comitee-page.component';
import { MedicalComiteeComponent } from './pages/medical-comitee/medical-comitee.component';
import { ExtraWorkComponent } from './pages/extra-work/extra-work.component';
import { StructureAndActivityPageComponent } from './pages/structure-and-activity-page/structure-and-activity-page.component';
import { WhyDonateBloodPageComponent } from './pages/why-donate-blood-page/why-donate-blood-page.component';
import { WhereAndWhenToDonatePageComponent } from './pages/where-and-when-to-donate-page/where-and-when-to-donate-page.component';
import { QuestionnairePageComponent } from './pages/questionnaire-page/questionnaire-page.component';
import { GuideForDonorsPageComponent } from './pages/guide-for-donors-page/guide-for-donors-page.component';
import { AboutBloodPageComponent } from './pages/about-blood-page/about-blood-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { Config } from '../Config';
import { QualityPolicyPageComponent } from './pages/quality-policy-page/quality-policy-page.component';
import { PromoMaterialComponent } from './pages/promo-material/promo-material.component';
import { DarujkrvMeComponent } from './pages/darujkrv.me/darujkrv.me.component';
import { ContactRegionComponent } from './home-test/contact-region/contact-region.component';

// const cookieConfig:NgcCookieConsentConfig = {
//   cookie: {
//     domain: 'webshop.samms.me' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
//   },
//   palette: {
//     popup: {
//       background: '#000'
//     },
//     button: {
//       background: '#B42024'
//     }
//   },
//   theme: 'edgeless',
//   type: 'info'
// };

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    NavigationComponent,
    SubNavigationMenuComponent,
    SiteOptionsComponent,
    HeaderComponent,
    HomeComponent,
    HomeTestComponent,
    AboutUsComponent,
    FooterComponent,
    LocationsMapComponent,
    SliderComponent,
    Slider2Component,
    SliderItemComponent,
    Slider2ItemComponent,
    ContactComponent,
    NewsSectionComponent,
    ZtkMapSectionComponent,
    ArticleComponent,
    NewsPreviewItemComponent,
    ArticlePreviewComponent,
    FilterBrandsComponent,
    FilterCategoriesComponent,
    AboutComponent,
    SignInComponent,
    BloodSuppliesModalComponent,
    BlogComponent,
    LoremIpsumComponent,
    PromoTwoComponent,
    RotationProductsComponent,
    RotationProductItemComponent,
    CultureComponent,
    ManifestationsComponent,
    HistoryPageComponent,
    AboutInstitutePageComponent,
    DocumentsPageComponent,
    NewsPageComponent,
    ManagementPageComponent,
    DirectorsBoardPageComponent,
    EthicsComiteePageComponent,
    MedicalComiteeComponent,
    ExtraWorkComponent,
    StructureAndActivityPageComponent,
    WhyDonateBloodPageComponent,
    WhereAndWhenToDonatePageComponent,
    QuestionnairePageComponent,
    GuideForDonorsPageComponent,
    AboutBloodPageComponent,
    FaqPageComponent,
    QualityPolicyPageComponent,
    PromoMaterialComponent,
    DarujkrvMeComponent,
    ContactRegionComponent
  ],
  entryComponents: [SignInComponent, BloodSuppliesModalComponent ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ztk' }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  MarkdownModule.forRoot({
    loader: HttpClient,
    markedOptions: {
      provide: MarkedOptions,
      useFactory: markedOptionsFactory,
    },
  }),
    // NgcCookieConsentModule.forRoot(cookieConfig),
    InfiniteScrollModule,
    // NgxImageZoomModule,
    SharedModule,
    /*TranslateModule.forRoot({
       loader: {
         provide: TranslateLoader,
         useFactory: HttpLoaderFactory,
         deps: [HttpClient]
        }
      }),
    */
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })
  ],
  providers: [
    LangService,
    ArticleService,
    ArticleResolverService,
    ArticleCategoryResolverService,
    LanguageResolverService,
    // NgcCookieConsentService,
    DataService,
    MapboxService,
    AuthGuard,
    VisitorMailService,
    AuthService,
    OrderService,
    StorageService,
    ShoppingCartService,
    ModalsService,
    NotificationWindowService,
    MockupService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SiteUserInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();
  const config = new Config();

  renderer.image = (text: string) => {
    return '<div class="f-r justify-content-center"><img class="md-image mv-12" src="' + config.fileUrl + text + '" alt="ZTKCG"></div>';
  };

  return {
    renderer: renderer
  };
}