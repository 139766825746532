import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from '../../shared/services/lang.service';

@Component({
  selector: 'app-management-page',
  templateUrl: './management-page.component.html',
  styleUrls: ['./management-page.component.css']
})
export class ManagementPageComponent implements OnInit {

  constructor(private translate: TranslateService,
              private langService: LangService) { }

    ngOnInit() {
      this.langService.languageChanged.subscribe((lang) => {
        this.translate.currentLang = lang;
      })
  }
}
