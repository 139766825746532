import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalsService } from '../../shared/services/modals.service';
import { DataService } from '../../shared/services/data.service';


@Component({
  selector: 'app-blood-supplies-modal',
  templateUrl: './blood-supplies-modal.component.html',
  styleUrls: ['./blood-supplies-modal.component.css']
})
export class BloodSuppliesModalComponent implements OnInit {
  @ViewChild('bloodSupplyModal', { static: true }) bloodSupplyModal: ElementRef;
  @ViewChild('modalTriggerBloodSupply', { static: true }) modalTriggerBloodSupply: ElementRef;
  bloodGroups: Array<{name: string, level: number}> = [];
  lastUpdate: Date;

  constructor(private modalService: ModalsService,
              private dataService: DataService) { }

  ngOnInit() {
    this.modalService.modalBloodSupplies.subscribe((ev) => {
      if (ev) {
        this.modalTriggerBloodSupply.nativeElement.click();
      } else {
        this.bloodSupplyModal.nativeElement.click();
      }
    });

    this.loadBloodGroups();
  }

//   72	89
// 22	20
// 26	88
// 88	29

loadBloodGroups() {

  this.dataService.getDataOutside('https://api.darujkrv.me/zalihe/active').subscribe((resp) => {
    //  console.log(resp);
    const supply = resp;
    this.bloodGroups = [
      {name: 'A+', level: supply.a_plus},
      {name: 'A-', level: supply.a_minus},
      {name: 'B+', level: supply.b_plus},
      {name: 'B-', level: supply.b_minus},
      {name: 'AB+', level: supply.ab_plus},
      {name: 'AB-', level: supply.ab_minus},
      {name: '0+', level: supply.ab_plus},
      {name: '0-', level: supply.ab_minus},
    ];
    this.lastUpdate = new Date(supply.datum);
    }, (err) => {
      //  console.log(err);
    });

  }

}
