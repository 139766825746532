import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from '../shared/services/lang.service';
import { AuthService } from '../shared/services/auth.service';
import { ModalsService } from '../shared/services/modals.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ScrollingService } from '../shared/services/scrolling.service';
import { StorageService } from '../shared/services/storage.service';
import { DataService } from '../shared/services/data.service';
import { Brand } from '../shared/models/brand.model';
import { DOCUMENT } from '@angular/common';
import { MenuOption } from '../shared/models/menu-option.model';
import { Config } from '../../Config';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  config = new Config();
  lang: string;
  userLoggedIn = false;
  topVisible = true;
  scrolledUp = false;
  subMenuStatus = 0;
  subMenuMouseLeft = false;
  activeCategory: number;
  optionSelected: MenuOption;
  menuOptions: Array<MenuOption> = [
    {nameMe: 'Novosti', nameEn: 'News', link: '/' + this.translate.currentLang + '/novosti', colorCode: '#CC444B'},
    {nameMe: 'O nama', nameEn: 'About us', link: '/' + this.translate.currentLang + '', colorCode: '#F05545', subOptions: [
      {nameMe: 'Istorijat', nameEn: 'History', link: '/' + this.translate.currentLang + '/istorijat', colorCode: '#fff' },
      {nameMe: 'O Zavodu', nameEn: 'About the Institute', link: '/' + this.translate.currentLang + '/o-zavodu', colorCode: '#fff' },
      // tslint:disable-next-line: max-line-length
      {nameMe: 'Statut Zavoda', nameEn: 'Statute of the Institute', link: this.config.baseUrl + 'uploads/Statut_14110a5ca3.pdf', colorCode: '#fff', target: "_blank" },
      {nameMe: 'Menadžment', nameEn: 'Management', link: '/' + this.translate.currentLang + '/clanak/7/menadzment', colorCode: '#fff' },
      {nameMe: 'Odbor direktora', nameEn: 'Board of directors', link: '/' + this.translate.currentLang + '/board-of-directors', colorCode: '#fff'},
      {nameMe: 'Etički komitet', nameEn: 'Ethics comitee', link: '/' + this.translate.currentLang + '/ethics-comitee', colorCode: '#fff'},
      {nameMe: 'Medicinski odbor', nameEn: 'Medical comitee', link: '/' + this.translate.currentLang + '/medical-comitee', colorCode: '#fff'},
      {nameMe: 'Dopunski rad', nameEn: 'Additional work', link: '/' + this.translate.currentLang + '/dopunski-rad', colorCode: '#fff'},

      {nameMe: 'Struktura i djelatnost', nameEn: 'Organisation and activity',
       link: '/' + this.translate.currentLang + '/struktura-i-djelatnost', colorCode: '#fff' }
    ]},
    {nameMe: 'Davalaštvo', nameEn: 'Blood donation', colorCode: '#523335', subOptions: [
      {nameMe: 'Vodič za davaoce krvi', nameEn: 'Guide for blood donors', colorCode: '#fff',
       link: '/' + this.translate.currentLang + '/vodic-za-davaoce'},
      {nameMe: 'Zašto davati krv?', nameEn: 'Why donate blood?', colorCode: '#fff',
       link: '/' + this.translate.currentLang + '/zasto-davati-krv'},
      {nameMe: 'Gdje i kada darivati', nameEn: 'When and where you can donate', colorCode: '#fff', 
      link: '/' + this.translate.currentLang + '/lokacije'},
      {nameMe: 'Upitnik za davaoce', nameEn: 'Questionaire for donors',colorCode: '#fff',
        link: '/' + this.translate.currentLang + '/upitnik'},
      {nameMe: 'O krvi', nameEn: 'About blood', colorCode: '#fff', link: '/' + this.translate.currentLang + '/o-krvi'},
      {nameMe: 'Česta pitanja', nameEn: 'FAQ', colorCode: '#fff', link: '/' + this.translate.currentLang + '/faq'},
      {nameMe: 'Promo materijal', nameEn: 'Promo material', colorCode: '#fff', link: '/' + this.translate.currentLang + '/promo-materijal'},

    ]},
    {nameMe: 'Dokumenti', nameEn: 'Documents', link: '/' + this.translate.currentLang + '/dokumenti', colorCode: '#0B6E4F'},
    {nameMe: 'Kontakt', nameEn: 'Contact', link: '/' + this.translate.currentLang + '/contact', colorCode: '#fff'},
    {nameMe: 'Politika organizacije', nameEn: 'Organisation policy',
     link: '/' + this.translate.currentLang + '/politika-organizacije', colorCode: '#fff'}
  ];
  mouseLeftKajron = false;
  screenHeight: number;
  screenWidth: number;
  @ViewChild('menuToggleButton', {static: true}) menuToggleButton: ElementRef;
  isMenuMobileCollapsed = false;
  areWeOnHomePage = false;

  constructor(private translate: TranslateService,
              private langService: LangService,
              private dataService: DataService,
              private auth: AuthService,
              private router: Router,
              private modals: ModalsService,
              private storage: StorageService,
              private scrollingService: ScrollingService,
              private route: ActivatedRoute,
              @Inject(DOCUMENT) private document: Document) { }
  show = true;

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.setAreWeOnHome();
    this.router.events.subscribe((routerEvent: NavigationEnd) => {
      if(routerEvent instanceof NavigationEnd) {
        this.setAreWeOnHome();
      }
    });

    if (this.route.snapshot.data.language == null || this.route.snapshot.data.language === undefined ) {
      this.lang = 'me';
    }

    this.menuOptions[0].nameMe.replace(' ', '_').toLowerCase()

    this.langService.languageChanged.subscribe((lang) => {
      this.lang = lang;
      this.translate.currentLang = lang;
      this.menuOptions.forEach((option) => {
        // Promijeni /me/ u /en/,
        // Promijeni /en/ u /me/... Zavisnosti od promjene... I to i za sve subopcije
        let toReplace: string;
        let replacement: string;
        if (lang === 'me') {
          toReplace = '/en/';
          replacement = '/me/';
        } else {
          toReplace = '/me/';
          replacement = '/en/';
        }

        if(option.link != null && option.link !== undefined) {
          option.link = option.link.replace(toReplace, replacement);
        }


        if(option.subOptions != null && option.subOptions !== undefined) {
          option.subOptions.forEach((sub) => {
            sub.link = sub?.link?.replace(toReplace, replacement);
          });
        }

      });
      //  console.log('Ima li ovdje kogaaaaaa');
    });

    this.screenHeight = this.document.documentElement.clientHeight;
    this.screenWidth = this.document.documentElement.clientWidth;    


    this.auth._loggedIn.subscribe((loggedIn) => {
      this.userLoggedIn = loggedIn;
    });

    if(this.screenWidth > 991) {
      this.scrollingService.scrolledFromTop.subscribe((ev) => {
        if(ev) {
          this.mouseLeftKajron = true;
          this.topVisible = false;
          this.hideSubMenuIfApplicable();
        }
      });
  
      this.scrollingService.returnedToTop.subscribe((ev) => {
        if(ev) {
          this.topVisible = true;
        }
      });
  
      this.scrollingService.upwardScroll.subscribe((isUpScroll) => {
        if(isUpScroll) {
          this.scrolledUp = true;
        } else {
          this.scrolledUp = false;
        }
      });
    }

    
  }

  setAreWeOnHome() {
    if(this.router.url.indexOf('home') > -1) {
      this.areWeOnHomePage = true;
    } else {
      this.areWeOnHomePage = false;
    }
  }


  changeLanguage(lang: string) {
    this.langService.languageChanged.emit(lang);
    // this.translate.use(lang);
    this.translate.currentLang = lang;
    this.lang = lang;
  }

  IfMe() {
    if (this.lang === "me") { return true; }
  }

  goToLogin() {
    this.isMenuMobileCollapsed = false;
    this.modals.modalSignIn.emit(true);
  }

  goToRegister() {
    this.isMenuMobileCollapsed = false;
    this.modals.modalRegister.emit(true);
  }


  navigateMobile(option: MenuOption) {
    if(option.subOptions == null || option.subOptions === undefined) {
      this.router.navigate([option.link]);
    }
  }



  hideSubMenuIfApplicable() {
      this.subMenuStatus = 0;
      this.subMenuMouseLeft = true;

  }

  goToLink(link: string) {
    this.menuToggleButton.nativeElement.click();
    this.router.navigate([link]);
  }

  setOptionHovered(option: MenuOption) {
    option.isHovered = true;
    this.optionSelected = option;
    this.storage.selectedOption.emit(option);
    if(option.subOptions != null) {
      this.subMenuStatus = 1;
    } else {
      this.subMenuStatus = 0;
    }
  }

  unhoverOption(option: MenuOption) {
    option.isHovered = false;
    this.optionSelected = null;
    //  console.log('subMenuStatus: ' + this.subMenuStatus + ' | ' + ' optionSelected: ' + this.optionSelected);
  }

  onKajronLeft(ev: MouseEvent) {
    if(ev.clientY < 242) {
      this.mouseLeftKajron = true;
    }
  }
  
}
