import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '../../../Config';
import { LangService } from '../../shared/services/lang.service';
@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.css']
})
export class FaqPageComponent implements OnInit {
  currentLang: string;
 
  constructor(private langService: LangService,
              private translate: TranslateService) { 

  }

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
    this.langService.languageChanged.subscribe((lang) => {
      this.translate.currentLang = lang;
      this.currentLang = lang;
    });

  }
}
