<div class="article-component-container pager">
  <div class="article-header mv-24">
      <h1 class="article-title text-center">
         {{  'structureAndActivity' | translate }}
      </h1>
  </div>

  <div class="article-content">

      <div *ngIf="currentLang === 'me'" class="article-text me">
        <p>Zavod je zdravstvena ustanova čija djelatnost obuhvata: prikupljanje, testiranje, preradu, čuvanje, distribuciju, izdavanje, kontrolu kvaliteta i upotrebu krvi i komponenata krvi, koje se vrše u skladu sa dobrom proizvođačkom praksom, Direktivama Evropske Unije i preporukama SZO, kao i drugim propisima u ovoj oblasti.</p>
        <p>Zavod svoju djelatnost ostvaruje i preko organizacionih djelova u zdravstvenim ustanovama koje u svojoj djelatnosti koriste krv u liječenju, u Baru, Kotoru, Risnu, Nikšiću, Cetinju, Bijelom Polju, Beranama i Pljevljima, u skladu sa Zakonom i aktima Zavoda.<br>
        Organizacija i sistematizacija poslova u Zavodu uređuje se u skladu sa djelatnošću, potrebama poslovanja Zavoda i zahtjevima organizacije rada.</p>
        <p>Radi obavljanja djelatnosti Zavoda u skladu sa Zakonom i Statutom, organizuju se sljedeće organizacione cjeline:</p>
        <ul>
        <li style="list-style-type: none;">
        <ul>
        <li style="list-style-type: none;">
        <ol type="1">
        <li>Služba za upravljanje kvalitetom
        <ul>
        <li>Odjeljenje za osiguranje i kontolu kvaliteta</li>
        <li>Odjeljenje za kontrolu dokumenata i hemovigilancu</li>
        </ul>
        </li>
        <li>Služba za kolekciju krvi
        <ul>
        <li>Odjeljenje za kolekciju krvi</li>
        <li>Odjeljenje za donorske afereyne postupke</li>
        <li>Odsjek za DDK</li>
        </ul>
        </li>
        <li>Služba za testiranje davalaca krvi,&nbsp;pripremu komponenata krvi i distribuciju
        <ul>
        <li>Odjeljenje za testiranje jedinica krvi</li>
        <li>Odjeljenje za pripremu komponenata krvi i distribuciju</li>
        </ul>
        </li>
        <li>Služba za laboratorijsku i drugu dijagnostiku</li>
        <li>Služba za kliničku i urgentnu transfuziju
        <ul>
        <li>Odjeljenje za kliničku i urgentnu transfuziju u Podgorici;</li>
        <li>Odjeljenje za kolekciju, laboratorijsku, kliničku i urgentnu transfuziju u Nikšiću;</li>
        <li>Odjeljenje za kolekciju, laboratorijsku, kliničku i urgentnu transfuziju u Baru;</li>
        <li>Odjeljenje za kolekciju, laboratorijsku, kliničku i urgentnu transfuziju u Beranama;</li>
        <li>Odjeljenje za kolekciju, laboratorijsku, kliničku i urgentnu transfuziju u Bijelom Polju;</li>
        <li>Odjeljenje za kolekciju, laboratorijsku, kliničku i urgentnu transfuziju u Pljevljima;</li>
        <li>Odjeljenje za kolekciju, laboratorijsku, kliničku i urgentnu transfuziju u Kotoru;</li>
        <li>Odjeljenje za kolekciju, laboratorijsku, kliničku i urgentnu transfuziju u Cetinju;</li>
        <li>Odjeljenje za kolekciju, laboratorijsku, kliničku i urgentnu transfuziju u Risnu.</li>
        </ul>
        </li>
        <li>Administrativni i opšti poslovi i IT podršku
        <ul>
        <li>Menadžment</li>
        <li>Služba za finansije, računovodstvo i nabavke</li>
        <li>Služba za pravne, kadrovske i opšte poslove</li>
        <li>Služba za tehničke poslove i IT podršku</li>
        </ul>
        </li>
        </ol>
        </li>
        </ul>
        </li>
        </ul>
      </div>

      <div *ngIf="currentLang === 'en'" class="article-text en">
        <p>The Institute is a health institution whose activities include: collection, testing, processing, storage, distribution, issuance, quality control and use of blood and blood components, which are performed in accordance with good manufacturing practice, European Union Directives and WHO recommendations, as well as other regulations in this area.</p>
        <p>The Institute also carries out its activities through organizational units in health institutions that use blood in their activities, in Bar, Kotor, Risan, Nikšić, Cetinje, Bijelo Polje, Berane and Pljevlja, in accordance with the Law and acts of the Institute. <br>
        The organization and systematization of work in the Institute is regulated in accordance with the activity, the business needs of the Institute and the requirements of the organization of work.</p>
        <p>In order to perform the activities of the Institute in accordance with the Law and the Statute, the following organizational units are organized:</p>
        <ul>
        <li style="list-style-type: none;">
        <ul>
        <li style="list-style-type: none;">
        <ol type="1">
        <li>Quality Management Service
        <ul>
        <li>Quality Assurance and Control Department</li>
        <li>Document Control and Chemovigilance Department</li>
        </ul>
        </li>
        <li>Blood Collection Service
        <ul>
        <li>Blood Collection Department</li>
        <li>Department of Donor Affair Procedures</li>
        <li>DDK Department</li>
        </ul>
        </li>
        <li>Blood donor testing, blood component preparation and distribution service
        <ul>
        <li>Blood Units Testing Department</li>
        <li>Blood Components Preparation and Distribution Department</li>
        </ul>
        </li>
        <li>Laboratory and other diagnostics service</li>
        <li>Clinical and Emergency Transfusion Service
        <ul>
        <li>Department of Clinical and Emergency Transfusion in Podgorica;</li>
        <li>Department for collection, laboratory, clinical and urgent transfusion in Nikšić;</li>
        <li>Department for collection, laboratory, clinical and urgent transfusion in Bar;</li>
        <li>Department for collection, laboratory, clinical and urgent transfusion in Berane;</li>
        <li>Department for collection, laboratory, clinical and urgent transfusion in Bijelo Polje;</li>
        <li>Department for collection, laboratory, clinical and emergency transfusion in Pljevlja;</li>
        <li>Department of Collection, Laboratory, Clinical and Urgent Transfusion in Kotor;</li>
        <li>Department for collection, laboratory, clinical and emergency transfusion in Cetinje;</li>
        <li>Department for collection, laboratory, clinical and emergency transfusion in Risan.</li>
        </ul>
        </li>
        <li>Administrative and general affairs and IT support
        <ul>
        <li>Management</li>
        <li>Finance, Accounting and Procurement Service</li>
        <li>Legal, Personnel and General Affairs Service</li>
        <li>Technical Affairs and IT Support Service</li>
        </ul>
        </li>
        </ol>
        </li>
        </ul>
        </li>
        </ul>
      </div>
  </div>

  <div class="p-2 mv-24"></div>
  
</div>

