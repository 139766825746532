<div class="filters">
    <div *ngFor="let cat of categories; let c = index" class="filter-category-item f-c {{ cat.opened ? 'mxBig' : 'mxNormal' }} ">
      <div class="item-title w-100 f-r" (click)="toggleSubFilters(c)">
        <h3 class="category-title rem-1-15"> {{ currentLang === 'me' ? cat.name_me : cat.name_en }} </h3>
        <div *ngIf="cat.subcategories != null && cat.subcategories !== undefined" class="arrow-icon">
          <span class="fa fa-chevron-down {{ cat.opened ? 'rotated' : '' }}"></span>
        </div>
      </div>

      <ul *ngIf="(cat.subcategories != null && cat.subcategories !== undefined) && cat.opened" class="subcategories animated faster fadeIn">
        <li *ngFor="let sub of cat.subcategories">
          <input type="radio" name="category" [value]="sub.id" id="c + {{ sub.id }}" [(ngModel)]="categorySelectedId" 
          (ngModelChange)="categorySelected(sub)">
          <label for="c + {{ sub.id }} " (click)="categorySelected(sub)" 
          class="rem-0-95 {{ sub.id === categorySelectedId ? 'bold' : '' }}"> {{ currentLang === 'me' ? sub.name_me : sub.name_en }} </label>
        </li>
      </ul>
    </div>
</div>

<!-- <ul class="category-list">
  <li *ngFor="let cat of categories">
    <input type="radio" name="category" [value]="cat.id" id="c + {{ cat.id }}" [(ngModel)]="categorySelectedId" 
    (ngModelChange)="categorySelected($event)">
    <label for="c + {{ cat.id }} "> {{ currentLang === 'me' ? cat.nameMe : cat.nameEn }} </label>
  </li>
</ul> -->