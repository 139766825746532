import { Component, OnInit, Inject } from '@angular/core';
import { SiteArticle } from '../../../shared/models/site-article.model';
import { DataService } from '../../../shared/services/data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from '../../../shared/services/lang.service';


@Component({
  selector: 'app-news-section',
  templateUrl: './news-section.component.html',
  styleUrls: ['./news-section.component.css']
})
export class NewsSectionComponent implements OnInit {
  articles: Array<SiteArticle>;
  currentPage = 1;
  numberOfGroups = 0;
  articleGroups: Array<any> = [];
  screenWidth = 0;


  constructor(private dataService: DataService,
              private domSanitizer: DomSanitizer,
              public translate: TranslateService,
              private langService: LangService,
              @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.loadArticles();
    this.screenWidth = this.document.documentElement.clientWidth;
    this.langService.languageChanged.subscribe((lang) => {
      this.articles.forEach((art) => {
        art.showAppropriateLangData(lang);
      });
    });
  }

  loadArticles() {
    this.articles = [];
    /* ovo me dolje ne utiče na prevod */
    this.dataService.getDataFromUrl('posts?status=1&_start=0&_limit=20&_sort=created_at:DESC').subscribe(
      (rez: any) => {
        // tslint:disable-next-line:prefer-for-of
        //  console.log(rez);
        if (rez instanceof Array && rez.length > 0) {
          const articles = [];
          for (const element of rez) {
            const art = new SiteArticle(element);
            // art.videoUrl = this.getYouTubeVideoUrl(art.videoUrl);
            art.showAppropriateLangData(this.translate.currentLang);
            articles.push(art);
          }
          this.articles = articles;
          this.splitArticlesIntoGroups();

        } else {
          // //  console.log('Dogodila se greska');
        }
      });
  }

  splitArticlesIntoGroups() {
    // tslint:disable-next-line: max-line-length
    this.numberOfGroups = this.articles.length / 4 > Math.round(this.articles.length / 4) ? 
    Math.round(this.articles.length / 4) + 1 : Math.round(this.articles.length / 4);
    // //  console.log('Broj grupa: ' +  this.numberOfGroups);
    let groupIndex = 0;
    let singleIndex = 0;
    while (groupIndex < this.numberOfGroups) {
      const arr = [];
      for (let n = 0; n < 4; n++) {
        arr.push(this.articles[singleIndex]);
        singleIndex++;
      }

      this.articleGroups.push(arr);
      groupIndex++;
    }

    //  console.log(this.articleGroups);

  }

  getYouTubeVideoUrl(videoUrl: string) {
    let url: string;
    // console.log(videoUrl)
    if(videoUrl != null) {
      if (videoUrl.indexOf('&') === -1) {
        url = 'https://www.youtube.com/embed/' + videoUrl.slice(videoUrl.indexOf('?v=') + 3);
      } else {
        url = 'https://www.youtube.com/embed/' + videoUrl.slice(videoUrl.indexOf('?v=') + 3, videoUrl.indexOf('&'));
      }
      return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      return null;
    }
  }

}
