import { Component, OnInit } from '@angular/core';
import { SliderItem } from '../models/slider-item.model';
import { TranslateService } from '@ngx-translate/core';
import { Article } from '../models/article.model';
import { LangService } from '../services/lang.service';
import { Observable } from 'rxjs';
import { SiteArticle } from '../../shared/models/site-article.model';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-slider2',
  templateUrl: './slider2.component.html',
  styleUrls: ['./slider2.component.css']
})
export class Slider2Component implements OnInit {


  public slides: SiteArticle[] = [];

  constructor(private translate: TranslateService,
              private langService: LangService,
              private dataService: DataService,
              private domSanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.loadSlides(this.translate.currentLang);
    this.langService.languageChanged.subscribe((lang: string) => {
      this.slides.forEach((sl) => {
        sl.showAppropriateLangData(lang);
      })
    });
  }

  loadSlides(lang: string) {
    this.slides = [];
    /* ovo me dolje ne utiče na prevod */
    this.dataService.getDataFromUrl('posts?position.id=2&_start=0&_limit=3&_sort=updated_at:DESC').subscribe(
      (rez: any) => {
        // tslint:disable-next-line:prefer-for-of
        //  console.log(rez);
          if (rez instanceof Array && rez.length > 0) {
            const articles = [];
            for (const element of rez) {
              const art = new SiteArticle(element);
              art.videoUrl = this.getYouTubeVideoUrl(art.videoUrl);
              articles.push(art);
            }
            this.slides = articles;
          } else {
            // //  console.log('Dogodila se greska');
          }
        });
  }

  getYouTubeVideoUrl(videoUrl: string) {
    let url: string;
    if(videoUrl != null) {
      if (videoUrl.indexOf('&') === -1) {
        url = 'https://www.youtube.com/embed/' + videoUrl.slice(videoUrl.indexOf('?v=') + 3);
      } else {
        url = 'https://www.youtube.com/embed/' + videoUrl.slice(videoUrl.indexOf('?v=') + 3, videoUrl.indexOf('&'));
      }
      return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      return null;
    }
  }
}
