<div class="article-component-container pager">
  <div class="article-header mv-24">
      <h1 class="article-title text-center">
         {{  'whyDonateBlood' | translate }}
      </h1>
  </div>

  <div class="article-content">

      <div *ngIf="currentLang === 'me'" class="article-text me">
        <p>Krv je jedini lijek koji nema zamjenu i ne može se dobiti vještačkim putem te su davaoci krvi jedini izvor za dobijanje ovog lijeka. Tvoja krv je neophodna da bi nečije zdravstveno stanje bilo bolje.</p>
        <p>Osnovni moto je da krv mora da čeka bolesnika, a ne bolesnik krv.</p>
        <p>Osim toga, postoje i fiziološki benefiti za davaoce – samo davanje blago stimuliše koštanu srž da stvara nove krvne ćelije. Takođe se poboljšava cirkulacija, ali ono što je davaocima ipak najdraže, jeste osjećaj da su učinili nesebično djelo i pomogli nekom kome je ta krv bila životno neophodna.</p>
        <p>Nažalost, svakom četvrtom građaninu je neophodna transfuzija krvi tokom života, dok tek svaki četrdeseti da krv jednom godišnje.</p>
        <p>Davanje krvi je privilegija zdravih ljudi!</p>
        <p>Davanjem krvi obezbjeđujemo sigurniju budućnost za nas, naše bližnje i pokazujemo odgovoran odnos prema zajednici.</p>
      </div>

      <div *ngIf="currentLang === 'en'" class="article-text en">
        <p>Blood is the only drug that has no substitute and cannot be obtained artificially, and blood donors are the only source for obtaining this drug. Your blood is necessary for someone's health to be better.</p>
        <p>The basic motto is that blood must wait for the patient, not the patient's blood.</p>
        <p>In addition, there are physiological benefits for donors - just giving gently stimulates the bone marrow to create new blood cells. Circulation also improves, but what is most dear to the donors is the feeling that they have done a selfless deed and helped someone who needed that blood vitally.</p>
        <p>Unfortunately, every fourth citizen needs a blood transfusion during his life, while only every fortieth person needs blood once a year.</p>
        <p>Giving blood is the privilege of healthy people!</p>
        <p>By donating blood, we provide a safer future for us, our loved ones, and show a responsible attitude towards the community.</p>
      </div>
  </div>

  <div class="p-2 mv-24"></div>
  
</div>

