<div class="pager f-c align-items-center justify-content-center mb-128">
  <div class="col-xs-12 col-sm-12 col-md-10 col-lg-9 col-xl-9 p-t-4">

    <h3 class="rem-1-25 bold mb-4">Rule #1</h3>
  <p class="mb-5">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Est placerat in egestas erat imperdiet sed. Praesent semper feugiat nibh sed pulvinar proin gravida hendrerit. Quis lectus nulla at volutpat diam ut venenatis tellus. Potenti nullam ac tortor vitae. Scelerisque viverra mauris in aliquam. Penatibus et magnis dis parturient montes. Sed ullamcorper morbi tincidunt ornare massa. Sed id semper risus in hendrerit. Aenean pharetra magna ac placerat vestibulum lectus mauris ultrices eros. Turpis nunc eget lorem dolor sed viverra ipsum nunc.
  </p>
  
  <h3 class="rem-1-25 bold mb-4">Rule #2</h3>
  <p class="mb-5">
    Commodo sed egestas egestas fringilla phasellus faucibus scelerisque eleifend donec. Fermentum iaculis eu non diam phasellus vestibulum lorem sed. Curabitur vitae nunc sed velit. Diam phasellus vestibulum lorem sed risus ultricies tristique nulla aliquet. Eget nunc scelerisque viverra mauris. Viverra suspendisse potenti nullam ac tortor vitae. Orci sagittis eu volutpat odio facilisis. Fames ac turpis egestas integer. Ullamcorper velit sed ullamcorper morbi tincidunt ornare massa eget. Ac placerat vestibulum lectus mauris ultrices eros in. Arcu dictum varius duis at consectetur lorem donec massa. Suspendisse faucibus interdum posuere lorem ipsum. Tempus imperdiet nulla malesuada pellentesque elit. Tincidunt ornare massa eget egestas purus viverra. Risus quis varius quam quisque id diam. Viverra aliquet eget sit amet tellus cras.
  </p>
  
  <h3 class="rem-1-25 bold mb-4">Rule #3</h3>
  <p class="mb-5">
    Risus nec feugiat in fermentum posuere urna nec tincidunt. Neque volutpat ac tincidunt vitae semper. Volutpat ac tincidunt vitae semper. Neque laoreet suspendisse interdum consectetur libero. Phasellus faucibus scelerisque eleifend donec. Risus nec feugiat in fermentum. Lectus proin nibh nisl condimentum id venenatis a condimentum. Natoque penatibus et magnis dis. Volutpat commodo sed egestas egestas. Massa ultricies mi quis hendrerit dolor magna. Mattis vulputate enim nulla aliquet porttitor lacus.
  </p>
  
  <h3 class="rem-1-25 bold mb-4">Rule #4</h3>
  <p class="mb-5">
    Vitae ultricies leo integer malesuada nunc vel risus commodo viverra. Est ultricies integer quis auctor elit sed. Nunc congue nisi vitae suscipit. Platea dictumst vestibulum rhoncus est pellentesque. Pharetra pharetra massa massa ultricies mi. Felis imperdiet proin fermentum leo vel orci porta non pulvinar. Dictumst vestibulum rhoncus est pellentesque elit ullamcorper. Arcu non sodales neque sodales ut. Fames ac turpis egestas integer eget aliquet nibh praesent. Et pharetra pharetra massa massa ultricies mi.
  </p>
  
  <h3 class="rem-1-25 bold mb-4">Rule #5</h3>
  <p class="mb-0">
    Magnis dis parturient montes nascetur ridiculus mus mauris. Quis varius quam quisque id. Fames ac turpis egestas sed tempus. Urna duis convallis convallis tellus id interdum. Et molestie ac feugiat sed lectus vestibulum. Et ultrices neque ornare aenean euismod. Iaculis at erat pellentesque adipiscing. Bibendum ut tristique et egestas quis ipsum suspendisse. Adipiscing bibendum est ultricies integer quis auctor elit sed. Lorem ipsum dolor sit amet consectetur adipiscing elit pellentesque. Nulla facilisi nullam vehicula ipsum a arcu. Et netus et malesuada fames ac. Dictum at tempor commodo ullamcorper a lacus vestibulum sed arcu. Ornare quam viverra orci sagittis eu. Eget aliquet nibh praesent tristique magna sit amet. Tincidunt id aliquet risus feugiat in ante metus dictum.
  </p>
    
  </div>
</div>


