import { SiteProduct } from '../../shared/models/site-product.model';

export class CartItem {
  public id: number;
  public product: SiteProduct;
  public size: string;
  public quantity: number;
  public priceAtTime: number;
  public totalAtTime: number;

  constructor(obj: {id: number, product: SiteProduct, size: string, quantity: number, price_at_time: number, total_at_time: number}) {
    this.id = obj.id;
    this.product = obj.product;
    this.size = obj.size;
    this.quantity = obj.quantity;
    this.priceAtTime = obj.price_at_time;
    this.totalAtTime = obj.total_at_time;
  }
}
