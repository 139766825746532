<div class="invisible-modal-trigger" #modalTriggerSign routerLinkActive="active" 
      data-toggle="modal" data-target="#signInModal">
</div>

<div class="modal fade" #signInModal id="signInModal" tabindex="-1" role="dialog" 
  aria-labelledby="signInModal" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form [formGroup]="signInForm"  (ngSubmit)="signIn(signInForm.value)">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="signInModalLabel">{{ 'signInTitle' | translate }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
                <div class="form-group">
                    <!-- <label for="signin_email" >{{ 'email' | translate }}</label> -->
                    <input type="text"  class="form-control"  formControlName="login" id="signin_email"
                    [placeholder]="'emailPlaceholder' | translate ">
                </div>
                <div class="form-group">
                    <!-- <label for="signin_password" >{{ 'password' | translate }}</label> -->
                    <input type="password"  class="form-control"  formControlName="password" id="signin_password"
                    [placeholder]="'password' | translate ">
                </div>
                <div *ngIf="errorExists" class="sign-in-error">
                  <div class="alert alert-danger">
                    {{ errorText }}
                  </div>
                </div>

                <div *ngIf="signInSuccess" class="sign-in-error">
                  <div class="alert alert-success">
                    {{ signInSuccessMessage | translate }}
                  </div>
                </div>
          </div>
          <div class="modal-footer">
            <div>
              <button class="btn btn-slider" type="submit">{{ 'sign-in' | translate }}</button>
            </div>

            <div class="reg">
              {{ 'noAccount' | translate }}<a data-dismiss="modal" (click)="goToRegister()" routerLink="/">{{ 'registerHere' | translate }} </a>
            </div>
          </div>
        </div>
      </form>
      </div>
    </div>