import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from '../../shared/services/lang.service';

@Component({
  selector: 'app-why-donate-blood-page',
  templateUrl: './why-donate-blood-page.component.html',
  styleUrls: ['./why-donate-blood-page.component.css']
})
export class WhyDonateBloodPageComponent implements OnInit {
  currentLang: string;

  constructor(private langService: LangService,
              private translate: TranslateService) { 

              }

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
    this.langService.languageChanged.subscribe((lang) => {
      this.currentLang = lang;
    });
  }
}
