import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Config } from '../../../Config';

@Injectable()
export class VisitorMailService {
  private config: Config;
  header: HttpHeaders;

  constructor(private http: HttpClient) {
    this.header = new HttpHeaders( {
      'Content-Type': 'application/json',
    });

    this.config = new Config();
  }

  public sendMailFromVisitor(contact: {
    fullName: string,
    email: string,
    phone: string,
    message: string
  }) {
    const url = this.config.baseUrl + 'send-mail-from-site';
    return this.http.post(url, contact, { headers : this.header});
  }
}
