<div class="article-component-container pager">
  <div class="article-header mv-24">
      <h1 class="article-title text-center">
         {{  'organisation-policy' | translate }}
      </h1>
  </div>

  <div class="article-content">

      <div *ngIf="currentLang === 'me'" class="article-text me">
        <p>Komisija za kontrolu kvaliteta zdravstvene zaštite ( u daljem tekstu: Komisija za kontrolu kvaliteta) je stručno tijelo, koje se stara o provjeri i stalnom unaprjeđenju kvaliteta zdravstvene zaštite koja se sprovodi u Zavodu.</p>
        <p>Komisija za kontrolu kvaliteta vrši monitoring i evaluaciju, predlaže direktoru Zavoda mjere za unaprjeđenje kvaliteta rada u zdravstvenoj ustanovi, kontroliše sprovodjenje standardnih operativnih procedura, planira i sprovodi druge mjere utvrđene statutom zdravstvene ustanove.</p>
      
        <h3>Naša Politika kvaliteta je da:</h3>

        <ul class="circle">
          <li>Propagiramo dobrovoljno davalaštvo krvi kroz kontinuirano obavještavanje zajednice o važnosti davanja krvi
          <li>Radimo na dobrobiti čuvanja zdravlja I sigurnosti transfuzijskog liječenja bolesnika
          <li>Poslovni procesi budu usmjereni na sticanje povjerenja I zadovoljstvo svih zainteresovanih strana
          <li>Stalno poboljšavamo naš sistem upravljanja kvalitetom u skladu sa najvišim svjetskim standardima,
          <li>Potičemo istraživačku djelatnost u transfuzijskoj medicini.
          <li>Primjenjujemo rezultate najnovijih istraživanja radi postizanja veće sigurnosti davaoca I djelotvornosti proizvoda I usluga
        </ul>

        <h3>Svoju Politiku kvaliteta ostvarujemo:</h3>

        <ul class="circle">
          <li>Primjenom savremenih principa poslovanja i zahtjeva međunarodnog standarda ISO 9001,
          <li>Stalnim usavršavanjem našeg osoblja,
          <li>Uspostavljanjem otvorene i dijelotvorne komunikacije s korisnicima i saradnicima;
          <li>Primjenom savremene informatičke podrške i najsavremenijih resursa potrebnih za kvalitetno poslovanje.
        </ul>
      </div>

      <div *ngIf="currentLang === 'en'" class="article-text en">
        <p> The Commission for Quality Control of Health Care (hereinafter: the Commission for Quality Control) is an expert body that takes care of the inspection and continuous improvement of the quality of health care that is carried out in the Institute.
          <p> The Quality Control Commission monitors and evaluates, proposes to the Director of the Institute measures to improve the quality of work in the health institution, controls the implementation of standard operating procedures, plans and implements other measures determined by the statute of the health institution. </p>
        
          <h3> Our Quality Policy is to: </h3>
  
          <ul class = "circle">
            <li> We promote voluntary blood donation by continuously informing the community about the importance of blood donation
            <li> We work for the health and safety of transfusion treatment of patients
            <li> Business processes are focused on gaining the trust and satisfaction of all stakeholders
            <li> We are constantly improving our quality management system in accordance with the highest world standards,
            <li> We encourage research in transfusion medicine.
            <li> We apply the results of the latest research to achieve greater security of providers and effectiveness of products and services
          </ul>
  
          <h3> We implement our Quality Policy: </h3>
  
          <ul class = "circle">
            <li> By applying modern business principles and requirements of the international standard ISO 9001,
            <li> Continuous training of our staff,
            <li> Establishing open and effective communication with customers and associates;
            <li> By applying modern IT support and the most modern resources needed for quality business.
          </ul>
      </div>
  </div>

  <div class="p-2 mv-24"></div>
  
</div>

