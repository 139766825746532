import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SiteArticle } from '../../../shared/models/site-article.model';
import { SiteProduct } from '../../../shared/models/site-product.model';
import { LangService } from '../../../shared/services/lang.service';

@Component({
  selector: 'app-slider-item',
  templateUrl: './slider-item.component.html',
  styleUrls: ['./slider-item.component.css']
})
export class SliderItemComponent implements OnInit {
  @Input() slide: SiteArticle;

  constructor(public translate: TranslateService,
              private langService: LangService) { }

  ngOnInit() {
    //  console.log(this.slide);
    this.slide.showAppropriateLangData(this.translate.currentLang);

    this.langService.languageChanged.subscribe((lang) => {
      this.slide.showAppropriateLangData(lang);
    })
  }

}
