
<div class="img-loading-container f-c">
    <img class="{{ classnames }}" src="{{ imageSrc }}" alt="{{ 'Image from ' +  copyright }}"
    [hidden]="!imageLoaded">
    <img class="{{ classnames }}" src="{{ imageSrc }}" alt="{{ 'Image from ' +  copyright }}"
    [hidden]="true" (load)="loadingFinished($event)" (error)="errorWhileLoading()">
    <div class="image-loader {{ classnames }}" [hidden]="imageLoaded">
        <app-loading-spinner-circle></app-loading-spinner-circle>
    
    </div>
</div>
