import { Component, OnInit } from '@angular/core';
import { NotificationWindowService } from '../../services/notification-window.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'notification-box',
  templateUrl: './notification-box.component.html',
  styleUrls: ['./notification-box.component.css']
})
export class NotificationBoxComponent implements OnInit {
  actionSuccess: boolean;
  actionMessage: string;
  actionSubtitle: string;
  boxShouldBeVisible: boolean;

  constructor(private notificationService: NotificationWindowService) {
   }

  ngOnInit() {
    this.notificationService.messageEvent.subscribe((ntf: {message: string, status: boolean}) => {
      this.boxShouldBeVisible = true;
      this.actionSuccess = ntf.status;
      this.actionMessage = ntf.message;
      setTimeout(() => {
        this.boxShouldBeVisible = false;
      }, 2900);
    });
  }

}
