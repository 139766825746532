import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'pagination-footer',
  templateUrl: './pagination-footer.component.html',
  styleUrls: ['./pagination-footer.component.css']
})
export class PaginationFooterComponent implements OnInit {
  @Input() totalPageNumber: number;
  @Input() currentPage: any;
  @Output()  pageChange = new EventEmitter<number>();
  // tslint:disable-next-line:no-inferrable-types
  @Input() maximumReached;
  @Input() superMaximumReached;

  maxPage: number = 100;
  errorExists = false;
  errorText: string;
  prevPage: number;
  nextPage: number;

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.currentPage = parseInt(this.currentPage);
    //  console.log('Pagination, page: ' + this.currentPage);
    this.prevPage = this.currentPage - 1;
    this.nextPage = this.currentPage + 1;

    if(this.maximumReached) {
      this.maximumReached = true;
      this.maxPage = this.currentPage;
      this.totalPageNumber = this.maxPage;
    }

    if(this.superMaximumReached) {
      this.maximumReached = true;
      this.maxPage = this.currentPage - 1;
      this.totalPageNumber = this.maxPage;
      this.errorText = 'Nema podataka za stranicu br.' + this.currentPage;
      this.errorExists = true;
    }
  }

  pageSelect(page: number) {
    if (page >= this.maxPage && !this.maximumReached) {
      // Maximum reached
      this.dataService.maxPageReached.emit(true);
    } else {
      if (page > this.totalPageNumber) {
        this.totalPageNumber = page;
      }
      this.currentPage = page;
      this.pageChange.emit(page);
    }
  }

  pageIs(page: number) {
    return this.currentPage === page;
  }



}
