import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { ScrollingService } from '../shared/services/scrolling.service';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css']
})
export class MainLayoutComponent implements OnInit {
  title = 'dhm';
  timePassed = false;
  topVisible = true;
  lastScrolledVal = 0;
  screenWidth: number;
  screenHeight: number;

  constructor(private translate: TranslateService,
              private router: Router,
              private scrollService: ScrollingService,
              @Inject(DOCUMENT) private document: Document) {
    translate.setDefaultLang('me');
  }

  ngOnInit() {
    this.screenHeight = this.document.documentElement.clientHeight;
    this.screenWidth = this.document.documentElement.clientWidth;
    if( isPlatformBrowser ) {
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }

        if (evt.url.indexOf('/quiz/') === -1) {
          window.scrollTo(0, 0);
        }
      });
    }
    

    setTimeout(() => {
      this.timePassed = true;
    }, 300);
  }

  @HostListener('window:scroll', [])
    onWindowScroll() {
     // we'll do some stuff here when the window is scrolled
     const scrolVal = this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    //  // //  console.log('page scrolled, scroll value: ' + scrolVal);
     if(this.screenWidth > 991) {
      if (scrolVal > 64) {
        this.topVisible = false;
        this.scrollService.scrolledFromTop.emit(true);
      } else {
          this.topVisible = true;
          this.scrollService.returnedToTop.emit(true);
      }

      if(scrolVal < this.lastScrolledVal) {
        this.scrollService.upwardScroll.emit(true);
      } else {
        this.scrollService.upwardScroll.emit(false);
      }

      this.lastScrolledVal = scrolVal;
    }
  }


  // @HostListener('window:resize', ['$event'])
  //   onResize(event) {
  //     this.innerWidth = window.innerWidth;
  //     // //  console.log(this.innerWidth);
  //     if (this.innerWidth <= 991) {
  //       this.topVisible = false;
  //       this.scrollService.topMenuPassed.emit(true);
  //     } else {
  //       this.topVisible = true;
  //       this.scrollService.returnedToTop.emit(true);
  //     }
  //   }



}
