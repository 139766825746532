<div class="f-c outter-slider-cont">
  <div id="sammsCarousel" class="carousel slide" data-interval="3000" data-ride="carousel">
    <div class="carousel-inner">
      <div *ngFor="let slide of slides; let s = index" class="carousel-item {{ s === 0 ? 'active' : '' }}">
        <app-slider2-item [slide]="slide"></app-slider2-item>
      </div>
    </div>

    <div class="w-100 crsl-indicators">
      <ol class="carousel-indicators">
        <li *ngFor="let slide of slides; let i = index" data-target="#sammsCarousel"[attr.data-slide-to]="i" 
        [class]="i === 0 ? 'active' : ''"></li>
      </ol>
    </div>
  </div>
  
  
</div>

