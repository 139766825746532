export class Document {
  id: number;
  nameMe: string;
  nameEn: string;
  author: {
      id: number,
      firstname: string,
      lastname: string,
      username: string
  };
  editor: {
    id: number,
    firstname: string,
    lastname: string,
    username: string
  };
  dateCreated: Date;
  dateChanged: Date;
  file: any;
  document_type: any;

  constructor(obj: {
    id: number,
    name_me: string,
    name_en: string,
    created_by: any;
    updated_by: any;
    created_at: Date;
    updated_at: Date;
    file: any;
    document_type: any;
  }) {
    this.id = obj.id;
    this.nameMe = obj.name_me;
    this.nameEn = obj.name_en;
    this.author = obj.created_by;
    this.editor = obj.updated_by;
    this.dateCreated = obj.created_at;
    this.dateChanged = obj.updated_at;
    this.file = obj.file;
    this.document_type = obj.document_type;
  }
}