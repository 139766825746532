import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from '../../services/lang.service';
import { ScrollingService } from '../../services/scrolling.service';

@Component({
  selector: 'app-dark-stripe',
  templateUrl: './dark-stripe.component.html',
  styleUrls: ['./dark-stripe.component.css']
})
export class DarkStripeComponent implements OnInit {
  lang: string;
  topVisible = true;
  subMenuVisible = false;
  scrolledUp = false;

  constructor(private translate: TranslateService,
              private langService: LangService,
              private scrollingService: ScrollingService) { }

  ngOnInit() {

    this.lang = this.translate.currentLang;

    this.scrollingService.scrolledFromTop.subscribe((ev) => {
      if(ev) {
        this.topVisible = false;
      }
    });

    this.scrollingService.returnedToTop.subscribe((ev) => {
      if(ev) {
        this.topVisible = true;
      }
    });

    this.scrollingService.upwardScroll.subscribe((isUpScroll) => {
      if(isUpScroll) {
        this.scrolledUp = true;
      } else {
        this.scrolledUp = false;
      }
    });
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
    this.langService.languageChanged.emit(lang);
    this.lang = lang;
  }

  IfMe() {
    if (this.lang === 'me') { return true; }
  }

}
