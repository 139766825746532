<div class="article-component-container pager">
  <div class="article-header mv-24">
      <h1 class="article-title text-center">
         {{  'whereAndWhenToDonate' | translate }}
      </h1>
  </div>

  <div class="article-content">

      <app-ztk-map-section></app-ztk-map-section>
  </div>

  <div class="p-2 mv-24"></div>
  
</div>

