import { Injectable, EventEmitter } from '@angular/core';
import { Subscription, forkJoin, Observable, of } from 'rxjs';
import { Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map, catchError } from 'rxjs/operators';
import { ArticleCategory } from '../../../shared/models/article-category.model';
import { DataService } from '../data.service';

@Injectable({
  providedIn: 'root'
})
export class CategorySectionResolverService {
  category: ArticleCategory;
  categoryChanged = new EventEmitter<number>();

  constructor(private router: Router,
    private translate: TranslateService,
    private dataService: DataService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    switch(route.params.categoryName) {
      case 'muska-obuca':
        this.categoryChanged.emit(172);
        return this.dataService.getDataFromUrl('post-categories?id=172');
        break;
      case 'muske-cipele':
        this.categoryChanged.emit(273);
        return this.dataService.getDataFromUrl('post-categories?id=273');
        break;
      case 'muske-cizme':
        this.categoryChanged.emit(274);
        return this.dataService.getDataFromUrl('post-categories?id=274');
        break;
      case 'muske-patike':
        this.categoryChanged.emit(277);
        return this.dataService.getDataFromUrl('post-categories?id=277');
        break;
      case 'zenska-obuca':
        this.categoryChanged.emit(173);
        return this.dataService.getDataFromUrl('post-categories?id=173');
        break;
      case 'zenske-cipele':
        this.categoryChanged.emit(275);
        return this.dataService.getDataFromUrl('post-categories?id=275');
        break;
      case 'zenske-cizme':
        this.categoryChanged.emit(276);
        return this.dataService.getDataFromUrl('post-categories?id=276');
        break;
      case 'zenske-patike':
        this.categoryChanged.emit(278);
        return this.dataService.getDataFromUrl('post-categories?id=278');
        break;
      case 'gulliver-program':
        this.categoryChanged.emit(175);
        return this.dataService.getDataFromUrl('post-categories?id=175');
        break;
      case 'klompe':
        this.categoryChanged.emit(174);
        return this.dataService.getDataFromUrl('post-categories?id=174');
        break;
      case 'tasne-i-novcanici':
        this.categoryChanged.emit(176);
        return this.dataService.getDataFromUrl('post-categories?id=176');
        break;
    }
    // if(route.url[0].path === 'post') {
    //   return this.articleService.getArticleById( +route.params['id'], true );
    // } else {
    //   return this.articleService.getArticleById( +route.params['id'], false );
    // }

  }

}
