<div class="article-component-container pager">
  <div class="article-header mv-24">
      <h1 class="article-title text-center">
         {{  'ethicsComitee' | translate }}
      </h1>
  </div>

  <div class="article-content">

    <div class="employee-box col-12">
      <ul class="no-decor">
        <li>{{ 'ecom1' | translate }}</li>
        <li>{{ 'ecom2' | translate }}</li>
        <li>{{ 'ecom3' | translate }}</li>
        <li>{{ 'ecom4' | translate }}</li>
        <li>{{ 'ecom5' | translate }}</li>


      </ul>        
    </div>

  </div>

  
</div>

