<div class="rot-pro-full-container">

  <div class="products-recomended mb-64 text-center bold">
    <h2 class="rem-1-85">{{ 'weRecommend' | translate }}</h2>
  </div>
  
  <div class="carousel slide" id="rotationCarousel" data-interval="3000" data-ride="carousel">
    <div class="carousel-inner">
      <ng-container *ngIf="screenWidth >= 882">
        <div *ngFor="let gr of productGroups; let g = index" class="carousel-item {{ g === 0 ? 'active' : '' }}" >
          <div class="rotation-group w-100 f-r">
            <ng-container *ngFor="let item of gr">
              <app-rotation-product-item  *ngIf="item != null && item !== undefined" [product]="item" class="f1"></app-rotation-product-item>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="882 > screenWidth">
        <div  *ngFor="let singleProduct of rotationProducts; let g = index" class="carousel-item {{ g === 0 ? 'active' : '' }}" >
              <app-rotation-product-item  *ngIf="singleProduct != null && singleProduct !== undefined" [product]="singleProduct" class="f1"></app-rotation-product-item>
        </div>
      </ng-container>
      


    </div>
    <a class="carousel-control-prev f-c" href="#rotationCarousel" role="button" data-slide="prev">
      <span class="fa fa-chevron-left" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next f-c" href="#rotationCarousel" role="button" data-slide="next">
      <span class="fa fa-chevron-right" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>