<div [ngClass]="{'sub-menu-cont sub-menu-navigation f-r animated': true, 'fadeIn': subMenuStatus == 1, 
'fadeOutUp faster': subMenuStatus == 2, 'hidden': subMenuStatus == 0}">
  <div *ngIf="menuOption != null && menuOption !== undefined" class="sub-options-cont f-c" (mouseleave)="hideSubMenuIfApplicable($event)">
    
    <div *ngFor="let sub of menuOption.subOptions" class="sub-option m-1">
      <span class="bt"><a *ngIf="sub.target == null || sub.target === undefined" class="no-decor sub-link bt" [routerLink]="sub.link"> {{ currentLang === 'me' ? sub.nameMe : sub.nameEn }}</a></span>
      <span class="bt"><a *ngIf="sub.target != null && sub.target !== undefined" class="no-decor sub-link bt" 
        href="{{ config.baseUrl + 'uploads/Statut_14110a5ca3.pdf' }}" target="_blank"> {{ currentLang === 'me' ? sub.nameMe : sub.nameEn }}</a></span>
    </div>


  </div>
</div>


