import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SiteArticle } from '../../models/site-article.model';
import { SiteProduct } from '../../models/site-product.model';
import { LangService } from '../../services/lang.service';

@Component({
  selector: 'app-slider2-item',
  templateUrl: './slider2-item.component.html',
  styleUrls: ['./slider2-item.component.css']
})
export class Slider2ItemComponent implements OnInit {
  @Input() slide: SiteArticle;

  constructor(public translate: TranslateService,
              private langService: LangService) { }

  ngOnInit() {
    //  console.log(this.slide);
    this.slide.showAppropriateLangData(this.translate.currentLang);

    this.langService.languageChanged.subscribe((lang) => {
      this.slide.showAppropriateLangData(lang);
    })
  }

}
