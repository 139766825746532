import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from './../shared/services/lang.service';
import { SiteArticle } from '../shared/models/site-article.model';
import { ArticleCategory } from '../shared/models/article-category.model';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { DataService } from '../shared/services/data.service';


@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css']
})
export class ArticleComponent implements OnInit {
  public articleFetch: any[];
  public article: SiteArticle;
  public artObs: any;
  errorExists = false;
  public category: ArticleCategory;


  constructor(private route: ActivatedRoute,
              private router: Router,
              private translate: TranslateService,
              private domSanitizer: DomSanitizer,
              private metaService: Meta,
              private dataService: DataService,
              private langService: LangService) {
    this.artObs = this.route.snapshot.data.article;
    //  console.log(this.artObs);
    if(this.artObs !== undefined) {
      this.errorExists = false;
      this.article = new SiteArticle(this.artObs);
      this.article.showAppropriateLangData(this.translate.currentLang);

      // this.article.videoUrl = this.getYouTubeVideoUrl(this.article.videoUrl);
      if(this.article.visualType === 'I') {
        try {
          let metaTag = this.metaService.getTag('property=\'og:image\'');
          this.metaService.removeTag('property=\'og:url\'');
          this.metaService.updateTag({ property: 'og:image', content: this.article.imageUrl });
          this.metaService.updateTag({ property: 'og:title', content: this.article.title });
          this.metaService.updateTag({ property: 'og:description', content: this.article.text });
        }
        catch(err) {
          // ne postoji tagav tag
          // //  console.log(err);
          this.metaService.addTag( { property: 'og:image', content: this.article.imageUrl });
        }
      }
      if(this.article.visualType === 'V') {
        try {
          let metaTag = this.metaService.getTag('property=\'og:url\'');
          this.metaService.removeTag('property=\'og:image\'');
          this.metaService.updateTag({ property: 'og:url', content: this.article.videoUrl });
          this.metaService.updateTag({ property: 'og:title', content: this.article.title });
          this.metaService.updateTag({ property: 'og:description', content: this.article.text });
        }
        catch(err) {
          // ne postoji tagav tag
          // //  console.log(err);
          this.metaService.addTag( { property: 'og:url', content: this.article.videoUrl });
        }
      }
    } else {
      this.errorExists = true;
    }

  }

ngOnInit() {
  this.langService.languageChanged.subscribe(
    (lang) => {
      this.translate.onLangChange.emit()
      if (lang === 'me') {
        this.article.title = this.article.me.title;
        this.article.subtitle = this.article.me.subtitle;
        this.article.text = this.article.me.text;
      } else {
        this.article.title = this.article.en.title;
        this.article.subtitle = this.article.en.subtitle;
        this.article.text = this.article.en.text;
      }
    }
  );
}

// getYouTubeVideoUrl(videoUrl: string) {
//   let url: string;
//   if (videoUrl != null) {
//     if (videoUrl.indexOf('&') === -1) {
//       url = 'https://www.youtube.com/embed/' + videoUrl.slice(videoUrl.indexOf('?v=') + 3);
//     } else {
//       url = 'https://www.youtube.com/embed/' + videoUrl.slice(videoUrl.indexOf('?v=') + 3, videoUrl.indexOf('&'));
//     }
//     return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
//   } else {
//     return null;
//   }
// }

}
