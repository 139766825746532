import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '../../../Config';
import { Document } from '../../shared/models/document.model';
import { DataService } from '../../shared/services/data.service';
import { LangService } from '../../shared/services/lang.service';
import { DocumentType } from '../../shared/models/document-type.model';



@Component({
  selector: 'app-documents-page',
  templateUrl: './documents-page.component.html',
  styleUrls: ['./documents-page.component.css']
})
export class DocumentsPageComponent implements OnInit {
  documentTypes: Array<DocumentType> = [];
  documents: Array<Document> = [];
  currentLang: string;
  config = new Config();
  errorExists = false;
  docGroups = {};

  constructor(private dataService: DataService,
              private translate: TranslateService,
              private langService: LangService) { }

  ngOnInit() {
    this.loadDocumentTypes();
    this.currentLang = this.translate.currentLang;
    this.langService.languageChanged.subscribe((lang) => {
      this.translate.currentLang = lang;
      this.currentLang = lang;
    });
  }

  loadDocumentTypes() {
    this.documentTypes = [];
    /* ovo me dolje ne utiče na prevod */
    this.dataService.getDataFromUrl('document-types?&_start=0&_limit=50&_sort=id:ASC').subscribe(
      (rez: any) => {
        // tslint:disable-next-line:prefer-for-of
        if (rez instanceof Array && rez.length > 0) {
          const docs = [];
          for (const element of rez) {
            const dok = new DocumentType(element);
            docs.push(dok);
          }
          this.documentTypes = docs;
          this.loadDocuments();

        } else {
          this.errorExists = true;
          // //  console.log('Dogodila se greska');
        }
      }, (err) => {
        console.log(err);
        this.errorExists = true;
      });

  }

  loadDocuments() {
    this.documents = [];
    /* ovo me dolje ne utiče na prevod */
    this.dataService.getDataFromUrl('documents?&_start=0&_limit=200&_sort=created_at:ASC').subscribe(
      (rez: any) => {
        console.log('Dokuments are here!')
         console.log(rez);
        if (rez instanceof Array && rez.length > 0) {
          const docs = [];
          for (const element of rez) {
            const dok = new Document(element);
            docs.push(dok);
          }
          this.documents = docs;
          this.groupDocuments();
        } else {
          this.errorExists = true;
          // //  console.log('Dogodila se greska');
        }
      }, (err) => {
        this.errorExists = true;
      });
  }

    groupDocuments() {
      this.docGroups = {}
      
      this.documentTypes.forEach((t) => {
        if(t){
          this.docGroups[t.id + ''] = this.documents.filter((doc) => doc?.document_type?.id === t.id);
        }
      })


    }

}
