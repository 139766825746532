import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import { SignInComponent } from '../../modals/sign-in/sign-in.component';
import { ModalsService } from '../../shared/services/modals.service';
import { AuthService } from '../../shared/services/auth.service';
import { Router } from '@angular/router';
import { ShoppingCartService } from '../../shared/services/shopping-cart.service';
import { CartItem } from '../../shared/models/cart-item.model';
import { DataService } from '../../shared/services/data.service';
import { SearchTerm } from '../../shared/models/search-term.model';
import { LangService } from '../../shared/services/lang.service';
import { TranslateService } from '@ngx-translate/core';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-site-options',
  templateUrl: './site-options.component.html',
  styleUrls: ['./site-options.component.css']
})
export class SiteOptionsComponent implements OnInit {
  data: Array<SearchTerm> = [];
  isLoggedIn: boolean;
  loggedInUser: any;
  numberOfItemsInCart: number;
  currentLang: string;
  isLoading = false;
  @ViewChild('autocomplete', { static: true }) autocomplete: AutocompleteComponent;


  constructor(private modalService: ModalsService,
              private auth: AuthService,
              private dataService: DataService,
              private router: Router,
              private translate: TranslateService,
              private langService: LangService,
              private cart: ShoppingCartService) { }

  ngOnInit() {
    this.auth.isLoggedIn.subscribe((val) => {
      this.isLoggedIn = val;
      this.loggedInUser = this.auth.loggedInUser;
    });

    this.auth.loggedOut.subscribe((val) => {
      if(val) {
        this.fetchCartFromLocalStorage();
      }
    });

    this.auth._loggedIn.subscribe((val) => {
      if (val) {
        this.fetchCartFromDb();
      }
    });

    this.fetchCart();

    this.cart.itemAdded.subscribe((addedItem) => {
      this.fetchCart();
    });

    this.cart.itemDeleted.subscribe((del) => {
      this.fetchCart();
    });

    this.cart.cartCleared.subscribe((val) => {
      if(val) {
        this.fetchCart();
      }
    });

    this.currentLang = this.translate.currentLang;

    this.langService.languageChanged.subscribe((lang) => {
      this.currentLang = lang;
      this.showAppropriateLang(lang);
    });

  }

  selectEvent(item) {
    // do something with selected item
    // //  console.log('selectEvent');
  }
 
  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    // this.keyword = val;
      // tslint:disable-next-line: max-line-length
      this.dataService.getDataFromUrl('products/autocomplete?term=' + val).subscribe((resp: any) => {
        if (resp.code === 200) {
          // //  console.log(resp);
          const terms: Array<any> = [];
          resp.data.forEach((el: {termMe: string, termEn: string, type: string, termId: number, link: string}) => {
            terms.push(new SearchTerm(el));
          });
          this.data = terms;
          this.isLoading = false;
          this.showAppropriateLang(this.currentLang);
          this.autocomplete.opened.emit();
        }
      });
  }


  showAppropriateLang(lang: string) {
    this.data.forEach((elem) => {
      if(lang === 'me') {
        elem.name = elem.term.me;
      } else {
        elem.name = elem.term.en;
      }
    });
  }
  
  onFocused(e){
    // do something when input is focused
    // //  console.log('onFocused');

  }

  goToLogin() {
    this.modalService.modalSignIn.emit(true);
  }

  logMeOut() {
    this.auth.logout();
    this.router.navigate(['/home']);
  }

  openMyCart() {
    // this.modalService.modalMyCart.emit(true);
    this.router.navigate(['/my-cart']);
  }

  fetchCart() {
    if(this.isLoggedIn) {
      this.fetchCartFromDb();
    } else {
      this.fetchCartFromLocalStorage();
    }
  }

  fetchCartFromLocalStorage() {
    let cartItems: Array<CartItem>;
    cartItems = this.cart.localCartItems;
    this.numberOfItemsInCart = cartItems.length;

    // //  console.log('Broj elemenata u korpi je: ' + cartItems.length);

  }

  fetchCartFromDb() {
    const obj = {};

    this.dataService.putAnyData('cart', obj).subscribe((response: any) => {
      if(response.success === false) {
        this.auth.logout();
        this.router.navigate(['/home']);
      }
      if(response.code === 200) {
        const cartItems: Array<CartItem> = [];
        response.data.forEach(element => {
          cartItems.push(new CartItem({id: element.id, product: JSON.parse(element.product), size: element.size, quantity: element.quantity,
             price_at_time: element.price_at_time, total_at_time: element.total_at_time}));
        });

        this.numberOfItemsInCart = cartItems.length;
      }
    },
    (err) => {
      // //  console.log('Greška u učitavanju korpe');
      // //  console.log(err);
    });
  }
}
