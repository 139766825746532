<div class="pagination-container f-c">
  <ul class="pages f-r">

      <!-- Dugme da se vratiš za jednu stranicu nazad -->
      <li class="f-c arrow-cont" *ngIf="totalPageNumber > 1" (click) = "pageSelect(currentPage-1)">
        <i class="page-arrows fa fa-arrow-left"></i>
      </li>
  
      <!-- Prva stranica -->
      <li *ngIf="totalPageNumber > 1 && 3 > currentPage"
          (click) = "pageSelect(1)"
          [ngClass] = "{'current-page': pageIs(1), 'page-number': true}">1</li>
  
  
      <!-- Prethodna stranica -->
      <li *ngIf="currentPage > 2"
        (click) = "pageSelect(currentPage - 1)"
        [ngClass] = "{'current-page': false, 'page-number': true}">{{ prevPage }}</li>
  
      <!-- Trenutna stranica -->  
      <li
        [ngClass] = "{'current-page': true, 'page-number': true}">{{ currentPage }}</li>
  
      
      <li *ngIf="!maximumReached"
        (click) = "pageSelect(currentPage + 1)"
        [ngClass] = "{'current-page': false, 'page-number': true}">{{ nextPage }}</li>
  
  
      <li class="f-c arrow-cont" *ngIf="!maximumReached" (click) = "pageSelect(currentPage+1)">
        <i class="page-arrows fa fa-arrow-right"></i>
      </li>
    </ul>
  
</div>
