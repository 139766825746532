<div class="article-component-container pager">
  <div class="article-header mv-24">
      <h1 class="article-title text-center">
         {{  'history' | translate }}
      </h1>
  </div>

  <div class="article-content">

      <div *ngIf="currentLang === 'me'" class="article-text me">
        <p>Osnivanje Zavoda za transfuziju krvi Crne Gore u decembru 2011.godine, predstavlja krunisanje dugogodišnjeg rada i izrade brojnih strateških dokumenata koji su bili neophodni da bi se ustanovile dobre osnove za uspostavljanje organizovane, kvalitetne transfuziološke službe koja će moći da pruži usluge građanima i pacijentima na čitavoj teritoriji Crne Gore i da zadovolji tražene kriterijume.</p>
        <p>Uključivanje transfuziološke službe Crne Gore u projekat „ Sigurna krv “za zemlje jugo-istočne Evrope pod patronatom SZO i Pakta stabilnosti 2004.godine, započeto potpisivanjem „Dubrovačke deklaracije“ ministara zdravlja zemalja jugo-istočne Evrope 2001.godine, označilo je početak sistematskog rada i nastavka aktivnosti započetih na prikupljanju neophodnih podataka za uspostavljanje dobro organizovane transfuziološke službe.</p>
        <p>Kontinuiran rad na ovoj problematici doveo je do brojnih aktivnosti i izrade strateških dokumenata.</p>
        <ul>
        <li>2003 – osnivanje Republičke komisije za transfuziju krvi pri Ministarstvu zdravlja Crne Gore;</li>
        <li>2004 – početak projekta “SIGURNA KRV”;</li>
        <li>2005 – izrada dokumenta »Analiza stanja transfuziološke Službe u CGi«;</li>
        <li>2006 – »Strategija bezbijedne krvi«;</li>
        <li>2007 – »Zakon o obezbjeđivanju krvi«;</li>
        <li>2008 – »Nacionalni program DDK u Crnoj Gori«;</li>
        <li>2009 – aplikacija Ministarstva zdravlja za IPA 2010;</li>
        </ul>
        <p>Analizom dobijenih podataka, i pored ogromnih napora transfuziološkog osoblja da revnosno ispuni svoje radne zadatke, transfuziološka služba je ocijenjena kao fragmentisana, neefikasna i nerentabilna.<br>
        U vrijeme kada SZO preporučuje postojanje jednog transfuziološkog centra na milion stanovnika, u Crnoj Gori, transfuziološke usluge se pružaju kroz rad devet nezavisnih službi i to jednog Centra za transfuziju krvi KC-a Crne Gore, kao najrazvijenije transfuziološke službe i osam kabineta za transfuziju krvi u okviru bolnica. Ti kabineti su uglavnom bili smješteni u neadekvatnom i neuslovnom prostoru u skladu sa tretiranjem ove djelatnosti, opremljeni simboličnom i zastarelom opremom, bez mogućnosti pripreme i skladištenja komponentne terapije i bez ikakvih preduslova za uvođenje sistema osiguranja kontrole kvaliteta. Sve ovo, dovelo je do velikih razlika u mogućnostima pružanja usluga i kvaliteta pruženih usluga a samim tim i do nejednake dostupnosti transfuziološke terapije pacijentima liječenim u različitim krajevima Crne Gore.<br>
        To je bio početak ozbiljnih razmišljanja o novoj organizaciji ove službe, uspostavljenoj na nacionalnom nivou.</p>
        <p>Već naredne godine, Ministarstvo zdravlja započinje aktivnosti na adaptaciji i rekonstrukciji neadekvatnih prostora Kabineta za transfuziju krvi u Pljevljima i Bijelom Polju i izgradnji novog prostora Kabineta za transfuziju krvi u Beranama koji danas funkcionišu u novim uslovima.</p>
        <p>Funkcionisanje Zavoda, kao nove, samostalne JZU, započelo je 01.07.2012.godine u skladu sa Odlukom Vlade.</p>
      </div>

      <div *ngIf="currentLang === 'en'" class="article-text en">

        <p>The establishment of the Blood Transfusion Institute of Montenegro in December 2011 is the culmination of many years of work and development of numerous strategic documents that were necessary to establish a good basis for establishing an organized, quality transfusion service that will be able to provide services to citizens and patients throughout territory of Montenegro and to meet the required criteria.</p>
        <p>The inclusion of the Transfusion Service of Montenegro in the project "Safe Blood" for the countries of Southeast Europe under the auspices of the WHO and the Stability Pact in 2004, started with the signing of the "Dubrovnik Declaration" of Health Ministers of Southeast Europe in 2001, marked the beginning of systematic work. continuation of activities started on collecting the necessary data for the establishment of a well-organized transfusion service.</p>
        <p>Continuous work on this issue has led to numerous activities and the development of strategic documents.</p>
        <ul>
          <li>2003 - establishment of the Republic Commission for Blood Transfusion at the Ministry of Health of Montenegro;</li>
          <li>2004 - beginning of the project "SAFE BLOOD";</li>
          <li>2005 - preparation of the document "Analysis of the state of the transfusion service in Montenegro";</li>
          <li>2006 - "Safe Blood Strategy";</li>
          <li>2007 - "Law on Blood Supply";</li>
          <li>2008 - "National DDK Program in Montenegro";</li>
          <li>2009 - application of the Ministry of Health for IPA 2010;</li>
        </ul>
        <p>By analyzing the obtained data, despite the enormous efforts of the transfusion staff to diligently fulfill their work tasks, the transfusion service was assessed as fragmented, inefficient and unprofitable.
          At a time when the WHO recommends the existence of one transfusion center per million inhabitants, in Montenegro, transfusion services are provided through the work of nine independent services, one Blood Transfusion Center of KC Montenegro, as the most developed transfusion service and eight blood transfusion offices in Montenegro. within hospitals. These cabinets were mostly located in inadequate and unconditional space in accordance with the treatment of this activity, equipped with symbolic and outdated equipment, without the possibility of preparation and storage of component therapy and without any preconditions for the introduction of quality control system. All this has led to large differences in the possibilities of providing services and the quality of services provided, and thus to the unequal availability of transfusion therapy to patients treated in different parts of Montenegro.
          That was the beginning of serious thoughts about the new organization of this service, established at the national level.
          </p>
        <p>Following year, the Ministry of Health began activities on the adaptation and reconstruction of inadequate premises of the Blood Transfusion Cabinet in Pljevlja and Bijelo Polje and the construction of a new premises of the Blood Transfusion Cabinet in Berane, which are functioning in new conditions today.</p>
        <p>The functioning of the Institute, as a new, independent PHI (Public Health Institution), began on July 1, 2012, in accordance with the Government Decision.</p>
      </div>
  </div>

  <div class="p-2 mv-24"></div>
  
</div>

