<div class="article-component-container pager">
  <div class="article-header mv-24">
      <h1 class="article-title text-center">
         {{  'aboutInstitute' | translate }}
      </h1>
  </div>

  <div class="article-content">

      <div *ngIf="currentLang === 'me'" class="article-text me">
        <p>Zavod za transfuziju krvi Crne Gore&nbsp;je visokospecijalizovana zdravstvena ustanova osnovana u decembru 2011 godine, koja od tada organizovano i kontinuirano vrši prikupljanje, čuvanje, testiranje i pripremu produkata od krvi kao i izvođenje laboratorijskih analiza potrebnih za uspešnu i sigurnu primenu transfuzije krvi. Prikupljanje potrebnih količina krvi i produkata od krvi za lečenje povređenih i obolelih lica odvija se uz nesebičnu saradnju humanih građana na teritoriji Crne Gore.<br>
          Stalna aktivnost Zavoda na razvoju kvaliteta usluga i proizvoda zasnovana je na najnovijim naučnim dostignućima, unapređenju postojećih i uvođenju novih savremenih metodologija rada.<br>
          Transfuziološka djelatnost Zavod za transfuziju krvi Crne Gore&nbsp; se zasniva na tri osnovna segmenta:<br>
          Prvi segment i osnovna djelatnost transfuziološke službe jeste kontinuirano obezbijeđivanje dovoljnih količina sigurne krvi/komponenata krvi neophodnih za svakodnevno liječenje pacijenata, za potrebe urgentnih stanja i vanrednih situacija.<br>
          Ovaj segment transfuziološkog rada podrazumjeva:</p>
          <ul class="circle">
            <li>Rad sa davaocima krvi i aktivnosti na zdravstvenom prosvjećivanju stanovništva različitih populacionih grupa u saradnji sa Crvenim krstom koji se sprovodi u cilju obezbijeđenja dovoljnih količina krvi od dobrovoljnih, nenamjenskih davaoca krvi;</li>
          <li>Obradu svake jedinice krvi na krvno-grupnu pripadnost u ABO i Rh sistemu;</li>
          <li>Skrining na prisustvo iregularnih antitijela;</li>
          <li>Testiranje svake jedinice krvi na prisustvo uzročnika krvlju prenosivih bolesti – HIV,HCV,HBV i Sifilis;</li>
          <li>Pripremu komponenata krvi;</li>
          <li>Obilježavanje proizvoda koje obezbijeđuje sljedljivost svake jedinice krvi/komponente krvi od davaoca do primaoca;</li>
          <li>Skladištenje svake komponente krvi u skladu sa propisanim standardima;</li>
          <li>Distribucija i transport komponenata krvi u skladu sa propisanim standardima;</li>
          <li>Kompletan proces mora biti propraćen ustanovljenim sistemom osiguranja kontrole kvaliteta u cilju dobijanja bezbijednog lijeka.</li>
          </ul>
          <p>Drugi segment rada transfuziološke službe je sprovođenje veoma specifične i osjetljive dijagnostike.<br>
          Treći segment i krajnji cilj transfuziološke djelatnosti je liječenje pacijenata koje podrazumjeva brojne terapijske postupke i kontinuirano učešće u konzilijarnom ordiniranju transfuziološke terapije, poštujući principe usmjerene hemoterapije u skladu sa optimalnom kliničkom primjenom krvi/komponenata krvi, kao i prijavu neželjenih reakcija i događaja, nastalih kao poslijedica transfuziološke terapije.</p>
          
      </div>

      <div *ngIf="currentLang === 'en'" class="article-text en">

        <p>The Blood Transfusion Institute of Montenegro is a highly specialized health institution founded in December 2011, which has since organized and continuously collected, stored, tested and prepared blood products and performed laboratory analyzes necessary for successful and safe use of blood transfusions. The collection of the necessary quantities of blood and blood products for the treatment of injured and sick persons takes place with the selfless cooperation of humane citizens on the territory of Montenegro. <br>
          The constant activity of the Institute on the development of the quality of services and products is based on the latest scientific achievements, improvement of existing and introduction of new modern work methodologies. <br>
          Transfusion activity The Blood Transfusion Institute of Montenegro is based on three basic segments:<br>
          The first segment and the main activity of the transfusion service is the continuous provision of sufficient quantities of safe blood / blood components necessary for the daily treatment of patients, for the needs of emergencies and emergencies. <br>
          This segment of transfusion work includes:</p>
          <ul class="circle">
          <li>Work with blood donors and activities on health education of the population of different population groups in cooperation with the Red Cross, which is carried out in order to provide sufficient amounts of blood from voluntary, non-purpose blood donors;</li>
          <li>Processing of each unit of blood on blood-group affiliation in ABO and Rh system;</li>
          <li>Screening for the presence of irregular antibodies;</li>
          <li>Testing of each unit of blood for the presence of blood-borne pathogens - HIV, HCV, HBV and Syphilis;</li>
          <li>Preparation of blood components;</li>
          <li>Product labeling that ensures the traceability of each blood unit / blood component from donor to recipient;</li>
          <li>Storage of each blood component in accordance with prescribed standards;</li>
          <li>Distribution and transport of blood components in accordance with prescribed standards;</li>
          <li>The complete process must be accompanied by an established quality control system in order to obtain a safe drug.</li>
          </ul>
          <p>Another segment of the work of the transfusion service is the implementation of very specific and sensitive diagnostics. <br>
          The third segment and the ultimate goal of transfusion activity is the treatment of patients, which includes numerous therapeutic procedures and continuous participation in the conciliatory administration of transfusion therapy, respecting the principles of targeted chemotherapy in accordance with optimal clinical use of blood / blood components. transfusion therapies.</p>
          
        </div>
  </div>

  <div class="p-2 mv-24"></div>
  
</div>

