import { Injectable } from '@angular/core';
import { Article } from '../models/article.model';

@Injectable({
  providedIn: 'root'
})
export class MockupService {
  sliderArticles: Array<Article> =  [
    new Article({
      id: 1,
      markupStrings: [],
      subtitle: '',
      title: '',
      lead: '',
      htmlText: '',
      images: [],
      imageIndex: 0,
      imageUrl: '',
      imageCopyright: '',
      link: '',
      category: 0
    }),
  ];

  constructor() { }

}
