import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '../../../Config';
import { LangService } from '../../shared/services/lang.service';
@Component({
  selector: 'app-questionnaire-page',
  templateUrl: './questionnaire-page.component.html',
  styleUrls: ['./questionnaire-page.component.css']
})
export class QuestionnairePageComponent implements OnInit {
  currentLang: string;
  config = new Config();
  mneFileUrl = '/uploads/UPITNIK_ZA_DAVAOCE_9f2953209b.pdf';
  enFileUrl = '/uploads/Upitnik_a2689a7bce.pdf';

  constructor(private langService: LangService,
              private translate: TranslateService) { 

              }

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
    this.langService.languageChanged.subscribe((lang) => {
      this.currentLang = lang;
      this.translate.currentLang = lang;
    });
  }
}
