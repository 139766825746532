import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
lang = 'me';
  constructor( private translate: TranslateService) { }

  ngOnInit() {
    this.IfMe();
  }
  changeLanguage(lang:string) {
    this.translate.use(lang);
    this.lang= lang;
  }

  IfMe()
  {
    if (this.lang === "me") { return true; }
  }
}
