import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { AuthService } from '../../shared/services/auth.service';
import { StorageService } from '../../shared/services/storage.service';
import { ModalsService } from '../../shared/services/modals.service';
import { ShoppingCartService } from '../../shared/services/shopping-cart.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  loggedIn = false;
  signInForm: FormGroup;
  signInSuccess = false;
  signInSuccessMessage = 'Uspješna prijava!';
  errorExists = false;
  errorText: string;
  @ViewChild('signInModal', { static: true }) signInModal: ElementRef;
  @ViewChild('modalTriggerSign', { static: true }) modalTrigger: ElementRef;

  constructor(private router: Router,
              private auth: AuthService,
              private storageService: StorageService,
              private cart: ShoppingCartService,
              private modalService: ModalsService,
              private fb: FormBuilder) { }

  ngOnInit() {
    this.createSignForm();
    this.modalService.modalSignIn.subscribe((ev) => {
      if (ev) {
        this.modalTrigger.nativeElement.click();
        this.clearForm();
      } else {
        this.signInModal.nativeElement.click();
      }
    });
  }

  clearForm() {
    this.errorExists = false;
    this.signInSuccess = false;
    this.signInForm.setValue({
      login: null,
      password: null
    });
  }

  private createSignForm() {
    this.signInForm = this.fb.group({
      // tslint:disable-next-line:max-line-length
      login: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(24)]],
     });
  }

  signIn(loginData: any) {
    if (this.signInForm.invalid) {
      if (this.signInForm.controls.login.value == null) {
        this.signInError('Morate unijeti Vašu email adresu za prijavu');
      }
      if (this.signInForm.controls.password.value == null) {
        this.signInError('Morate unijeti lozinku za prijavu');
      } else {
        if (this.signInForm.controls.password.value.length < 8 ||
          this.signInForm.controls.password.value.length > 22) {
          this.signInError('Lozinka mora biti dužine između 8 i 22 karaktera');
        }
      }

    } else {
      this.auth.loginRequest(loginData).subscribe(
        (result: {code: number, status: string, message: string, data: any}) => {
          if (result.code === 200) {
            this.errorExists = false;
            this.storageService.sessionSet('isLoggedIn', true);
            this.storageService.sessionSet('vsite_user_token', result.data.token);
            this.storageService.sessionDelete('admin_user_token');
            this.storageService.sessionSet('loggedInUser', result.data.userdata);
            this.cart.submitCartFromSession(result.data.userdata.id).subscribe((val: any) => {
              if(val.code === 200) {
                this.signInSuccessfull();
                this.storageService.localSet('shoppingCart', []);
              } else {
                // //  console.log('Dogodila se greska u ucitavanju podataka iz sesije');
                this.signInSuccessfull();
              }
              
            },
            (err) => {
              // //  console.log(err);
              this.signInSuccessfull();
            });
          } else {
            this.signInError(result.message);
          }
        });
    }
  }

  signInSuccessfull() {
    /*this.loginFailed = false;
    this.loginCommited = true;*/
    this.auth._loggedIn.next(true);
    this.router.navigate(['/home']);
    this.signInSuccess = true;
    this.loggedIn = true;
    setTimeout( () => {
      this.signInModal.nativeElement.click();
    }, 500);
  }

  signInError(errorMessage: string) {
    this.errorText = errorMessage;
    this.errorExists = true;
  }

  goToRegister() {
    this.signInModal.nativeElement.click();
    this.modalService.modalRegister.emit(true);
  }

}
