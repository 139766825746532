import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-size-table',
  templateUrl: './size-table.component.html',
  styleUrls: ['./size-table.component.css']
})
export class SizeTableComponent implements OnInit {
  @Input('type') type: number;
  shoesSizes: Array<number>;

  constructor() {
    this.shoesSizes = Array.from(Array(29), (x, i) => i);
    // this.shoesSizes.shift();
   }

  ngOnInit() {
  }

}
