import { Component, OnInit } from '@angular/core';
import { DataService } from '../../shared/services/data.service';
import { SiteArticle } from '../../shared/models/site-article.model';
import { DomSanitizer } from '@angular/platform-browser';
import { LangService } from '../../shared/services/lang.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-promo-two',
  templateUrl: './promo-two.component.html',
  styleUrls: ['./promo-two.component.css']
})
export class PromoTwoComponent implements OnInit {

  public promoNews: SiteArticle[] = [];
  public currentLang: string;

  constructor(private dataService: DataService,
              private domSanitizer: DomSanitizer,
              private langService: LangService,
              private translate: TranslateService) { }

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
    this.langService.languageChanged.subscribe((lang) => {
      this.currentLang = lang;
      this.translateAllArticles();
    });

    this.loadPromoNews();
  }

  loadPromoNews() {
    this.promoNews = [];
    /* ovo me dolje ne utiče na prevod */
    this.dataService.getDataFromUrl('posts?promo=2&page=1&rows=3&sort=last_change_time&sortType=desc').subscribe(
      (rez: any) => {
        // tslint:disable-next-line:prefer-for-of
          if (rez.code === 200) {
            const articles = [];
            // //  console.log(rez.data);
            for (const element of rez.data) {
              const art = new SiteArticle(element);
              art.videoUrl = this.getYouTubeVideoUrl(art.videoUrl);
              art.showAppropriateLangData(this.currentLang);
              articles.push(art);
            }
            this.promoNews = articles;
          } else {
            // //  console.log('Dogodila se greska');
          }
        });
  }

  translateAllArticles() {
    this.promoNews.forEach((elem) => {
      elem.showAppropriateLangData(this.currentLang);
    });
  }


  getYouTubeVideoUrl(videoUrl: string) {
    let url: string;
    if(videoUrl != null) {
      if (videoUrl.indexOf('&') === -1) {
        url = 'https://www.youtube.com/embed/' + videoUrl.slice(videoUrl.indexOf('?v=') + 3);
      } else {
        url = 'https://www.youtube.com/embed/' + videoUrl.slice(videoUrl.indexOf('?v=') + 3, videoUrl.indexOf('&'));
      }
      return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      return null;
    }
  }

}
