import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from '../shared/services/lang.service';
import { ModalsService } from '../shared/services/modals.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  lang = 'me';

  constructor(private translate: TranslateService,
              private langService: LangService,
              private modalService: ModalsService) { }

  ngOnInit() {
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
    this.langService.languageChanged.emit(lang);
    this.lang = lang;
  }

  IfMe() {
    if (this.lang === 'me') { return true; }
  }

  goToRegister() {
    this.modalService.modalRegister.emit(true);
  }
}
