<div class="blog-page">

    <div class="blog-meta">
            <div class="page-title mv-12">
                {{ 'blog' | translate }}
            </div>
    </div>

    <div class="blog-articles" *ngIf="blogArticles.length > 0; else noArticles">
        <div class="articles articles-wrap f-r">
          <app-article-preview class="multi-row" *ngFor="let blogArt of blogArticles" [article]="blogArt">
          </app-article-preview>
        </div>
    </div>
    
  
      <ng-template #noArticles>
        <div class="no-articles">
          <p>{{ 'noArticles' | translate }}</p>
        </div>
      </ng-template>
  
      <div class="custom-spacing">
        <pagination-footer 
          (pageChange) = "onPageSelected($event)"
          totalPageNumber="1">
        </pagination-footer>
      </div>
    
</div>
