<div class="culture-page">

    <div class="culture-meta">
            <div class="page-title mv-12">
                {{ 'culture' | translate }}
            </div>
        
            <div class="page-info mv-24">
                {{ 'cultureInfo' | translate }}
            </div>
    </div>

    <div class="culture-articles">
            <div *ngIf="cultureArticles !== undefined && cultureArticles.length > 0; else noArticles"
            class="articles f-r">
                <app-article-preview *ngFor="let clArt of cultureArticles" [article]="clArt"></app-article-preview>
            </div>
        
            <ng-template #noArticles>
                <div class="no-articles">
                    <p>{{ 'noArticles' | translate }}</p>
                </div>
            </ng-template>
    </div>

    <div class="pgn">
        <pagination-footer 
            (pageChange) = "onPageSelected($event)"
            totalPageNumber="1">
        </pagination-footer>
    </div>
    
</div>