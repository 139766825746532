import { formatDate } from '@angular/common';
import { Config } from '../../../Config';

export class SiteProduct {
  // tslint:disable-next-line:variable-name
  public id: number;
  public sifra: string;
  public name: string;
  public description: string;
  public productSlug: string;
  public me: {
      name: string,
      description: string,
      productSlug: string
  };
  public en: {
      name: string,
      description: string,
      productSlug: string
  };
  public brand: {
      id: number,
      name: string,
      brandCategory: string,
  };
  public price: number;
  public salePrice: number;
  public imageUrl: string;
  public smallImageUrl: string;
  public bigImageUrl: string;
  public imageOrientation: string;
  public category: number;
  public promoPosition: number;
  public galleryId: number;
  public status: string;
  public dateCreated: string;
  public userCreated: number;
  public dateChanged: string;
  public userChanged: number;
  public author: string;
  public editor: string;
  public gender: string;
  public imageCopyright: string;
  public link: string;
  public imageSpecial: number; // 1 - Jeste specijalna, 0 - nije specijalna
  public fake: boolean;
  public soldOut: boolean;


  constructor(obj: {
      id: number, name_me: string, name_en: string,
      description_me: string, description_en: string,
      product_slug_me: string, product_slug_en: string,
      sifra: string,
      brand_id: number,
      brandName: string,
      brand_category: string,
      price: number,
      sale_price: number,
      image_url: string,
      small_image_url: string,
      image_orientation: string,
      prod_cat_id: number,
      promo_position: number,
      gallery_id: number,
      gender_oriented: string,
      status: string,
      date_created: string,
      user_created: number,
      date_changed: string,
      user_changed: number,
      author: string,
      editor: string,
      sold_out: string,
      fake?: boolean,
  }) {
      const config = new Config();
      this.id = obj.id;
      this.me = {
          name: obj.name_me,
          description: obj.description_me,
          productSlug: obj.product_slug_me
      };
      this.en = {
        name: obj.name_en,
        description: obj.description_en,
        productSlug: obj.product_slug_en
      };
      this.sifra = obj.sifra,
      this.brand = { id: obj.brand_id, name: obj.brandName, brandCategory: obj.brand_category};
      this.price = obj.price;
      this.salePrice = obj.sale_price;
      this.category = obj.prod_cat_id;
      if(!obj.fake) {
        this.dateCreated = formatDate(obj.date_created, 'dd.MM.yyyy HH:mm', 'en');
        this.dateChanged = formatDate(obj.date_changed, 'dd.MM.yyyy HH:mm', 'en');
      } else {
        this.dateCreated = obj.date_created;
        this.dateChanged = obj.date_changed;
      }
      

      this.userCreated = obj.user_created;
      this.status = obj.status;
      this.userChanged = obj.user_changed;
      this.promoPosition = obj.promo_position;
      this.galleryId = obj.gallery_id;
      this.gender = obj.gender_oriented;
      this.author = obj.author;
      this.editor = obj.editor;
      this.soldOut = obj.sold_out === 'D';

      if (!obj.fake) {
        if(obj.image_url.indexOf('http://') > -1) {

          this.imageUrl = obj.image_url;
          this.smallImageUrl = obj.small_image_url;
          this.fake = false;
        } else {

          this.imageUrl = config.imgUrl + obj.image_url;
          this.smallImageUrl = config.imgUrl + obj.small_image_url;
          this.fake = false;
        }
      } else {
        this.imageUrl = obj.image_url;
        this.smallImageUrl = obj.small_image_url;
        this.fake = true;
      }
      this.imageOrientation = obj.image_orientation;
      this.link = '/admin/product/' + this.id;


  }

  showAppropriateLangData(lang: string) {
      if (lang === 'me') {
          this.name = this.me.name;
          this.description = this.me.description;
          this.productSlug = this.me.productSlug;
      } else {
        this.name = this.en.name;
        this.description = this.en.description;
        this.productSlug = this.en.productSlug;
      }
    }
}