<div class="invisible-modal-trigger" #modalTriggerBloodSupply routerLinkActive="active" 
      data-toggle="modal" data-target="#bloodSupplyModal">
</div>

<div class="modal fade" #bloodSupplyModal id="bloodSupplyModal" tabindex="-1" role="dialog" 
  aria-labelledby="bloodSupplyModal" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="bloodSupplyModalLabel">{{ 'supplyState' | translate }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="f-r bags-container">
              <app-blood-supply-animated *ngFor="let group of bloodGroups" [groupName]="group.name" [supplyLevel]="group.level"></app-blood-supply-animated>
            </div>
          </div>
        </div>
      </div>
</div>