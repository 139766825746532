import { formatDate } from '@angular/common';
import { Config } from '../../../Config';


export class SiteArticle {
    // tslint:disable-next-line:variable-name
    public id: number;
    public title: string;
    public subtitle: string;
    public text: string;
    public me: {
        title: string,
        subtitle: string,
        text: string
    };
    public en: {
        title: string,
        subtitle: string,
        text: string
    };
    // tslint:disable-next-line:variable-name
    public post_date: string;
    public category: number;
    // tslint:disable-next-line:variable-name
    public post_author: number;
    public status: string;
    // tslint:disable-next-line:variable-name
    public user_last_changed: number;
    // tslint:disable-next-line:variable-name
    public last_change_time: string;
    public promoPosition: number;

    public author: string;
    public editor: string;

    public visualType: string;
    public videoUrl: any;
    public embeddedYoutubeUrl: string;
    public imageUrl: string;
    public smallImageUrl: string;
    public imageCopyright: string;
    public imageOrientation: string;
    public link: string;
    public postNameMe;
    public postNameEn;
    public show_date: boolean;


    constructor(obj: {
                    id: number, title_me: string, title_en: string,
                    subtitle_me: string, subtitle_en: string,
                    text_me: string, text_en: string,
                    created_at: string,
                    post_category: number,
                    status: boolean,
                    created_by: any,
                    updated_by: any,
                    updated_at: string,
                    position: any,
                    visual_type: string,
                    video_url: any,
                    image_url: any,
                    image_copyright: string,
                    image_orientation: string,
                    post_name_me?: string,
                    post_name_en?: string,
                    show_date?: boolean
                }) {
        const config = new Config();
        this.id = obj.id;
        this.me = {
            title: obj.title_me,
            subtitle: obj.subtitle_me,
            text: obj.text_me
        };
        this.en = {
            title: obj.title_en,
            subtitle: obj.subtitle_en,
            text: obj.text_en
        };
        this.post_date = formatDate(obj.created_at, 'dd.MM.yyyy HH:mm', 'en');
        this.category = obj.post_category;
        // this.post_author = obj.created_by.id;
        this.status = obj.status ? 'D' : 'N';
        // this.user_last_changed = obj.updated_by.id;
        this.last_change_time = formatDate(obj.updated_at, 'dd.MM.yyyy HH:mm', 'en');
        this.promoPosition = obj.position != null && obj.position !== undefined ? obj.position.id : 1;
        // this.author = obj.created_by.firstname + ' ' + obj.created_by.lastname;
        // this.editor = obj.updated_by.firstname + ' ' + obj.updated_by.lastname;
        this.visualType = obj.visual_type === 'Slika' ? 'I' : 'V';
        if(obj.visual_type === 'Video') {
          this.videoUrl = 'https://admin.ztkcg.me' + obj.video_url.url;
        }
        this.show_date = obj.show_date;
        // if(!obj.image_url.formats){
        //   console.log(obj.id);
        //   console.log(obj.title_me);
        //   console.log(obj.image_url);
        //   console.log('-------------------------------------')
        // }
        if(obj.image_url != null && obj.image_url !== undefined) {
          if(obj.image_url.url.indexOf('http://') > -1) {
            this.imageUrl = obj.image_url.url;
          } else {
            if(obj.image_url.formats.medium != null && obj.image_url.formats.medium !== undefined) {
              this.imageUrl = config.imgUrl + obj.image_url.formats.medium.url;
            } else {
              this.imageUrl = config.imgUrl + obj.image_url.formats.small.url;
            }
            this.smallImageUrl = config.imgUrl + obj.image_url.formats.thumbnail.url;
          }
        } else {
          this.imageUrl = 'https://admin-ztkcg.darujkrv.me/uploads/transfuzija_CG_f9a8c8e641.jpg';
        }
        this.imageCopyright = obj.image_copyright;
        this.imageOrientation = obj.image_orientation;
        this.postNameMe = obj.post_name_me;
        this.postNameEn = obj.post_name_en;


    }

    showAppropriateLangData(lang: string) {
        if (lang === 'me') {
            this.title = this.me.title;
            this.subtitle = this.me.subtitle;
            this.text = this.me.text;
            this.link = '/clanak/' + this.id + '/' + this.postNameMe;

        } else {
            this.title = this.en.title;
            this.subtitle = this.en.subtitle;
            this.text = this.en.text;
            this.link = '/clanak/' + this.id + '/' + this.postNameEn;

        }
      }
}
