<a class="no-decor" [routerLink]="'/' + translate.currentLang + article.link">
  <div class="article-item-wrapper f-c">
    
    <div *ngIf="article.visualType === 'I'" class="f-c image-wrapper {{ article.imageOrientation === 'V' ? 'vertical' : 'horizontal' }}" [ngStyle]="{'background-image': 'url(' + article.imageUrl + ')'}">
    </div>

    <div *ngIf="article.visualType === 'V'" class="f-c image-wrapper {{ article.imageOrientation === 'V' ? 'vertical' : 'horizontal' }}">
      <video [src]="article.videoUrl" style="height: 100%;" controls></video>
    </div>

    <div class="white-box p-3">
      <div class="title-cont">
        <h4 class="fs-16 bt">{{ article.title }}</h4>
      </div>

      
      <div class="date-cont-mobile">
        <span class="fs-11">{{ article.post_date }}</span>
      </div>

      <div class="link-cont mv-12">
          <a class="text-underline red-link" [routerLink]="article.link">
            <span>{{ 'readMore' | translate }}</span>
          </a>

          <div class="date-cont">
            <span class="fs-11">{{ article.post_date }}</span>
          </div>
      </div>
    </div>
    
  </div>

  </a>