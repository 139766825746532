import { Injectable } from '@angular/core';
import { Subscription, forkJoin, Observable, of } from 'rxjs';
import { Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Article } from '../../models/article.model';
import { TranslateService } from '@ngx-translate/core';
import { ArticleService } from '../article.service';
import { map, catchError } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class ArticleResolverService {

  constructor(private router: Router,
              private translate: TranslateService,
              private articleService: ArticleService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if(route.url[0].path === 'post') {
      return this.articleService.getArticleById( +route.params['id'], true );
    } else {
      return this.articleService.getArticleById( +route.params['id'], false );
    }
   
  }
}
