<div *ngIf="errorExists" class="page-error">
  <div class="alert alert-danger">
      {{ 'pageNotAvailable' | translate }}
  </div>
</div>
<div class="article-component-container pager">
  <div class="article-header mv-24">
      <h1 class="article-title text-center">
         {{  'promo-material' | translate }}
      </h1>
  </div>

  <div class="article-content">   
    <div *ngFor="let mat of materials" class="promo-cont f-c justify-content-center align-items-center">
      <h3 class="rem-1-25 title mb-12"> {{ currentLang === 'me' ? mat.Naslov : mat.naslov_en }}</h3>
      <video class="mb-48" controls>
        <source src="{{ config.imgUrl + mat.video.url }}" type="video/mp4">
      </video>
    </div>      
  </div>

  <div class="p-2 mv-24"></div>
  
</div>

