import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { HomeTestComponent } from './home-test/home-test.component';
import { CultureComponent } from './culture/culture.component';
import { ManifestationsComponent } from './manifestations/manifestations.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { AppComponent } from './app.component';
import { ContactComponent } from './contact/contact.component';
import { ArticleResolverService } from './shared/services/resolvers/article-resolver.service';
import { ArticleComponent } from './article/article.component';
import { AboutComponent } from './about-us/about/about.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { BlogComponent } from './blog/blog.component';
import {CategorySectionResolverService } from './shared/services/resolvers/category-section-resolver.service';
import { LoremIpsumComponent } from './lorem-ipsum/lorem-ipsum.component';
import { HistoryPageComponent } from './pages/history-page/history-page.component';
import { AboutInstitutePageComponent } from './pages/about-institute-page/about-institute-page.component';
import { DocumentsPageComponent } from './pages/documents-page/documents-page.component';
import { NewsPageComponent } from './pages/news-page/news-page.component';
import { LanguageResolverService } from './shared/services/resolvers/language-resolver.service';
import { ManagementPageComponent } from './pages/management-page/management-page.component';
import { DirectorsBoardPageComponent } from './pages/directors-board-page/directors-board-page.component';
import { EthicsComiteePageComponent } from './pages/ethics-comitee-page/ethics-comitee-page.component';
import { MedicalComiteeComponent } from './pages/medical-comitee/medical-comitee.component';
import { ExtraWorkComponent } from './pages/extra-work/extra-work.component';
import { StructureAndActivityPageComponent } from './pages/structure-and-activity-page/structure-and-activity-page.component';
import { WhyDonateBloodPageComponent } from './pages/why-donate-blood-page/why-donate-blood-page.component';
import { WhereAndWhenToDonatePageComponent } from './pages/where-and-when-to-donate-page/where-and-when-to-donate-page.component';
import { QuestionnairePageComponent } from './pages/questionnaire-page/questionnaire-page.component';
import { GuideForDonorsPageComponent } from './pages/guide-for-donors-page/guide-for-donors-page.component';
import { AboutBloodPageComponent } from './pages/about-blood-page/about-blood-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { QualityPolicyPageComponent } from './pages/quality-policy-page/quality-policy-page.component';
import { PromoMaterialComponent } from './pages/promo-material/promo-material.component';
import { DarujkrvMeComponent } from './pages/darujkrv.me/darujkrv.me.component';




const routes: Routes = [
  {path: '', component: MainLayoutComponent, children: [
    {path: '', component: HomeComponent, resolve: {language: LanguageResolverService}},
    // {path: ':language/home', component: HomeComponent, resolve: {language: LanguageResolverService}},
    {path: ':language/home', component: HomeTestComponent, resolve: {language: LanguageResolverService}},
    {path: ':language/clanak/:id/:postName', component: ArticleComponent,
     resolve: {article: ArticleResolverService, language: LanguageResolverService}},
    // tslint:disable-next-line: max-line-length
    // {path: ':language/events', component: ManifestationsComponent},
    // {path: ':language/blog', component: BlogComponent},
    // {path: ':language/about-us', component: AboutUsComponent},
    // {path: ':language/about', component: AboutComponent},
    {path: ':language/contact', component: ContactComponent, resolve: {language: LanguageResolverService}},
    // {path: ':language/lorem-ipsum', component: LoremIpsumComponent},
    {path: ':language/istorijat', component: HistoryPageComponent, resolve: {language: LanguageResolverService}},
    {path: ':language/o-zavodu', component: AboutInstitutePageComponent, resolve: {language: LanguageResolverService}},
    {path: ':language/dokumenti', component: DocumentsPageComponent, resolve: {language: LanguageResolverService}},
    {path: ':language/novosti', component: NewsPageComponent, resolve: {language: LanguageResolverService}},
    {path: ':language/novosti/stranica/:page', component: NewsPageComponent, resolve: {language: LanguageResolverService}},
    {path: ':language/menadzment', component: ManagementPageComponent, resolve: {language: LanguageResolverService}},
    {path: ':language/board-of-directors', component: DirectorsBoardPageComponent, resolve: {language: LanguageResolverService}},
    {path: ':language/ethics-comitee', component: EthicsComiteePageComponent, resolve: {language: LanguageResolverService}},
    {path: ':language/medical-comitee', component: MedicalComiteeComponent, resolve: {language: LanguageResolverService}},
    {path: ':language/dopunski-rad', component: ExtraWorkComponent, resolve: {language: LanguageResolverService}},
    {path: ':language/struktura-i-djelatnost', component: StructureAndActivityPageComponent, resolve: {language: LanguageResolverService}},
    {path: ':language/zasto-davati-krv', component: WhyDonateBloodPageComponent, resolve: {language: LanguageResolverService}},
    {path: ':language/lokacije', component: WhereAndWhenToDonatePageComponent, resolve: {language: LanguageResolverService}},
    {path: ':language/upitnik', component: QuestionnairePageComponent, resolve: {language: LanguageResolverService}},
    {path: ':language/vodic-za-davaoce', component: GuideForDonorsPageComponent, resolve: {language: LanguageResolverService}},
    {path: ':language/o-krvi', component: AboutBloodPageComponent, resolve: {language: LanguageResolverService}},
    {path: ':language/daruj-krv', component: DarujkrvMeComponent, resolve: {language: LanguageResolverService}},
    {path: ':language/faq', component: FaqPageComponent, resolve: {language: LanguageResolverService}},
    {path: ':language/politika-organizacije', component: QualityPolicyPageComponent, resolve: {language: LanguageResolverService}},
    {path: ':language/promo-materijal', component: PromoMaterialComponent, resolve: {language: LanguageResolverService}},



  ]},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
