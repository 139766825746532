<!-- <app-desktop-navigation></app-desktop-navigation> -->
<div class="top-menu w-100 f-c">
    <app-dark-stripe></app-dark-stripe>
    <app-navigation></app-navigation>
</div>

<div class="w-100 site-content">
    <router-outlet></router-outlet>
</div>
<div class="footer-cont">
    <app-footer></app-footer>
</div>
<div class="modals">
    <app-blood-supplies-modal></app-blood-supplies-modal>
</div>

<!-- <app-my-cart-modal></app-my-cart-modal> -->


