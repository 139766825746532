import { Injectable, EventEmitter } from '@angular/core';
import { AuthService } from './auth.service';
import { ShoppingCartService } from './shopping-cart.service';
import { DataService } from './data.service';
import { CartItem } from '../models/cart-item.model';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private orderItems: CartItem[] = [];
  private total: number;
  public itemsFetched = new EventEmitter<boolean>();
  public shippingData: any;

  

  constructor(private auth: AuthService,
              private cart: ShoppingCartService,
              private dataService: DataService) { }

  fetchCartFromDb() {
    const obj = {};

    this.dataService.putAnyData('cart', obj).subscribe((response: any) => {
      if(response.code === 200) {
        this.orderItems = [];
        response.data.forEach(element => {
          this.orderItems.push(new CartItem({id: element.id, product: JSON.parse(element.product), 
            size: element.size, quantity: element.quantity,
            price_at_time: element.price_at_time, total_at_time: element.total_at_time
          }));
        });

        this.calculateTotal();
        this.itemsFetched.emit(true);
      }
    },
    (err) => {
      // //  console.log('Greška u učitavanju korpe');
      // //  console.log(err);
    });
  }

  calculateTotal() {
    this.total = 0;
    this.orderItems.forEach((el) => {
      this.total += Math.round(el.priceAtTime * el.quantity * 100) / 100;
    });
  }

  getOrderItems() {
    return this.orderItems;
  }

  getTotal() {
    return this.total;
  }


}
