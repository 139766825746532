import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from '../../shared/services/lang.service';

@Component({
  selector: 'app-structure-and-activity-page',
  templateUrl: './structure-and-activity-page.component.html',
  styleUrls: ['./structure-and-activity-page.component.css']
})
export class StructureAndActivityPageComponent implements OnInit {
  currentLang: string;

  constructor(private langService: LangService,
              private translate: TranslateService) { 

              }

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
    this.langService.languageChanged.subscribe((lang) => {
      this.currentLang = lang;
    });
  }
}
