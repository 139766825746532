import {OnInit, HostListener, PLATFORM_ID, Inject, Injectable, EventEmitter  } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { MenuOption } from '../models/menu-option.model';

@Injectable()
export class StorageService {

  // _platformId je mozda i nepotreban ali provjerava da li je dostupan browser
  // cisto osigurava da postoji sessionStorage kad proba da ga dobije
  // ovo je u starijim angularima moralo sad mozda vise ne mora
  public selectedOption = new EventEmitter<MenuOption>();

  constructor( @Inject(PLATFORM_ID) private _platformId: Object ) { }

  sessionGet(key: string) {
    if (isPlatformBrowser(this._platformId)) {
      const inSessionValue = JSON.parse(sessionStorage.getItem(key));
      return inSessionValue ? inSessionValue : null;
    }
  }

  sessionSet(key: string, value: any): void {
    if (isPlatformBrowser(this._platformId)) {
      sessionStorage.setItem(key, JSON.stringify(value !== undefined ? value : 0));
    }
  }

  sessionDelete(key: string) {
    if (isPlatformBrowser(this._platformId)) {
      sessionStorage.removeItem(key);
    }
  }

    localGet(key: string) {
      if (isPlatformBrowser(this._platformId)) {
        if (localStorage.getItem(key) == null || localStorage.getItem(key) === undefined) {
          return 0;
        }
        const inLocalValue = JSON.parse(localStorage.getItem(key));
        return inLocalValue ? inLocalValue : null;
      }
    }

    localSet(key: string, value: any): void {
      if (isPlatformBrowser(this._platformId)) {
        localStorage.setItem(key, JSON.stringify(value));
      }
    }

}