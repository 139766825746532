<div class="pager">
    <div class="well well-sm">
        <div class="container contact text-center">
            <div class="row">
                <div class="col-sm-12 col-lg-12">
                    <div class="col-sm-12 col-lg-12">
                        <div class="page-title mv-12 rem-1-25">
                            {{ 'contact' | translate }}
                        </div>
                    </div>
                </div>   
            </div>
        </div>
    </div>

    <div class="container contact text-center mb-128">
        <div class="row">
            <div class="w-100">
                <div class="well well-sm">
                    <form [formGroup]="contactForm"  (ngSubmit)="sendMail(contactForm.value)" class="mb-0">
                    <div class="row f-c justify-content-center align-items-center">
                        <div class="col-md-8 col-lg-8 col-xs-8">
                            <!-- <div class="form-group">
                                <label for="name">
                                    {{ 'firstName' | translate }}</label>
                                <input type="text" class="form-control rem-1-25" id="name"  [placeholder]="'firstName' | translate" required="required" />
                            </div> -->
                            <div class="form-group">
                                <label for="cf_name" >{{ 'fullName' | translate }}</label>
                                <input type="text" class="form-control rem-1-25" formControlName="fullName" id="cf_name" 
                                [placeholder]="'fullNamePlaceholder' | translate ">
                            </div>
                            <div class="form-group">
                                <label for="cf_email" >{{ 'email' | translate }}</label>
                                <input type="text"  class="form-control rem-1-25"  formControlName="email" id="cf_email"
                                [placeholder]="'emailPlaceholder' | translate ">
                            </div>
                            <div class="form-group">
                                <label for="cf_phone">{{ 'phone' | translate }}</label>
                                <input type="text"  class="form-control rem-1-25"  formControlName="phone" id="cf_phone"
                                [placeholder]="'phone' | translate ">
                            </div>
                        </div>

                        <div class="col-md-8 col-lg-8 col-xs-8">
                            <div class="form-group">
                                <textarea name="message" id="message" class="form-control rem-1-25" rows="5" cols="25"
                                formControlName="message"
                                [placeholder]="'yourMessage' | translate"></textarea>
                            </div>
                        </div>

                        <div class="col-md-8 col-lg-8 col-xs-8 contact-button mv-24">
                            <button type="submit" class="btn btn-slider" id="btnContactUs">
                                {{ 'send' | translate }}</button>
                        </div>

                        <div class="col-md-8 col-lg-8 col-xs-8 mt-5">
                            <ul class="contact-list rem-1-25 mb-24">
                                <li>ZAVOD ZA TRANSFUZIJU KRVI CRNE GORE
                                <li>Džona Džeksona bb, Podgorica
                                <li>Tel: +382/20 – 240 - 715
                                <li>Mail: <a class="bt" href="mailto:info@ztkcg.me" target="_blank">info@ztkcg.me</a>
                            </ul>
                        </div>

                        <div class="col-md-8 col-lg-8 col-xs-8 text-center">
                            <app-loading-spinner-circle *ngIf="inTheProcess"></app-loading-spinner-circle>
                        </div>

                        <div class="col-md-8 col-lg-8 col-xs-8">
                            <div *ngIf="formWasSubmitted && actionSuccessfull" 
                            class="col-md-8 col-lg-8 col-xs-8 alert alert-success animated fadeIn text-center">
                                {{ lastResult.message }}
                            </div>

                            <div *ngIf="formWasSubmitted && actionFailed" 
                            class="col-md-8 col-lg-8 col-xs-8 alert alert-danger animated fadeIn text-center">
                                {{ lastResult.message }}
                            </div>
                        </div>                    
                        
                        
                    </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>