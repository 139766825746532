import { Config } from '../../../Config';

export class Brand {
  public id: number;
  public name: string;
  public imageUrl: string;
  public gender: string;

  constructor(obj: {id: number, name: string, image_url: string, gender_oriented: string}) {
    const config = new Config();
    this.id = obj.id;
    this.name = obj.name;
    this.imageUrl = obj.image_url;
    this.gender = obj.gender_oriented;
  }
}