import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { PaginationFooterComponent } from './components/pagination-footer/pagination-footer.component';
import { QuillModule } from './mocks/ngx-quill-module.mocks';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';  -- zrelo za micanje
import { UiSwitchModule } from 'ngx-ui-switch';
import { NotificationBoxComponent } from './components/notification-box/notification-box.component';
import { ImageLoaderComponent } from './components/image-loader/image-loader.component';
import { LoadingSpinnerCircleComponent } from './components/loading-spinner-circle/loading-spinner-circle.component';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { LocalTimePipe } from './pipes/local-time.pipe';
import { DarkStripeComponent } from './components/dark-stripe/dark-stripe.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { CartElementComponent } from './components/cart-element/cart-element.component';
import { SizeTableComponent } from './components/size-table/size-table.component';
import { GoogleMapComponent } from './components/google-map/google-map.component';
import { AgmMap } from '@agm/core';
import { BloodSupplyAnimatedComponent } from './components/blood-supply-animated/blood-supply-animated.component';






@NgModule({
  declarations: [
    PaginationFooterComponent,
    NotificationBoxComponent,
    ImageLoaderComponent,
    LoadingSpinnerCircleComponent,
    LocalDatePipe,
    LocalTimePipe,
    DarkStripeComponent,
    CartElementComponent,
    SizeTableComponent,
    BloodSupplyAnimatedComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    QuillModule,
    HttpClientModule,
    // CKEditorModule,
    UiSwitchModule,
    AutocompleteLibModule,
    AngularMyDatePickerModule
  ],
  exports: [
    PaginationFooterComponent,
    // CKEditorModule,
    UiSwitchModule,
    NotificationBoxComponent,
    ImageLoaderComponent,
    BloodSupplyAnimatedComponent,
    LoadingSpinnerCircleComponent,
    SizeTableComponent,
    CartElementComponent,
    DarkStripeComponent,
    LocalDatePipe,
    LocalTimePipe,
    AutocompleteLibModule,
    AngularMyDatePickerModule
    
  ]
})
export class SharedModule { }