export class DocumentType {
  id: number;
  nameMe: string;
  nameEn: string;

  constructor(obj: {
    id: number,
    naziv: string,
    naziv_eng: string,
  }) {
    this.id = obj.id;
    this.nameMe = obj.naziv;
    this.nameEn = obj.naziv_eng;
    
  }
}