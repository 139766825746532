
<section class="w-100 f-c align-items-center">
  <div class="col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-11">
    <div class="map-segment pt-2 mt-2 mb-48">
      <div class="map-title mb-48 text-center">
        <h3 class="rem-1-55">{{ 'whenAndWhereToDonate' | translate }}</h3>
      </div>
      <div class="f-r responsive reverse">
        <div class="organisation f1">
          <p class="max-300 rem-1">{{ 'workingTimeInAllUnits' | translate }}</p>
          <table class="mv-12">
            <tr>
              <th>Transfuzijska Ustanova</th>
              <th>Kontakt telefon</th>
            </tr>
            <tr>
              <td>Podgorica</td>
              <td>020 240 715</td>
            </tr>
            <tr>
              <td>Bar</td>
              <td>030/342-212</td>
            </tr>
            <tr>
              <td>Berane</td>
              <td>068/043-111</td>
            </tr>
            <tr>
              <td>Bijelo Polje</td>
              <td>050/486-102</td>
            </tr>
            <tr>
              <td>Cetinje</td>
              <td>041/232-740</td>
            </tr>
            <tr>
              <td>Kotor</td>
              <td>068/047-111</td>
            </tr>
            <tr>
              <td>Nikšić</td>
              <td>068/042-111</td>
            </tr>
            <tr>
              <td>Pljevlja</td>
              <td>068/048-111</td>
            </tr>
            <tr>
              <td>Risan</td>
              <td>032/326-163</td>
            </tr>
          </table>
        </div>
        <div id="map" class="map-container f1"> </div>
      </div>
    </div>
  </div>
</section>

