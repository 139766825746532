<div class="dark-stripe animated f-r
{{ !topVisible && scrolledUp ? 'scrolled-up-menu faster slideInDown' : (!topVisible && !scrolledUp ? 'fadeOutUp' : '') }}">

<div class="inner-stripe f-r col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
  <div class="lang-picker">
    <a class="cursor-pointer right rem-0-95" [ngClass]="{'hover': IfMe()}" (click) ="changeLanguage('me')">ME </a>|
    <a class="cursor-pointer right rem-0-95"  [ngClass]="{'hover': !IfMe()}" (click) ="changeLanguage('en')">EN</a>
  </div>
</div>

</div>