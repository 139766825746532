import { Component, OnInit, Inject, Injector, PLATFORM_ID, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { isPlatformBrowser } from '@angular/common';
import { Config } from '../Config';
import { Subscription } from 'rxjs';
import { LangService } from './shared/services/lang.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  config = new Config();
  currentLang: string;

  constructor(private translate: TranslateService,
              // private cookieService: NgcCookieConsentService,
              private langService: LangService,
              private router: Router,
              private route: ActivatedRoute,
              @Inject(PLATFORM_ID) private readonly platformId: Object,
              private readonly injector: Injector) { }

  ngOnInit() {

    if (this.currentLang == null || this.currentLang === undefined) {
      this.currentLang = 'me';
      this.translate.setDefaultLang('me');
    }

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.langService.languageChanged.subscribe((lang) => {
      this.currentLang = lang;
    });

    // this.langService.languageChanged.subscribe((lang) => {
    //   this.changeCookieText();
    // });

    // let cc = window as any;

    // cc.cookieconsent.initialise({
    //   container: document.getElementById('cookieconsent'),
    //   palette:{
    //     popup: { background: '#1aa3ff' },
    //     button: { background: '#e0e0e0' },
    //   },
    //   revokable: true,
    //   onStatusChange: function(status) {
    //     //  console.log(this.hasConsented() ?
    //     'enable cookies' : 'disable cookies');
    //   },
    //   theme: 'edgeless'
    // });

   }

 
}
