import { Component, OnInit, Input } from '@angular/core';
import { SiteProduct } from '../../../shared/models/site-product.model';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from '../../../shared/services/lang.service';

@Component({
  selector: 'app-rotation-product-item',
  templateUrl: './rotation-product-item.component.html',
  styleUrls: ['./rotation-product-item.component.css']
})
export class RotationProductItemComponent implements OnInit {
  @Input() product: SiteProduct;


  constructor(private translate: TranslateService,
              private langService: LangService) { }

  ngOnInit() {
    this.product.showAppropriateLangData(this.translate.defaultLang);
    this.langService.languageChanged.subscribe((lang) => {
      this.product.showAppropriateLangData(lang);
    })
  }

}
