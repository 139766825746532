import { Component, OnInit } from '@angular/core';
import { ArticleService } from '../shared/services/article.service';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from '../shared/services/lang.service';
import { SiteArticle } from '../shared/models/site-article.model';
import { DataService } from '../shared/services/data.service';

@Component({
  selector: 'app-culture',
  templateUrl: './culture.component.html',
  styleUrls: ['./culture.component.css']
})
export class CultureComponent implements OnInit {

  public cultureArticles: SiteArticle[] = [];
  public currentPage = 1;
  public currentLang: string;


  constructor(private articleService: ArticleService,
              private translate: TranslateService,
              private langService: LangService,
              private dataService: DataService) {
   }

   ngOnInit() {
    this.loadArticles(this.translate.currentLang);
    this.langService.languageChanged.subscribe((lang: string) => {
      this.cultureArticles.forEach(element => {
        element.showAppropriateLangData(lang);
      });
    });
   }

   loadArticles(lang: string) {
    this.currentLang = lang;
    this.articleService.getArticlesByCategory(1, 1, 3).subscribe(
      (rez: any) => {
      // tslint:disable-next-line:prefer-for-of
        if (rez.code === 200) {
          const articles = [];
          if (rez.data.length > 0) {
            for (const element of rez.data) {
              const art = new SiteArticle(element);
              art.showAppropriateLangData(lang);
              articles.push(art);
            }
            this.cultureArticles = articles;
          } else {
            this.dataService.maxPageReached.emit(true);
          }
        } else {
          // //  console.log('Dogodila se greska');
        }
      });
   }

   onPageSelected(pageEvent: number) {
    this.currentPage = pageEvent;
    this.loadArticles(this.currentLang);
  }
}
