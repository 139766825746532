import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { StorageService } from "./storage.service";
import { IfStmt } from '@angular/compiler';

@Injectable()
export class SiteUserInterceptor implements HttpInterceptor {

  constructor(private storageService: StorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const userToken = this.storageService.sessionGet("vsite_user_token");
    
    if(this.isUserRequest(req)){
        if (userToken) {
        const cloned = req.clone({
            headers: req.headers.set("Authorization",
            "Bearer " + userToken)
        });

        return next.handle(cloned);
        } 
    }

    return next.handle(req); 
  }

  isUserRequest(request: HttpRequest<any>) {
    if(request.method.toUpperCase() === "POST" || request.method.toUpperCase() === "PUT" || request.method.toUpperCase() === "DELETE") {
        if(request.url.indexOf("cart") > -1 ||  request.url.indexOf("order") > -1 || request.url.indexOf("my-account") > -1
        || request.url.indexOf("new-password") > -1) {
               return true;
        } else {
          return false;
        }
      } else {
        if(request.url.indexOf("order") > -1 || request.url.indexOf("my-account") > -1 || request.url.indexOf("products") > -1 ||
           request.url.indexOf("promo-codes/search") > -1 ) {
          return true;
        } 
        return false;
    }

  }
}
