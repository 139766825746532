<div class="article-component-container pager">
  <div class="article-header mv-24">
      <h1 class="article-title text-center">
         {{  'ewTitle1' | translate }}
      </h1>
  </div>

  <div class="article-content">

    <div class="employee-box col-12">
      <ul class="no-decor">
        <li>{{ 'ew1' | translate }}</li>
        <li>{{ 'ew2' | translate }}</li>
        <li>{{ 'ew3' | translate }}</li>
        <li>{{ 'ew4' | translate }}</li>
        <li>{{ 'ew5' | translate }}</li>
        <li>{{ 'ew6' | translate }}</li>
      </ul>        
    </div>

    <div class="employee-box col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-5">
      <h3>{{ 'ewTitle2' | translate }}</h3>
      <ul class="no-decor">
        <li>{{ 'ews1' | translate }}</li>
        <li>{{ 'ews2' | translate }}</li>
      </ul>        
    </div>

    <div class="employee-box col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-5">
      <h3>{{ 'ewTitle3' | translate }}</h3>
      <ul class="no-decor">
        <li>{{ 'ewt1' | translate }}</li>
        <li>{{ 'ewt2' | translate }}</li>
      </ul>        
    </div>

    <div class="employee-box col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-5">
      <h3>{{ 'ewTitle4' | translate }}</h3>      
    </div>

  </div>

  
</div>

