import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localTime'
})
export class LocalTimePipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    // value should be in format of 24HH:MI, example 18:45 means 6:45 pm
    const ar = value.split(':');
    const lang = args[0];
    if (ar != null && ar !== undefined && ar.length > 1 ) {
      if (lang === 'me') {
        return ar[0] + ':' + ar[1];
      } else {
        return ar[0] + '.' + ar[1];
      }
    } else {
      return 'N/A';
    }
  }

}
