<div class="article-component-container pager">
  <div class="article-header mv-24">
      <h1 class="article-title text-center">
         {{  'questionnaireForDonors' | translate }}
      </h1>
  </div>

  <div class="article-content">
    <div *ngIf="currentLang === 'me'" class="article-text me">
      <p>Prije donacije krvi, svi davaoci moraju da ispune Upitnik o podobnosti za doniranje krvi. Upitnik možete popuniti i kod kuće, i donijeti nam ga prilikom posjete.</p>

      <p>Upitnik preuzmite u nastavku:</p>
    </div>

    <div *ngIf="currentLang === 'en'" class="article-text en">
      <p>Prior to blood donation, all donors must complete a Blood Donation Eligibility Questionnaire. You can also fill in the questionnaire at home, and bring it to us when you visit.</p>
      <p>Download the questionnaire below:</p>
    </div>

    <div class="the-link mv-24">
      <a class="fs-18 no-decor doc-link bt" href="{{ config.fileUrl + mneFileUrl }}" target="_blank">{{ 'questionnaireMneFileName' | translate }}</a>
    </div>

    <div class="the-link mv-24">
      <a class="fs-18 no-decor doc-link bt" href="{{ config.fileUrl + enFileUrl }}" target="_blank">{{ 'questionnaireEngFileName' | translate }}</a>
    </div>

  </div>

  <div class="p-2 mv-24"></div>
  
</div>

