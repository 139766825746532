import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationWindowService {
  public messageEvent = new EventEmitter<{message: string, status: boolean}>();

  constructor() { }

  showNotification(ntf: {message: string, status: boolean}) {
    this.messageEvent.emit(ntf);
  }
}
