import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from '../../shared/services/lang.service';

@Component({
  selector: 'app-about-institute-page',
  templateUrl: './about-institute-page.component.html',
  styleUrls: ['./about-institute-page.component.css']
})
export class AboutInstitutePageComponent implements OnInit {
  currentLang: string;

  constructor(private langService: LangService,
              private translate: TranslateService) { 

              }

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
    this.langService.languageChanged.subscribe((lang) => {
      this.translate.currentLang = lang;
      this.currentLang = lang;
    });
  }

}
