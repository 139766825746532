import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SiteArticle } from '../../shared/models/site-article.model';
import { DataService } from '../../shared/services/data.service';
import { LangService } from '../../shared/services/lang.service';


@Component({
  selector: 'app-news-page',
  templateUrl: './news-page.component.html',
  styleUrls: ['./news-page.component.css']
})
export class NewsPageComponent implements OnInit {
  news: Array<SiteArticle>;
  currentPage = 1;
  errorExists = false;
  errorMessage: string;
  currentLang: string;
  isLoading = false;
  inkrement = 20;
  maxReached = false;
  superMaxReached = false;

  constructor(private dataService: DataService,
              private domSanitizer: DomSanitizer,
              private translate: TranslateService,
              private router: Router,
              private langService: LangService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.currentPage = this.route.snapshot.params.page;
    if(!this.currentPage) {
      this.currentPage = 1;
    }
    //  console.log(this.currentPage);
    this.currentLang = this.translate.currentLang;
    this.loadNews();
    this.langService.languageChanged.subscribe((lang) => {
      this.currentLang = lang;
      this.news.forEach((art) => {
        art.showAppropriateLangData(lang);
      });
    });
  }

  loadNews() {
    this.isLoading = true;
    this.news = [];
    const start = (this.currentPage - 1) * this.inkrement;
    /* ovo me dolje ne utiče na prevod */
    this.dataService.getDataFromUrl('posts?status=1&_start=' + start + '&_limit=' +  this.inkrement  + '&_sort=created_at:DESC').subscribe(
      (rez: any) => {
        // tslint:disable-next-line:prefer-for-of
        //  console.log(rez);
        if (rez instanceof Array && rez.length > 0) {
          const articles = [];
          for (const element of rez) {
            const art = new SiteArticle(element);
            art.videoUrl = this.getYouTubeVideoUrl(art.videoUrl);
            art.showAppropriateLangData(this.translate.currentLang);
            articles.push(art);
          }
          this.news = articles;
          this.isLoading = false;

          if(this.news.length < this.inkrement) {
            this.maxReached = true;
            this.superMaxReached = false;
          } else {
            this.maxReached = false;
            this.superMaxReached = false;
          }

        } else {
          this.maxReached = false;
          this.superMaxReached = true;
          this.errorExists = true;
        }
      });
  }


  getYouTubeVideoUrl(videoUrl: string) {
    let url: string;
    if(videoUrl != null) {
      if (videoUrl.indexOf('&') === -1) {
        url = 'https://www.youtube.com/embed/' + videoUrl.slice(videoUrl.indexOf('?v=') + 3);
      } else {
        url = 'https://www.youtube.com/embed/' + videoUrl.slice(videoUrl.indexOf('?v=') + 3, videoUrl.indexOf('&'));
      }
      return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      return null;
    }
  }

  onPageSelected(pageEvent: number) {
      this.currentPage = pageEvent;
      this.loadNews();
      // this.router.navigate(['novosti/stranica/' + this.currentPage]);
  }

}
