import { Component, Input, OnInit } from '@angular/core';
import { ModalsService } from '../../services/modals.service';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-blood-supply-animated',
  templateUrl: './blood-supply-animated.component.html',
  styleUrls: ['./blood-supply-animated.component.css']
})
export class BloodSupplyAnimatedComponent implements OnInit {
  @Input("supplyLevel") supplyLevel: number;
  @Input("groupName") groupName: string;
  @Input("onHomePage") onHomePage: boolean = false;
  bloodHeight = 0;
  bloodHeightInPixels = '0px';

  constructor(private modalService: ModalsService,
              private dataService: DataService) { }

  ngOnInit() {
    this.modalService.modalBloodSupplies.subscribe((ev) => {
      if (ev) {
        setTimeout(() => {
          this.calculateBloodHeight();
        }, 500);
      }
    });

    if(this.onHomePage) {
      setTimeout(() => {
        this.calculateBloodHeight();
      }, 500);
    }
    
  }

  calculateBloodHeight() {
    if(this.onHomePage){
      this.bloodHeight = this.supplyLevel * 0.8;
    } else {
      this.bloodHeight = this.supplyLevel * 2;
    }
    this.bloodHeightInPixels = this.bloodHeight + 'px';
  }

}
