<ng-container *ngIf="isBrowser === true">
        <agm-map [latitude]="lat" [longitude]="lng" [zoom]="8" [disableDefaultUI]="false"
        [zoomControl]="false">


              <agm-marker-cluster [imagePath]="'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'">

                      <agm-marker *ngFor="let marker of markers" 
                              [title]="marker.title" [latitude]="marker.lat" [longitude]="marker.lng"
                              [iconUrl]="'../../assets/images/samms-marker.png'">
      
                              <agm-info-window [disableAutoPan]="false">
                                      <div class="info-window-cont">
                                              <div class="mb-12">
                                                      <h4>{{ marker.title }}</h4>
                                              </div>
      
                                              <ul class="marker-address-list">
                                                      <li> {{ marker.address }}</li>
                                                      <li> {{ marker.city + ', ' + marker.country }}</li>
                                              </ul>
                                      </div>
                              </agm-info-window>
      
                      </agm-marker>
              </agm-marker-cluster>
</agm-map>
</ng-container>
