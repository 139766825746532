import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from '../../services/data.service';
import { TranslateService } from '@ngx-translate/core';
import { Brand } from '../../../shared/models/brand.model';
import { Router } from '@angular/router';
import { VirtualTimeScheduler } from 'rxjs';

@Component({
  selector: 'app-filter-brands',
  templateUrl: './filter-brands.component.html',
  styleUrls: ['./filter-brands.component.css']
})
export class FilterBrandsComponent implements OnInit {
  @Input('brandSelectedId') brandSelectedId: number;
  @Input('isOnItsPage') isOnItsPage: boolean;
  opened = false;
  brands: Array<Brand> = [];
  @Output('brandSelection') brandSelection = new EventEmitter<number>();

  

  constructor(private dataService: DataService,
              private translate: TranslateService,
              private router: Router) { }

  ngOnInit() {
    // if(this.brandSelectedId > 0) {
    //   this.opened = true;
    // }

    if(this.isOnItsPage) {
      this.opened = true;
    }

    // this.loadBrands();
  }

  loadBrands() {
    this.dataService.getDataFromUrl('brands').subscribe((resp: any) => {
      if (resp.code === 200) {
        const brs = [];
        resp.data.forEach((br: any) => {
          const nb = new Brand(br);
          brs.push(nb);
        });

        this.brands = brs;
      }

    });
  }

  brandSelected(br: Brand) {
    this.brandSelectedId = br.id;
    let link;
    if(this.isOnItsPage) {
      if(br.name.toUpperCase().indexOf('HUGO') > -1) {
        link = '/hugo-boss';
      } else {
        link = '/products/brand/' + br.name.toLowerCase().replace(/\s/g, '-') + '/' + br.id;
      }
      this.router.navigate([link]);
    } else {
      this.brandSelection.emit(br.id);
    }
  }

  toggleSubFilters() {
    this.opened = !this.opened;
  }

}
