import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localDate'
})
export class LocalDatePipe implements PipeTransform {


  transform(value: string, ...args: any[]): any {
    // value should be in format of DD.MM.YYYY
    const ar = value.split('.');
    const lang = args[0];
    if (ar != null && ar !== undefined && ar.length > 2 ) {
      if (lang === 'me') {
        return ar[0] + '.' + ar[1] + '.' + ar[2] + '.';
      } else {
        return ar[0] + '.' + ar[1] + '.' + ar[2];
      }
    } else {
      return 'N/A';
    }
  }

}
