import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollingService {

  public scrolledFromTop = new EventEmitter<boolean>();
  public returnedToTop = new EventEmitter<boolean>();
  public upwardScroll = new EventEmitter<boolean>();

  constructor() { }
}
