<ng-container *ngIf="product != null && product !== undefined">
  <a class="no-decor" [routerLink]="['/product/' + product.id + '/' + product.me.productSlug]">
  <div class="no-outline">
    <div class="product-item-rotation-wrapper f-c f1">
      <div class="image-wrapper f-c">
        <img class="product-rotation-image {{ product.imageOrientation === 'V' ? 'vertical' : 'horizontal' }}"
         [src]="product.imageUrl" alt="{{ product.me.name }}">
      </div>
    
      <div class="product-title rotation">
        <h4 class="rem-1-05 bold">{{ product.brand.name }}</h4>
      </div>
    
      <div class="product-subtitle rotation">
        <h4 class="rem-1-25">{{ product.name }}</h4>
      </div>
    
      <div class="product-button">
        <button class="btn btn-shop-now rem-1" [routerLink]="['/product/' + product.id + '/' + product.me.productSlug]">Shop now</button>
      </div>
    </div>
  </div>
  </a>
</ng-container>
