import { Injectable, EventEmitter } from '@angular/core';
import { GalleryItem } from '../../shared/models/gallery-item.model';

@Injectable({
  providedIn: 'root'
})
export class ModalsService {
  modalSignIn = new EventEmitter<boolean>();
  modalBloodSupplies = new EventEmitter<boolean>();
  modalMyCart = new EventEmitter<boolean>();
  modalRegister = new EventEmitter<boolean>();
  modalSearchWindow = new EventEmitter<boolean>();
  newSearchTriggered = new EventEmitter<string>();
  imageFullSizeModal = new EventEmitter<GalleryItem>();
  menuModal = new EventEmitter<boolean>();
  constructor() { }
}
