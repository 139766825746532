<div *ngIf="errorExists" class="page-error">
  <div class="alert alert-danger">
      {{ (errorMessage != null || errorMessage !== undefined) ? errorMessage : ('noArticlesOnThisPage' | translate) }}
  </div>
</div>
<div class="article-component-container pager">
  <div class="article-header mv-24">
      <h1 class="article-title text-center">
         {{  'news' | translate }}
      </h1>
  </div>

  <div *ngIf="!isLoading" class="news-content">   
    <div class="mf-news" *ngIf="news.length > 0; else noNews">
      <div class="news news-wrap f-r">
        <app-news-preview-item class="multi-row"  *ngFor="let n of news" [article]="n"></app-news-preview-item>
      </div>
    </div>

    <div *ngIf="isLoading" class="loading-cont">
      <app-loading-spinner-circle></app-loading-spinner-circle>
    </div>
  

    <ng-template #noNews>
      <div class="no-articles">
        <p>{{ 'noArticles' | translate }}</p>
      </div>
    </ng-template>

    <div class="custom-spacing">
      <pagination-footer [currentPage]="currentPage"
        (pageChange) = "onPageSelected($event)"
        [totalPageNumber]="currentPage" [maximumReached]="maxReached" [superMaximumReached]="superMaxReached">
      </pagination-footer>
    </div>
      
  </div>

  <div class="p-2 mv-24"></div>
  
</div>

