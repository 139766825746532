import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lorem-ipsum',
  templateUrl: './lorem-ipsum.component.html',
  styleUrls: ['./lorem-ipsum.component.css']
})
export class LoremIpsumComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
