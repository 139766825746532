<div class="filters">
  <div  class="filter-brand-item f-c {{ opened ? 'mxBig' : 'mxNormal' }}">
    <div class="item-title w-100 f-r" (click)="toggleSubFilters()">
      <h3 class="category-title rem-1-15"> {{ 'brands' | translate }} </h3>
      <div class="arrow-icon">
        <span class="fa fa-chevron-down {{ opened ? 'rotated' : '' }}"></span>
      </div>
    </div>

    <ul class="subfilters animated faster fadeIn">
      <li *ngFor="let br of brands">
        <input type="radio" name="brand" [value]="br.id" id="b + {{ br.id }}" [(ngModel)]="brandSelectedId" 
        (ngModelChange)="brandSelected(br)">
        <label for="c + {{ br.id }} " (click)="brandSelected(br)" 
        class="rem-0-95 {{ br.id === brandSelectedId ? 'bold' : '' }}"> {{ br.name }} </label>
      </li>
    </ul>
  </div>
</div>