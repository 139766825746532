<div class="article-component-container pager">
  <div class="article-header mv-24">
      <h1 class="article-title text-center">
         {{  'management' | translate }}
      </h1>
  </div>

  <div class="article-content">

      <div class="employee-box col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-5">
        <h3>{{ 'directoress' | translate }}</h3>
        <div class="f-c">
          <span>mr sci. dr Tamara Scepanović</span>
          <span>tel. 020/240-709</span>
          <span>e-mail: <a class="red no-decor" href="mailto:tamara.scepanovic@ztkcg.me">tamara.scepanovic@ztkcg.me</a></span>
        </div>        
      </div>

      <!-- <div class="employee-box col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-5">
        <h3>{{ 'directoress' | translate }}</h3>
        <div class="f-c">
          <span>Prim. Dr. Gordana Rašović</span>
          <span>tel. 020/240-709</span>
          <span>e-mail: <a class="red no-decor" href="mailto:gordana.rasovic@ztkcg.me">gordana.rasovic@ztkcg.me</a></span>
        </div>        
      </div> -->

      <div class="employee-box col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-5">
        <h3>{{ 'directoressHelper' | translate }}</h3>
        <div class="f-c">
          <span>Dipl.ecc Mirjana Đukić</span>
          <span>tel: 020/240-723</span>
          <span>e-mail: <a class="red no-decor" href="mailto:mirjana.djukic@ztkcg.me">mirjana.djukic@ztkcg.me</a></span>
        </div>        
      </div>

      <div class="employee-box col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-5">
        <h3>{{ 'mainTehnician' | translate }}</h3>
        <div class="f-c">
          <span>teh. Rajko Nenezić</span>
          <span>tel: 068/827-917</span>
          <span>e-mail: <a class="red no-decor" href="mailto:petar.maras@ztkcg.me">rajko.nenezic@ztkcg.me</a></span>
        </div>        
      </div>

      <div class="employee-box col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-5">
        <h3>{{ 'financeAccountantSupplyOffice' | translate }}</h3>
        <div class="f-c">
          <span>Dipl.ecc  Ljiljana Vlahović</span>
          <span>tel: 020/240-708</span>
          <span>e-mail: <a class="red no-decor" href="mailto:ljiljana.vlahovic@ztkcg.me">ljiljana.vlahovic@ztkcg.me</a></span>
        </div>        
      </div>

      <div class="employee-box col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-5">
        <h3>{{ 'supplyOfficer' | translate }}</h3>
        <div class="f-c">
          <span>Dipl.ecc Mirjana Đukić</span>
          <span>tel: 020/240-723</span>
          <span>e-mail: <a class="red no-decor" href="mailto:mirjana.djukic@ztkcg.me">mirjana.djukic@ztkcg.me</a></span>
        </div>     
      </div>


      <div class="employee-box col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-5">
        <h3>{{ 'legalPublicAndHrOffice' | translate }}</h3>
        <div class="f-c">
          <span>Dipl. pr. Anica Grujić</span>
          <span>Dipl. pr. Svetlana Vukašinović</span>
          <span>tel: 020/240-727</span>
          <span>e-mail: <a class="red no-decor" href="mailto:anica.grujic@ztkcg.me">anica.grujic@ztkcg.me</a></span>
          <span>e-mail: <a class="red no-decor" href="mailto:svetalana.vukasnovic@ztkcg.me">svetalana.vukasnovic@ztkcg.me</a></span>
        </div>        
      </div>

      <div class="employee-box col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-5">
        <h3>{{ 'personToContactWhistler' | translate }}</h3>
        <div class="f-c">
          <span>Dipl. pr. Svetlana Vukašinović</span>
          <span>tel: 020/240-727</span>
          <span>e-mail: <a class="red no-decor" href="mailto:svetalana.vukasnovic@ztkcg.me">svetalana.vukasnovic@ztkcg.me</a></span>
        </div>        
      </div>

      <div class="employee-box col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-5">
        <h3>{{ 'officeTenicalJobsAndItSupport' | translate }}</h3>
        <div class="f-c">
          <span>inz. Velimir Lazrević</span>
          <span>inz. Zoran Boškovic</span>
          <span>tel: 020/240-203</span>
          <span>e-mail: <a class="red no-decor" href="mailto:velimir.lazarevic@ztkcg.me">velimir.lazarevic@ztkcg.me</a></span>
          <span>e-mail: <a class="red no-decor" href="mailto:zoran.boskovic@ztkcg.me">zoran.boskovic@ztkcg.me</a></span>
        </div>        
      </div>

      
      <div class="employee-box col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-5">
        <h3>{{ 'ITSupport' | translate }}</h3>
        <div class="f-c">
          <span>mr Mirko Kuzman</span>
          <span>tel: 020/240-731</span>
          <span>e-mail: <a class="red no-decor" href="mailto:mirko.kuzman@ztkcg.me">mirko.kuzman@ztkcg.me</a></span>
        </div>        
      </div>

      <div class="employee-box col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-5">
        <h3>{{ 'organisationUnitNiksic' | translate }}</h3>
        <div class="f-c">
          <span>dr Dragica Janičić</span>
          <span>teh. Veselin Gezović</span>
          <span>tel: 067/173-537</span>
          <span>tel: 067/345-821</span>
          <span>e-mail: <a class="red no-decor" href="mailto:dragica.janicic@ztkcg.me">dragica.janicic@ztkcg.me</a></span>
        </div>     
      </div>


      <div class="employee-box col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-5">
        <h3>{{ 'organisationUnitBar' | translate }}</h3>
        <div class="f-c">
          <span>dr Dragan Radonjić</span>
          <span>teh. Ibrahim Čobić</span>
          <span>tel: 030/342-212</span>
          <span>e-mail: <a class="red no-decor" href="mailto:dragan.radonjic@ztkcg.me">dragan.radonjic@ztkcg.me</a></span>
          <span>e-mail: <a class="red no-decor" href="mailto:ibrahim.cobic@ztkcg.me">ibrahim.cobic@ztkcg.me</a></span>
        </div>     
      </div>

      <div class="employee-box col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-5">
        <h3>{{ 'organisationUnitBerane' | translate }}</h3>
        <div class="f-c">
          <span>dr Budmir Ivanović</span>
          <span>teh. Branka Radunović</span>
          <span>tel: 067/173-544</span>
          <span>tel: 067/173-545</span>
          <span>e-mail: <a class="red no-decor" href="mailto:budimir.ivanovic@ztkcg.me">budimir.ivanovic@ztkcg.me</a></span>
          <span>e-mail: <a class="red no-decor" href="mailto:branka.radunovic@ztkcg.me">branka.radunovic@ztkcg.me</a></span>
        </div>     
      </div>

      <div class="employee-box col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-5">
        <h3>{{ 'organisationUnitBijeloPolje' | translate }}</h3>
        <div class="f-c">
          <span>dr Dušanka Miladinović </span>
          <span>teh. Siniša Jokić</span>
          <span>tel: 050/486-102</span>
          <span>e-mail: <a class="red no-decor" href="mailto:dusanka.miladinovic@ztkcg.me">dusanka.miladinovic@ztkcg.me</a></span>
          <span>e-mail: <a class="red no-decor" href="mailto:sinisa.jokic@ztkcg.me">sinisa.jokic@ztkcg.me</a></span>
        </div>     
      </div>

      <div class="employee-box col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-5">
        <h3>{{ 'organisationUnitPljevlja' | translate }}</h3>
        <div class="f-c">
          <span>dr Ikonija Borović</span>
          <span>teh. Lela Gogić</span>
          <span>tel: 067/173-555</span>
          <span>tel: 067/173-556</span>
          <span>e-mail: <a class="red no-decor" href="mailto:ikonija.borovic@ztkcg.me">ikonija.borovic@ztkcg.me</a></span>
          <span>e-mail: <a class="red no-decor" href="mailto: lela.gogic@ztkcg.me"> lela.gogic@ztkcg.me</a></span>
        </div>     
      </div>

      <div class="employee-box col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-5">
        <h3>{{ 'organisationUnitKotor' | translate }}</h3>
        <div class="f-c">
          <span>dr Snežana Filipović</span>
          <span>teh. Dušanka Kovačević</span>
          <span>tel: 067/173-565</span>
          <span>tel: 067/173-566</span>
          <span>e-mail: <a class="red no-decor" href="mailto:snezana.filipovic@ztkcg.me">snezana.filipovic@ztkcg.me</a></span>
          <span>e-mail: <a class="red no-decor" href="mailto:dusanka.kovacevic@ztkcg.me">dusanka.kovacevic@ztkcg.mee</a></span>
        </div>     
      </div>

      <div class="employee-box col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-5">
        <h3>{{ 'organisationUnitCetinje' | translate }}</h3>
        <div class="f-c">
          <span>dr Dragan Gazivoda</span>
          <span>teh. Jovan Jovanović</span>
          <span>tel: 041/232-740</span>
          <span>e-mail: <a class="red no-decor" href="mailto:dragan.gazivoda@ztkcg.me">dragan.gazivoda@ztkcg.me</a></span>
          <span>e-mail: <a class="red no-decor" href="mailto:jovan.jovanovic@ztkcg.me">jovan.jovanovic@ztkcg.me</a></span>
        </div>     
      </div>

      <div class="employee-box col-xs-12 col-sm-12 col-md-8 col-lg-5 col-xl-5">
        <h3>{{ 'organisationUnitRisan' | translate }}</h3>
        <div class="f-c">
          <span>dr Lucija Virijević-Milić</span>
          <span>teh. Novak Milić</span>
          <span>tel: 032/326-163</span>
          <span>e-mail: <a class="red no-decor" href="mailto:lucija.milic@ztkcg.me">lucija.milic@ztkcg.me</a></span>
          <span>e-mail: <a class="red no-decor" href="mailto:novak.milic@ztkcg.me">novak.milic@ztkcg.me</a></span>
        </div>     
      </div>

  </div>

  
</div>

