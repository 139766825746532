<div class="article-component-container pager">
  <div class="article-header mv-24">
      <h1 class="article-title text-center">
         {{  'guideForDonors' | translate }}
      </h1>
  </div>

  <div class="article-content">
    
  
    <div *ngIf="currentLang === 'en'" id="accordionEn">
      <div class="card">
        <div class="card-header" id="enHeadingOne">
          <span class="mb-0 question-cont">
            <button class="btn btn-link" data-toggle="collapse" data-target="#enCollapseOne" aria-expanded="true" aria-controls="collapseOne">
              {{ 'whoCanBeDonor' | translate }}
            </button>
          </span>
        </div>
    
        <div id="enCollapseOne" class="collapse" aria-labelledby="enHeadingOne" data-parent="#accordionEn">
          <div class="card-body">
            <div class="inner-pad">
              <p>
                A blood donor can be any healthy, adult person, aged 18-65, after a laboratory and medical examination. Women can donate blood 3 times and men 4 times a year. The donor must meet the following conditions:
              </p> 
              <ul>
                <li style="list-style-type: none;">
                <ul>
                <li>That the person is healthy, in good general condition</li>
                <li>The person has to have at least &nbsp;<strong>50 kg</strong></li>
                <li>Body temperature less than  <strong>37 ° C</strong> </li>
                <li>Pulse between&nbsp;<strong>50 – 100/min</strong></li>
                <li>Blood pressure: not higher than &nbsp;<strong>170/100 mmHG</strong>&nbsp;and not lower than&nbsp;<strong>110/70&nbsp;mmHg</strong></li>
                <li>The donor must not have a cold</li>
                <li>The donor must not suffer from lung or heart disease</li>
                <li>The lymph glands of the neck and armpits, liver and spleen should not be enlarged</li>
                <li>Hemoglobin must be above&nbsp;<strong>135 g/L&nbsp;(Hct = 0.38)</strong> for men</li>
                <li>Hemoglobin must be above&nbsp;<strong>125 g/L&nbsp;(Hct = 0.4)</strong> for women</li>
                </ul>
                </li>
                </ul>
            </div>            
          </div>
        </div>
      </div>
  
  
  
      <div class="card">
        <div class="card-header" id="enHeadingTwo">
          <span class="mb-0 question-cont">
            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#enCollapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              {{ 'bloodDonationPreparation' | translate }}
            </button>
          </span>
        </div>
        <div id="enCollapseTwo" class="collapse" aria-labelledby="enHeadingTwo" data-parent="#accordionEn">
          <div class="card-body">
            <div class="inner-pad">
              <p>
                No special preparation for blood donation is needed, nor is there a special post-blood donation regimen, but in order to feel as good as possible when donating blood, especially if it is a first donation, certain advice might be helpful.
              </p>
  
              <div class="photo-steps f-c">
                <div class="step f-r responsive align-items-center">
                  <img src="../../../assets/images/vodic/1-2.jpg" alt="krv" class="step-graphic">
                  <div class="step-expl p-3">
                    <span>Don’t drink alcohol and don’t eat too much fatty food the day before.</span>
                  </div>
                </div>
  
                <div class="step f-r responsive align-items-center">
                  <img src="../../../assets/images/vodic/2-2.jpg" alt="krv" class="step-graphic">
                  <div class="step-expl p-3">
                    <span>Be sure to get 6-8 hours of sleep the night before</span>
                  </div>
                </div>
  
                <div class="step f-r responsive align-items-center">
                  <img src="../../../assets/images/vodic/3-2.jpg" alt="krv" class="step-graphic">
                  <div class="step-expl p-3">
                    <span>Be sure to have a light breakfast: bread, jam, cereals in milk, drink more fluids.</span>
                  </div>
                </div>
  
                <div class="step f-r responsive align-items-center">
                  <img src="../../../assets/images/vodic/4-2.jpg" alt="krv" class="step-graphic">
                  <div class="step-expl p-3">
                    <span>If you are taking any therapy, be sure to bring a list of medications with you.</span>
                  </div>
                </div>
  
                <div class="step f-r responsive align-items-center">
                  <img src="../../../assets/images/vodic/5.jpg" alt="krv" class="step-graphic">
                  <div class="step-expl p-3">
                    <span>Don't forget the personal document with the ID number and the photo.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
  
      <div class="card">
        <div class="card-header" id="enHeadingThree">
          <span class="mb-0 question-cont">
            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#enCollapseThree" aria-expanded="false" aria-controls="collapseThree">
              {{ 'bloodDonationStepByStep' | translate }}
            </button>
          </span>
        </div>
        <div id="enCollapseThree" class="collapse" aria-labelledby="enHeadingThree" data-parent="#accordionEn">
          <div class="card-body">
            <div class="inner-pad">
              <p>
                The blood donation procedure takes about 45 minutes and consists of several stages.
              </p>
  
              <div class="photo-steps f-c">
                <div class="step f-r responsive align-items-center">
                  <img src="../../../assets/images/vodic/1-1.jpg" alt="krv" class="step-graphic">
                  <div class="step-expl p-3">
                    <span>Fill out a blood donation questionnaire - for your own safety and the safety of the recipients.</span>
                  </div>
                </div>
  
                <div class="step f-r responsive align-items-center">
                  <img src="../../../assets/images/vodic/2-1.jpg" alt="krv" class="step-graphic">
                  <div class="step-expl p-3">
                    <span>Then follows a short medical examination: measurement of blood pressure, control of hemoglobin, check of general health.</span>
                  </div>
                </div>
  
                <div class="step f-r responsive align-items-center">
                  <img src="../../../assets/images/vodic/3-1.jpg" alt="krv" class="step-graphic">
                  <div class="step-expl p-3">
                    <span>If all is well, you can give blood…</span>
                  </div>
                </div>
  
                <div class="step f-r responsive align-items-center">
                  <img src="../../../assets/images/vodic/4-1.jpg" alt="krv" class="step-graphic">
                  <div class="step-expl p-3">
                    <span>…and then you can rest with refreshment!</span>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  
    <div class="card">
      <div class="card-header" id="enHeading4">
        <span class="mb-0 question-cont">
          <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#enCollapse4" aria-expanded="false" aria-controls="collapse4">
            {{ 'afterDonationAdvices' | translate }}
          </button>
        </span>
      </div>
      <div id="enCollapse4" class="collapse" aria-labelledby="enHeading4" data-parent="#accordionEn">
        <div class="card-body">
          <div class="inner-pad">
            <p>
              Follow the instructions below after donating blood.
            </p>
  
            <div class="photo-steps f-c">
              <div class="step f-r responsive align-items-center">
                <img src="../../../assets/images/vodic/1.jpg" alt="krv" class="step-graphic">
                <div class="step-expl p-3">
                  <span>Avoid strenuous physical exercises.</span>
                </div>
              </div>
  
              <div class="step f-r responsive align-items-center">
                <img src="../../../assets/images/vodic/2.jpg" alt="krv" class="step-graphic">
                <div class="step-expl p-3">
                  <span>Do not smoke for a couple of hours.</span>
                </div>
              </div>
  
              <div class="step f-r responsive align-items-center">
                <img src="../../../assets/images/vodic/3.jpg" alt="krv" class="step-graphic">
                <div class="step-expl p-3">
                  <span>Drink plenty of fluids.</span>
                </div>
              </div>
  
              <div class="step f-r responsive align-items-center">
                <img src="../../../assets/images/vodic/4.jpg" alt="krv" class="step-graphic">
                <div class="step-expl p-3">
                  <span>Do not expose yourself excessively to sun and heat.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="card">
      <div class="card-header" id="enHeading5">
        <span class="mb-0 question-cont">
          <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#enCollapse5" aria-expanded="false" aria-controls="collapse5">
            {{ 'howToPrepareBloodForUse' | translate }}
          </button>
        </span>
      </div>
      <div id="enCollapse5" class="collapse" aria-labelledby="enHeading5" data-parent="#accordionEn">
        <div class="card-body">
          <div class="inner-pad">
            <p>
              After ingestion, each unit of blood goes through several stages of preparation for use. The first phase is the centrifugation process, and then in a closed bag system, the blood is separated into its constituent elements (plasma, erythrocytes, platelets, cryoprecipitate), and then each of these elements is separated by the transfer process. The isolated blood components are issued to patients after mandatory testing, in accordance with the needs of their health condition.
            </p>
  
            <p>
              Each unit of blood must be controlled on:
            </p>
  
            <ul class="circle">
              <li>blood group affiliation</li>
              <li>the presence of irregular antibodies</li>
              <li>blood-borne pathogens (transmissible diseases such as hepatitis B and C, HIV and syphilis).</li>
            </ul>
  
            <img class="w-100" src="../../../assets/images/vodic/upotreba-krvi-1024x390.png" alt="krv">
          </div>
        </div>
      </div> 
    </div>
  
    <div class="card">
      <div class="card-header" id="enHeading6">
        <span class="mb-0 question-cont">
          <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#enCollapse6" aria-expanded="false" aria-controls="collapse6">
            {{ 'howLongCanBloodBeKept' | translate }}
          </button>
        </span>
      </div>
  
      <div id="enCollapse6" class="collapse" aria-labelledby="enHeading6" data-parent="#accordionEn">
        <div class="card-body">
          <div class="inner-pad">
            <img style="width: 400px" src="../../../assets/images/vodic/krv_upotreba.jpg" alt="krv">
          </div>
        </div>
      </div>
    </div>    
  </div>
  
    <div *ngIf="currentLang === 'me'" id="accordionMe">
      <div class="card">
        <div class="card-header" id="headingOne">
          <span class="mb-0 question-cont">
            <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              {{ 'whoCanBeDonor' | translate }}
            </button>
          </span>
        </div>
    
        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionMe">
          <div class="card-body">
            <div class="inner-pad">
              <p>
                Davalac krvi može biti svaka zdrava, odrasla osoba, starosti od 18-65 godina, nakon obavljenog laboratorijskog i ljekarskog pregleda. Žene mogu davati krv 3 puta, a muškarci 4 puta godišnje. Potrebno je da davalac ispunjava sljedeće uslove:
              </p> 
              <ul>
                <li style="list-style-type: none;">
                <ul>
                <li>Da je osoba zdrava, dobrog opšteg stanja</li>
                <li>Teža od&nbsp;<strong>50 kg</strong></li>
                <li>Tjelesne temperature manje od&nbsp;<strong>37°C</strong></li>
                <li>Pulsa između&nbsp;<strong>50 – 100/min</strong></li>
                <li>Krvnog pritiska: ne višeg od&nbsp;<strong>170/100 mmHG</strong>&nbsp;i ne nižeg od&nbsp;<strong>110/70&nbsp;mmHg</strong></li>
                <li>Davalac ne smije bit prehlađen</li>
                <li>Davalac ne smije bolovati od bolesti pluća i srca</li>
                <li>Limfne žlijezde vrata i pazuha, jetra i slezina ne smiju biti uvećane</li>
                <li>Hemoglobin mora biti iznad&nbsp;<strong>135 g/L&nbsp;(Hct = 0.38)</strong> za muškarce</li>
                <li>Hemoglobin mora biti iznad&nbsp;<strong>125 g/L&nbsp;(Hct = 0.4)</strong> za žene</li>
                </ul>
                </li>
                </ul>
            </div>            
          </div>
        </div>
      </div>



      <div class="card">
        <div class="card-header" id="headingTwo">
          <span class="mb-0 question-cont">
            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              {{ 'bloodDonationPreparation' | translate }}
            </button>
          </span>
        </div>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionMe">
          <div class="card-body">
            <div class="inner-pad">
              <p>
                Posebna priprema za davanje krvi nije potrebna, kao ni poseban režim poslije davanja krvi, ali da bi se što bolje osjećali pri davanju krvi, pogotovo ako je to prvo davanje, određeni savjeti bi mogli da budu od koristi.
              </p>

              <div class="photo-steps f-c">
                <div class="step f-r responsive align-items-center">
                  <img src="../../../assets/images/vodic/1-2.jpg" alt="krv" class="step-graphic">
                  <div class="step-expl p-3">
                    <span>Ne pijte alkohol i ne jedite previše masne hrane prethodnog dana.</span>
                  </div>
                </div>

                <div class="step f-r responsive align-items-center">
                  <img src="../../../assets/images/vodic/2-2.jpg" alt="krv" class="step-graphic">
                  <div class="step-expl p-3">
                    <span>Prethodne noći obavezno odspavajte 6-8h.</span>
                  </div>
                </div>

                <div class="step f-r responsive align-items-center">
                  <img src="../../../assets/images/vodic/3-2.jpg" alt="krv" class="step-graphic">
                  <div class="step-expl p-3">
                    <span>Obavezno lagano doručkujte: hljeb, marmeladu, žitarice u mlijeku, popijte više tečnosti.</span>
                  </div>
                </div>
  
                <div class="step f-r responsive align-items-center">
                  <img src="../../../assets/images/vodic/4-2.jpg" alt="krv" class="step-graphic">
                  <div class="step-expl p-3">
                    <span>Ukoliko uzimate neku terapiju, sa sobom obavezno ponesite i listu ljekova.</span>
                  </div>
                </div>

                <div class="step f-r responsive align-items-center">
                  <img src="../../../assets/images/vodic/5.jpg" alt="krv" class="step-graphic">
                  <div class="step-expl p-3">
                    <span>Ne zaboravite lični dokument sa matičnim brojem i fotografijom.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="card">
        <div class="card-header" id="headingThree">
          <span class="mb-0 question-cont">
            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              {{ 'bloodDonationStepByStep' | translate }}
            </button>
          </span>
        </div>
        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionMe">
          <div class="card-body">
            <div class="inner-pad">
              <p>
                Postupak davanja krvi traje oko 45 minuta i sastoji se od nekoliko faza.
              </p>

              <div class="photo-steps f-c">
                <div class="step f-r responsive align-items-center">
                  <img src="../../../assets/images/vodic/1-1.jpg" alt="krv" class="step-graphic">
                  <div class="step-expl p-3">
                    <span>Ispunite upitnik o podobnosti za davanje krvi – radi vaše bezbjednosti i bezbjednosti primalaca.</span>
                  </div>
                </div>

                <div class="step f-r responsive align-items-center">
                  <img src="../../../assets/images/vodic/2-1.jpg" alt="krv" class="step-graphic">
                  <div class="step-expl p-3">
                    <span>Zatim slijedi kratak ljekarski pregled: mjerenje krvnog pritiska, kontrola hemoglobina, provjera opšteg zdravstvenog stanja.</span>
                  </div>
                </div>

                <div class="step f-r responsive align-items-center">
                  <img src="../../../assets/images/vodic/3-1.jpg" alt="krv" class="step-graphic">
                  <div class="step-expl p-3">
                    <span>Ako je sve u redu, možete da date krv…</span>
                  </div>
                </div>
  
                <div class="step f-r responsive align-items-center">
                  <img src="../../../assets/images/vodic/4-1.jpg" alt="krv" class="step-graphic">
                  <div class="step-expl p-3">
                    <span>…a potom i da se odmorite uz osvježenje!</span>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="card">
      <div class="card-header" id="heading4">
        <span class="mb-0 question-cont">
          <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
            {{ 'afterDonationAdvices' | translate }}
          </button>
        </span>
      </div>
      <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordionMe">
        <div class="card-body">
          <div class="inner-pad">
            <p>
              Nakon davanja krvi pridržavajte se daljih uputstava.
            </p>

            <div class="photo-steps f-c">
              <div class="step f-r responsive align-items-center">
                <img src="../../../assets/images/vodic/1.jpg" alt="krv" class="step-graphic">
                <div class="step-expl p-3">
                  <span>Izbjegavajte teža fizička naprezanja.</span>
                </div>
              </div>

              <div class="step f-r responsive align-items-center">
                <img src="../../../assets/images/vodic/2.jpg" alt="krv" class="step-graphic">
                <div class="step-expl p-3">
                  <span>Ne pušite par sati.</span>
                </div>
              </div>

              <div class="step f-r responsive align-items-center">
                <img src="../../../assets/images/vodic/3.jpg" alt="krv" class="step-graphic">
                <div class="step-expl p-3">
                  <span>Pijte dosta tečnosti.</span>
                </div>
              </div>

              <div class="step f-r responsive align-items-center">
                <img src="../../../assets/images/vodic/4.jpg" alt="krv" class="step-graphic">
                <div class="step-expl p-3">
                  <span>Ne izlažite se pretjerano suncu i toploti.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header" id="heading5">
        <span class="mb-0 question-cont">
          <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
            {{ 'howToPrepareBloodForUse' | translate }}
          </button>
        </span>
      </div>
      <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordionMe">
        <div class="card-body">
          <div class="inner-pad">
            <p>
              Nakon uzimanja, svaka jedinica krvi prolazi kroz nekoliko faza pripreme za upotrebu. Prva faza je postupak centrifugiranja, a zatim se u zatvorenom sistemu kesa, krv raslojava na sastavne elemente (plazma, eritrociti, trombociti, krioprecipitat), a zatim postupkom pretakanja izdvaja svaki od ovih elemenata. Izdvojene komponente krvi se nakon obaveznih testiranja izdaju pacijentima, u skladu sa potrebama njihovog zdravstvenog stanja.
            </p>

            <p>
              Svaka jedinica krvi obavezno se kontroliše na:
            </p>

            <ul class="circle">
              <li>krvno grupnu pripadnost</li>
              <li>prisustvo iregularnih antitijela</li>
              <li>izazivače bolesti koje se mogu prenijeti putem krvi (transmisivne bolesti kao što su hepatitis B i C, HIV i sifilis).</li>
            </ul>

            <img class="w-100" src="../../../assets/images/vodic/upotreba-krvi-1024x390.png" alt="krv">
          </div>
        </div>
      </div> 
    </div>

    <div class="card">
      <div class="card-header" id="heading6">
        <span class="mb-0 question-cont">
          <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
            {{ 'howLongCanBloodBeKept' | translate }}
          </button>
        </span>
      </div>

      <div id="collapse6" class="collapse" aria-labelledby="heading6" data-parent="#accordionMe">
        <div class="card-body">
          <div class="inner-pad">
            <img style="width: 400px" src="../../../assets/images/vodic/krv_upotreba.jpg" alt="krv">
          </div>
        </div>
      </div>
    </div>
  
  
  
  </div>

  <div class="p-2 mv-24"></div>


 

<!--  -->