<section class="main pt-5">

  
  <div class="main-center f-r responsive">
    <div class="main-center-left">
      <img src="../../../assets/images/screen-preview.png" alt="">
    </div>
    <div class="main-center-right align-items-center p-3 pb-5">
      <div class="d-flex">
        <h1>Darujkrv.me</h1>
        <img class="logo" src="../../../assets/images/logo.png" alt="logo">
      </div>
      <p class="description">
        Daruj krv je zvanična aplikacija Zavoda za transfuziju krvi Crne Gore. Dozvoljava lako zakazivanje, upravljanje i praćenje donacija, kao i uvid u ličnu istoriju i zalihe.
      </p>

      <div class="f-r responsive">
        <a target="_blank" href='https://play.google.com/store/apps/details?id=me.darujkrv&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
          <img class="installs" alt='Get it on Google Play' src='../../../assets/images/android_store.png'/></a>
        <a target="_blank" href='https://apps.apple.com/us/app/darujkrv-me/id1560568076'>
          <img class="installs ios" src="../../../assets/images/ios_store.svg" alt="Get it on Apple store">
        </a>
      </div>
    </div>

    
  </div>
</section>
<section class="second"></section>
<footer id="contact">
  Ako imate problem u korišćenju aplikacije kontaktirajte nas na info@darujkrv.me
</footer>