import { Component, OnInit } from '@angular/core';
import { ArticleService } from '../shared/services/article.service';
import { Article } from '../shared/models/article.model';
import { TranslateService } from '@ngx-translate/core';
import { SiteArticle } from '../shared/models/site-article.model';
import { LangService } from '../shared/services/lang.service';
import { DataService } from '../shared/services/data.service';

@Component({
  selector: 'app-manifestations',
  templateUrl: './manifestations.component.html',
  styleUrls: ['./manifestations.component.css']
})
export class ManifestationsComponent implements OnInit {
  public manifestArticles: SiteArticle[] = [];
  public currentPage = 1;

  constructor(private articleService: ArticleService,
              private translate: TranslateService,
              private langService: LangService,
              private dataService: DataService) {
  }

  ngOnInit() {
    this.getResponseFromArticleService(this.translate.currentLang);
    this.langService.languageChanged.subscribe((lang: string) => {
      this.manifestArticles.forEach(element => {
        element.showAppropriateLangData(lang);
      });
    });
  }

  getResponseFromArticleService(lang: string) {
    this.articleService
      .getArticlesByCategory(3, this.currentPage, 20).subscribe(
        (rez: any) => {
          // tslint:disable-next-line:prefer-for-of
            if (rez.code === 200) {
              const articles = [];
              if (rez.data.length > 0) {
                for (const element of rez.data) {
                  const art = new SiteArticle(element);
                  art.showAppropriateLangData(lang);
                  articles.push(art);
                }
                this.manifestArticles = articles;
              } else {
                this.dataService.maxPageReached.emit(true);
              }
            } else {
              // //  console.log('Dogodila se greska');
            }
          });
  }

  onPageSelected(pageEvent: number) {
    this.currentPage = pageEvent;
    this.getResponseFromArticleService(this.translate.currentLang);
  }

}
