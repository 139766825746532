<div class="article-component-container pager">
  <div class="article-header mv-24">
      <h1 class="article-title text-center">
         {{  'aboutBlood' | translate }}
      </h1>
  </div>

  <div class="article-content">
    
  
    <div *ngIf="currentLang === 'me'" id="accordionMe">
      <div class="card">
        <div class="card-header" id="headingOne">
          <span class="mb-0 question-cont">
            <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              {{ 'whatIsBlood' | translate }}
            </button>
          </span>
        </div>
    
        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionMe">
          <div class="card-body">
            <div class="inner-pad">
              <p>
                Krv je kompleksno tečno tkivo koje predstavlja suspenziju različitih vrsta ćelija u složenom vodenom rastvoru – krvnoj plazmi.
              </p> 
              <ul class="circle">
                <li>Krv povezuje i usklađuje funkcije različitih ćelija, tkiva i organa i tako doprinosi održavanju relativne stalnosti unutrašnje sredine organizma.</li>
                <li>Osnovna funkcija krvi je snadbijevanje tkiva kiseonikom i hranljivim materijama, kao i odstranjenje ugljen-dioksida i svih metabiličkih produkata .</li>
                <li>Brojne su uloge krvi u organizmu : transportna, respiratorna, nutritivna,regulatorna,ekskretorna i detoksaciona,odbrambena, hemostazna,homeostazna….</li>
                <li>Krv čini oko 7% tjelesne težine. Organizam odraslog čovjeka sadrži 4,5-5,5 l krvi. Volumen krvi muškaraca je aproksimativno 70 ml/kg , a žena 66 ml/kg.</li>
              </ul>
            </div>            
          </div>
        </div>
      </div>



      <div class="card">
        <div class="card-header" id="headingTwo">
          <span class="mb-0 question-cont">
            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              {{ 'bloodDonationPreparation' | translate }}
            </button>
          </span>
        </div>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionMe">
          <div class="card-body">
            <div class="inner-pad">
              <ul class="circle">
                <li>Krvne grupe su nasljedne biološke karakteristike koje su kod zdravih ljudi nepromjenjive tokom života.</li>
                <li>Svakoj doniranoj jedinici krvi se određuje krvna grupa u ABO i Rh sistemu, kao i prisustvo iregularnih eritrocitnih antitijela.</li>
                <li>Određivanje АBO i RhD krvne grupe je osnovni preduslov za bezbjednu primjenu krvi i komponenata krvi.</li>
                <li>Osobe О RhD-se smatraju opštim davaocima, a osobe AB RhD+ su opšti primaoci.</li>
              </ul>
            </div>     
          </div>
        </div>
      </div>


      <div class="card">
        <div class="card-header" id="headingThree">
          <span class="mb-0 question-cont">
            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              {{ 'bloodDonationStepByStep' | translate }}
            </button>
          </span>
        </div>
        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionMe">
          <div class="card-body">
            <div class="inner-pad">
              <ul class="circle">
                <li>Do sada je ustanovljeno preko 600 eritrocitnih antigena koji su svrstani u 36 krvnogrupnih sistema,među kojima je klinički najznačajniji  krvnogrupni sisitem ABO.</li>
                <li>U ABO sistemu postoje 4 krvne grupe (А, B, АB i О).  Učetalost krvnih grupa varira u okviru određenih populacija, pa je tako u bijeloj populaciji najzastupljenija  krvna grupa О. U našoj populaciji najzastupljenija je krvna grupa А.</li>
                <li>Sledeći po značaju je krvno grupni sistem  Rh u okviru koga je opisano 54 antigena. Najznačajniji antigen Rh sistema je D antigen, pa se tako osobe u zavisnosti od toga da li je ovaj antigen prisutan na eritrocitima ili ne, dijele na RhD+ i RhD-.</li>
                <li>U našoj populaciji 85% osoba su RhD+, а 15% RhD</li>
              </ul>
            </div> 
        </div>
      </div>
    </div>


    <div class="card">
      <div class="card-header" id="heading4">
        <span class="mb-0 question-cont">
          <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
            {{ 'afterDonationAdvices' | translate }}
          </button>
        </span>
      </div>
      <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordionMe">
        <div class="card-body">
          <div class="inner-pad">
            <p>
              Jedinica cijele krvi sadrži krvnu plazmu i ćelijske elemente. Cilj pravilnog liječenja pacijenata krvnim produktima je da  se nadoknadi samo onaj dio krvi koji mu nedostaje tzv. usmerena hemoterapija.
            </p> 

            <p>
              Postupkom centrifugiranja u zatvorenom, sterilnom sistemu kesa za krv  raslojavaju se sastavni elementi i pretakanjem izdvajaju plazma i ćelijski elementi -krvne komponente.
            </p>

            <p>
              Iz jedne jedinice cijele krvi najčešće izdvajamo 4 vrste krvnih komponenata:
            </p>

            <ul class="circle mb-24">
              <li>koncentrat eritrocita</li>
              <li>koncentrat trombocita</li>
              <li>zamrznutu sviježu plazmu iz koje možemo izdvojiti i krioprecipitat.</li>
            </ul>

            <p>
              Postoje i posebni oblici krvnih komponenata koji se procesiraju shodno kliničkim indikacijama, a to su: oprani eritrociti, deleukocitovani eritrociti, male doze eritrocita za dječiji uzrast…
            </p>

          </div> 
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header" id="heading5">
        <span class="mb-0 question-cont">
          <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
            {{ 'howToPrepareBloodForUse' | translate }}
          </button>
        </span>
      </div>
      <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordionMe">
        <div class="card-body">
          <div class="inner-pad">
            <p>
              Krvne komponente se čuvaju na temperaturi i u uslovima koji su optimalni za funkcionalnu aktivnost sastojaka krvnih komponenata.
            </p> 

            <p>
              Rok trajanja krvnih komponenata zavisi i od vrste ćelija, temperaturnih uslova skladištenja, dodatnih rastvora.
            </p>

            <p>
              Dužina skladištenja krvnih komponenata je različita:
            </p>

            <ul class="circle mb-24">
              <li>koncentrat eritrocita: u frižiderima na temperaturi od 4°C +/-2°C 28, 35 ili 42 dana, zavisno od rastvora koji se nalazi unutar sistema kesa</li>
              <li>koncentrat trombocita: u inkubatorima sa mješalicom na temperaturi od 22°C do 5dana</li>
              <li>zamrznuta svježa plazma : u zamrzivačima na temperaturi nižoj od -30°C do 3 godine.</li>
            </ul>

            <p>
              Kada je riječ o koncentratu eritrocita i trombocita, imajući u vidu njihov kratak rok trajanja vrlo često nismo u mogućnosti da obezbjedimo zalihe tih komponenata, te je od izuzetnog značaja ravnomerno i svakodnevno dolaženje davaoca krvi u našu ustanovu.
            </p>
          </div> 
        </div>
      </div> 
    </div>

    <div class="card">
      <div class="card-header" id="heading6">
        <span class="mb-0 question-cont">
          <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
            {{ 'whereIsBloodCreated' | translate }}
          </button>
        </span>
      </div>

      <div id="collapse6" class="collapse" aria-labelledby="heading6" data-parent="#accordionMe">
        <div class="card-body">
          <div class="inner-pad">
            <p>
              Krvne ćelije se nerprestano stvaraju u koštanoj srži zdravog ljudskog organizma.
            </p>

            <p>
              Tokom cijelog života , svaki organizam sam proizvodi dovoljne količine svih sastavnih dijelova krvi. ( Npr. 12×107 Eritrocita/kg/h )
            </p>
          </div>
        </div>
      </div>
    </div>


    <div class="card">
      <div class="card-header" id="heading7">
        <span class="mb-0 question-cont">
          <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
            {{ 'bloodStructureQuestion' | translate }}
          </button>
        </span>
      </div>

      <div id="collapse7" class="collapse" aria-labelledby="heading7" data-parent="#accordionMe">
        <div class="card-body">
          <div class="inner-pad">
            <p>
              Krv se sastoji od uobličenih ćelijskih elemenata ( 45 % ) i tečnog dijela koji se naziva krvna plazma ( 55% ) , te predstavlja dvofazni sistem koji cirkuliše u zatvorenom vaskularnom sistemu.
            </p>

            <p>
              <strong>Krvna plazma</strong> je tečnio dio krvi koji se sastoji od vode , mineralnih materija , masti , ugljenih hidrata i više od 300 različitih vrsta bjelančevina koje prezumaju različite uloge krvi. Prosječni volumen krve plazme je oko 40 ml/kg.
            </p>

            <p>
              Krvne ćelije su eritrociti ( crvene krvne ćelije ) , leukociti ( bijele krvne ćelije) i trombociti ( krne pločice ).
            </p>

            <p>
              U jednoj kapi krvi se nalzi :
            </p>

            <ul>
              <li>- 15 miliona trombocita</li>
              <li>- 400 hiljada leukocita</li>
              <li>- do 250 miliona eritrocita</li>
            </ul>


            <ul class="np">
              <li><strong>Eritrocit</strong>i su najbrojnije krvne ćelije . Životni vijek im je oko 120 dana i za to vrijeme oko 200.000 puta prođu krvotokom.</li>
              <li>Crvena boja eritrocita potiče od crvenog krvnog pigmenta, <strong>hemoglobina </strong>koji je najvžniji dio eritrocita jer prenosi kiseonik i ugljen dioksid u organizmu.</li>
              <li><strong>Hematokrit </strong>je postotak eritrocita na jedinicu volumena krvi , odnosno zapreminski odnos uobličenih ćelijskih elemenata krvi prema plazmi.</li>
              <li><strong>Leukociti</strong> su vrsta krvnih ćelija čija je najvažnija uloga u odbrani organizma od najrazličitijih infekcija. Vrste leukocita su : granulociti, limfociti , monociti , eozinofili . Životni vijek im je najkraći.</li>
              <li><strong>Trombociti </strong>( krvne pločice ) učestvuju u složenom procesu zgrušavanja (koagulacije ) krvi. U cirkulaciji se zadržavaju oko 9-11 dana.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  
  
  
  </div>

  <div *ngIf="currentLang === 'en'" id="accordionEn">	
    <div class="card">		
      <div class="card-header" id="enHeadingOne">
        <span class="mb-0 question-cont">
          <button aria-controls="collapseOne" aria-expanded="false" class="btn btn-link collapsed" data-target="#enCollapseOne" data-toggle="collapse">
          What is blood? 
          </button>
        </span>
      </div>	
      <div aria-labelledby="enHeadingOne" class="collapse" data-parent="#accordionEn" id="enCollapseOne">
        <div class="card-body">
          <div class="inner-pad">
            <p> Blood is a complex liquid tissue that represents a suspension of different types of cells in a complex aqueous solution - blood plasma. </p>
            <ul class="circle">
              <li>Blood connects and harmonizes the functions of various cells, tissues and organs and thus contributes to maintaining the relative stability of the internal environment of the organism.</li>
              <li>The basic function of the blood is to supply tissues with oxygen and nutrients, as well as to remove carbon dioxide and all metabolic products.</li>
              <li>There are numerous roles of blood in the body: transport, respiratory, nutritional, regulatory, excretory and detoxification, defense, hemostasis, homeostasis….</li>
              <li>Blood makes up about 7% of body weight. The body of an adult contains 4.5-5.5 liters of blood. The blood volume of men is approximately 70 ml / kg, and that of women 66 ml / kg.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="enHeadingTwo">
        <span class="mb-0 question-cont">
          <button aria-controls="collapseTwo" aria-expanded="false" class="btn btn-link collapsed" data-target="#enCollapseTwo" data-toggle="collapse"> 
            Preparation for blood donation 
          </button>
        </span>
      </div>
      <div aria-labelledby="enHeadingTwo" class="collapse" data-parent="#accordionEn" id="enCollapseTwo">
        <div class="card-body">
          <div class="inner-pad">
            <ul class="circle">
              <li>Blood groups are hereditary biological characteristics that are unchanged in healthy people throughout life.</li>
              <li>Each donated unit of blood is determined by the blood group in the ABO and Rh system, as well as the presence of irregular erythrocyte antibodies.</li>
              <li>Determination of ABO and RhD blood group is a basic prerequisite for safe use of blood and blood components.</li>
              <li>Persons O RhD-are considered general donors, and persons AB RhD + are general recipients.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="enHeadingThree">
        <span class="mb-0 question-cont">
          <button aria-controls="collapseThree" aria-expanded="false" class="btn btn-link collapsed" data-target="#enCollapseThree" data-toggle="collapse">
            Blood donation - step by step 
          </button>
        </span>
      </div>
      <div aria-labelledby="enHeadingThree" class="collapse" data-parent="#accordionEn" id="enCollapseThree">
        <div class="card-body">
          <div class="inner-pad">
            <ul class="circle">
              <li>So far, over 600 erythrocyte antigens have been identified, which are classified into 36 blood group systems, among which the ABO blood group system is the most clinically significant.</li>
              <li>There are 4 blood groups (AB, B, AB and O) in the ABO system. The frequency of blood groups varies within certain populations, so in the white population, blood group O is the most common. Blood group A is the most represented in our population.</li>
              <li>The next most important is the blood group system Rh, within which 54 antigens have been described. The most important antigen of the Rh system is the D antigen, so people are divided into RhD + and RhD-, depending on whether this antigen is present on erythrocytes or not.</li>
              <li>In our population, 85% of people are RhD + and 15% are RhD</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="enHeading4">
        <span class="mb-0 question-cont">
          <button aria-controls="collapse4" aria-expanded="false" class="btn btn-link collapsed" data-target="#enCollapse4" data-toggle="collapse">
            Tips on behavior after donating blood 
          </button>
        </span>
      </div>
      <div aria-labelledby="enHeading4" class="collapse" data-parent="#accordionEn" id="enCollapse4">
        <div class="card-body">
          <div class="inner-pad">
            <p>The whole blood unit contains blood plasma and cellular elements. The goal of proper treatment of patients with blood products is to compensate only that part of the blood that lacks the so-called. targeted chemotherapy.</p>
            <p> The process of centrifugation in a closed, sterile system of blood bags separates the constituent elements and separates the plasma and cellular elements - blood components. </p>
            <p> From one unit of whole blood we usually single out 4 types of blood components: </p>
            <ul class="circle mb-24">
              <li>erythrocyte concentrate</li>
              <li>platelet concentrate</li>
              <li>frozen fresh plasma from which we can extract the cryoprecipitate.</li>
            </ul>
            <p> There are also special forms of blood components that are processed according to clinical indications, and they are: washed erythrocytes, deleukocyte erythrocytes, small doses of erythrocytes for children… </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="enHeading5">
        <span class="mb-0 question-cont">
          <button aria-controls="collapse5" aria-expanded="false" class="btn btn-link collapsed" data-target="#enCollapse5" data-toggle="collapse">
            How is blood prepared for use? 
          </button>
        </span>
      </div>
      <div aria-labelledby="enHeading5" class="collapse" data-parent="#accordionEn" id="enCollapse5">
        <div class="card-body">
          <div class="inner-pad">
            <p> Blood components are stored at temperatures and conditions that are optimal for the functional activity of the components of blood components. </p>
            <p> The shelf life of blood components also depends on the type of cells, temperature storage conditions, and additional solutions. </p>
            <p> The length of storage of blood components is different: </p><ul class="circle mb-24">
            <li>erythrocyte concentrate: in refrigerators at 4 ° C +/- 2 ° C for 28, 35 or 42 days, depending on the solution inside the bag system</li>
            <li>platelet concentrate: in incubators with stirrer at a temperature of 22 ° C for 5 days</li>
            <li>frozen fresh plasma: in freezers at temperatures below -30 ° C for up to 3 years.</li></ul>
            <p> When it comes to the concentrate of erythrocytes and thrombocytes, having in mind their short shelf life, we are often not able to provide supplies of these components, so it is extremely important that the blood donor comes to our institution evenly and daily. </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="enHeading6">
        <span class="mb-0 question-cont">
          <button aria-controls="collapse6" aria-expanded="false" class="btn btn-link collapsed" data-target="#enCollapse6" data-toggle="collapse">
            Where does blood come from? 
          </button>
        </span>
      </div>
      <div aria-labelledby="enHeading6" class="collapse" data-parent="#accordionEn" id="enCollapse6">
        <div class="card-body">
          <div class="inner-pad">
            <p> Blood cells are constantly formed in the bone marrow of a healthy human body. </p>
            <p>Throughout life, each organism produces sufficient amounts of all blood components on its own. (Eg 12 × 107 Erythrocytes / kg / h)</p>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header" id="enHeading7">
        <span class="mb-0 question-cont">
          <button aria-controls="collapse7" aria-expanded="true" class="btn btn-link" data-target="#enCollapse7" data-toggle="collapse">
            What is the composition of the blood? 
          </button>
        </span>
      </div>
    <div aria-labelledby="enHeading7" class="collapse show" data-parent="#accordionEn" id="enCollapse7">
      <div class="card-body">
        <div class="inner-pad">
          <p> Blood consists of shaped cellular elements (45%) and a fluid portion called blood plasma (55%), and is a two-phase system circulating in a closed vascular system. </p>
          <p><strong>Blood plasma</strong> is a liquid part of the blood that consists of water, minerals, fats, carbohydrates and more than 300 different types of proteins that assume different blood roles. The average blood plasma volume is about 40 ml / kg.</p>
          <p> Blood cells are erythrocytes (red blood cells), leukocytes (white blood cells) and platelets (platelets). </p>
          <p> One drop of blood contains: </p>
          <ul>
            <li>- 15 million platelets</li>
            <li>- 400 thousand leukocytes</li>
            <li>- up to 250 million erythrocytes</li></ul><ul class="np">
            <li><strong>Erythrocytes</strong> are the most numerous blood cells. Their lifespan is about 120 days and during that time they pass through the bloodstream about 200,000 times.</li>
            <li>The red color of erythrocytes comes from the red blood pigment, <strong>hemoglobin,</strong> which is the most important part of erythrocytes because it carries oxygen and carbon dioxide in the body.</li>
            <li><strong>Hematocrit</strong> is the percentage of erythrocytes per unit volume of blood, ie the volume ratio of shaped cellular elements of blood to plasma.</li>
            <li><strong>Leukocytes</strong> are a type of blood cells whose most important role is in the body's defense against various infections. Types of leukocytes are: granulocytes, lymphocytes, monocytes, eosinophils. Their lifespan is the shortest.</li>
            <li><strong>Platelets</strong> (blood platelets) participate in a complex process of blood clotting (coagulation). They remain in the circulation for about 9-11 days.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  </div>

  <div class="p-2 mv-24"></div>


 

<!--  -->