import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  isShown: boolean = false; // hidden by default
  constructor() { }

  ngOnInit() {
  }
  toggleShow() {

    this.isShown = !this.isShown;

  }
}
