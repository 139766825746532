<div *ngIf="errorExists" class="page-error">
  <div class="alert alert-danger">
      {{ 'pageNotAvailable' | translate }}
  </div>
</div>
<div class="article-component-container pager">
  <div class="article-header mv-24">
      <h1 class="article-title text-center">
         {{  'documents' | translate }}
      </h1>
  </div>


  

  <div *ngIf="docGroups != null" class="article-content"> 


    <div *ngFor="let docType of documentTypes; let i = index" [attr.id]="'accordion' +  i">
      <div class="card">
        <div class="card-header" [attr.id]="'heading' + i">
          <span class="mb-0 question-cont">
            <button class="btn btn-link" data-toggle="collapse" [attr.data-target]="'#collapse' + i " aria-expanded="false"
             [attr.aria-controls]="'collapse' + i">
              {{ currentLang == 'me' ? docType.nameMe : docType.nameEn }}
            </button>
          </span>
        </div>
    
        <div [attr.id]="'collapse' + i" class="collapse" [attr.aria-labelledby]="'heading' + i" [attr.data-parent]="'#accordion' + i">
          <div class="card-body">
            <div class="inner-pad">
              <ul class="pt-2">
                <li class="pt-2" *ngFor="let doc of docGroups[docType.id + '']">
                  <a class="fs-18 no-decor doc-link bt" href="{{ config.fileUrl + doc.file.url }}" target="_blank">{{ currentLang === 'me' ? doc.nameMe : doc.nameEn }}</a>
                </li>
              </ul>              
            </div>            
          </div>
        </div>
      </div>
    
    


    

  </div>
  </div>

