import { Injectable, EventEmitter } from '@angular/core';

import { Router } from '@angular/router';
import { BehaviorSubject, config } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isArray } from 'util';
import { StorageService } from './storage.service';
import { Config } from '../../../Config';
import * as jwt_decode from 'jwt-decode';


@Injectable()
export class AuthService {
  public _loggedIn = new BehaviorSubject<boolean>(false);
  public _loginFailed = new BehaviorSubject<boolean>(false);
  public loggedOut = new EventEmitter<boolean>();
  private config: Config;
  baseUrl: string;
  adminUrl: string;
  header: HttpHeaders;

  constructor(
    private router: Router,
    private http: HttpClient,
    private storageService: StorageService
  ) {
    this.config = new Config();
    this.baseUrl = this.config.baseUrl;
    this.header = new HttpHeaders( {
      'Content-Type': 'application/json',
    });
  }

  get isLoggedIn() {
    if (this.storageService.sessionGet('isLoggedIn') === true) {
      this._loggedIn.next(true);
    }

    return this._loggedIn.asObservable();
  }

  get adminLoggedIn() {
    if (this.storageService.sessionGet('adminLoggedIn') === true) {
      this._loggedIn.next(true);
    }

    return this._loggedIn.asObservable();

  }

  public loginRequest(loginData: {login: string, password: string}) {
    const url =  this.baseUrl + 'login';
    const dataToSend = {loginData: {login: null, password: null}};
    dataToSend.loginData.login = loginData.login;
    dataToSend.loginData.password = btoa(loginData.password + ' ' + loginData.login);

    return this.http.post(url, dataToSend, { headers: this.header});
  }

  public adminLoginRequest(loginData: {login: string, password: string}) {
    const url =  this.baseUrl + 'admin-login';
    const dataToSend = {loginData: {login: null, password: null}};
    dataToSend.loginData.login = loginData.login;
    dataToSend.loginData.password = btoa(loginData.password + ' ' + loginData.login);

    return this.http.post(url, dataToSend, { headers: this.header});
  }

  public registerUser(registerData: {ime: string, prezime: string, email: string,
     password: string, repeatPassword: string}) {
      const url =  this.baseUrl + 'register';
      const dataToSend = {userData: {
                            ime: registerData.ime,
                            prezime: registerData.prezime,
                            email: registerData.email,
                            password: null}};
      dataToSend.userData.password = btoa(registerData.password + ' ' + registerData.email);
      return this.http.post(url, dataToSend, { headers: this.header});
  }

  get loginFailed() {
    return this._loginFailed.value;
  }

  get loggedInUser() {
    return this.storageService.sessionGet('loggedInUser');
  }

  login() {
    this._loggedIn.next(true);
  }

  logout() {
    this.storageService.sessionDelete('isLoggedIn');
    this.storageService.sessionDelete('loggedInUser');
    this.storageService.sessionDelete('admin_user_token');
    this.storageService.sessionDelete('vsite_user_token');

    this._loggedIn.next(false);
    this.loggedOut.emit(true);
    this.router.navigate(['/home']);
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded.exp === undefined) {
       return null;
    }

    const date = new Date(0); 
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if(!token) {
      token = this.getToken();
    }

    if(!token) {
      return true;
    }

    const date = this.getTokenExpirationDate(token);
    if(date === undefined) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }

  getToken() {
    return this.storageService.sessionGet('admin_user_token');
  }
}
