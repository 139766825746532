<section class="w-100 f-c pt-3 pb-3 align-items-center">
  <div class="col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-11">
    <div class="section-news f-c align-items-center mv-24">
      <div class="top-region f-r justify-content-between w-100">
        <div class="section-title">
          <h2 class="rem-1-25 bt">{{ 'latestNews' | translate }}</h2>
        </div>

        <div class="section-arrows f-r">
          <a class="no-decor"  href="#newsCarousel" role="button" data-slide="prev">
            <div class="arrow-cont left f-c justify-content-center">
              <span class="fa fa-chevron-left"></span>
            </div>
          </a>

          <a  class="no-decor" href="#newsCarousel" role="button" data-slide="prev">
            <div class="arrow-cont right f-c justify-content-center">
              <span class="fa fa-chevron-right"></span>
            </div>
          </a>

         
        </div>
      </div>


      <div class="articles-cont w-100 mv-48">
        <div class="carousel slide" id="newsCarousel" data-interval="3000" data-ride="carousel">
          <div class="carousel-inner">
            <ng-container *ngIf="screenWidth >= 882">
              <div *ngFor="let gr of articleGroups; let g = index" class="carousel-item {{ g === 0 ? 'active' : '' }}" >
                <div class="rotation-group w-100 f-r justify-content-between">
                  <ng-container *ngFor="let item of gr">
                    <app-news-preview-item  *ngIf="item != null && item !== undefined" [article]="item"></app-news-preview-item>
                  </ng-container>
                </div>
              </div>
            </ng-container>
      
            <ng-container *ngIf="882 > screenWidth">
              <div  *ngFor="let singleArticle of articles; let g = index" class="carousel-item {{ g === 0 ? 'active' : '' }}" >
                    <app-news-preview-item  *ngIf="singleArticle != null && singleArticle !== undefined" [article]="singleArticle" class="f1"></app-news-preview-item>
              </div>
            </ng-container>
            
      
      
          </div>
        </div>

        <div class="mobile-news" *ngIf="articles.length > 0">
          <div class="news news-wrap f-c">
            <app-news-preview-item class="multi-row"  *ngFor="let singleArticle of articles; let g = index" [article]="singleArticle"></app-news-preview-item>
          </div>
        </div>
      </div>

      <div class="look-for-more text-center w-100">
        <span routerLink="{{ '/' + translate.currentLang +  '/novosti'}}">{{ 'lookForMoreNews' | translate }}</span>
      </div>
  </div>
  </div>
  
</section>